define("trivver-frontend/pods/home/register-dev/route", ["exports", "trivver-frontend/mixins/routes/unlogged-user-only"], function (_exports, _unloggedUserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unloggedUserOnly.default.extend({
    breadCrumb: null,
    store: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        developer: this.store.createRecord('register'),
        countries: this.store.findAll('country'),
        states: this.store.findAll('state'),
        terms: this.store.findRecord('tos-document', 'register-publisher-tos')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model); // When we load the controller, then we put the country's default value
      // to the form `country`


      if (!controller.get('model.developer.country.code')) {
        controller.set('model.developer.country', controller.defaultCountry);
      }

      if (!controller.get('model.developer.state.short')) {
        controller.set('model.developer.state', controller.defaultState);
      }

      controller.set('errors', null);
    }
  });

  _exports.default = _default;
});