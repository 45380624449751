define("trivver-frontend/pods/components/tos-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCw/gZ5r",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"modals/tos-modal\",[],[[\"@open\",\"@onYes\",\"@onNo\",\"@title\",\"@acceptEnabled\"],[[23,0,[\"tosModalOpen\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"isAgreedObserver\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"isAgreedObserver\"]]],null],false],null],\"Terms of service\",[23,0,[\"tosEnabled\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"blizzard-tos\",[],[[\"@acquireBottom\"],[[28,\"action\",[[23,0,[]],\"acquireBottom\"],null]]],{\"statements\":[[0,\"\\n    \"],[14,1],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"label\",true],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"class\",\"required\",\"change\"],[\"checkbox\",[23,0,[\"isAgreedObserver\"]],\"tos\",[23,0,[\"required\"]],[28,\"action\",[[23,0,[]],\"isAgreedChanged\"],null]]]],false],[0,\"\\n\\n  I have read and agree to\\n  \"],[7,\"a\",true],[10,\"href\",\"#\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"openModal\"],null]],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"Trivver terms and conditions\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/tos-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});