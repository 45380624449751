define("trivver-frontend/pods/components/interactive-file-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+0MiO+zR",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"file-input\",null,[[\"errors\",\"label\",\"field\",\"value\",\"changeFile\",\"class\",\"accept\",\"helpText\"],[[23,0,[\"errors\"]],[23,0,[\"label\"]],[23,0,[\"field\"]],[23,0,[\"value\"]],[23,0,[\"changeFile\"]],[23,0,[\"class\"]],[23,0,[\"accept\"]],[23,0,[\"helpText\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/interactive-file-input/template.hbs"
    }
  });

  _exports.default = _default;
});