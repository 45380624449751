define("trivver-frontend/pods/loading/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DwXFP435",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ember-view\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"background:rgba(0, 0, 0, 0.5);\"],[10,\"class\",\"ember-cli-spinner\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"style\",\"height:80px;width:80px;\"],[10,\"class\",\"sk-folding-cube magenta\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"sk-folding-cube1 sk-child magenta\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"sk-folding-cube2 sk-child magenta\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"sk-folding-cube3 sk-child magenta\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"sk-folding-cube4 sk-child magenta\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"load-text\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/loading/loading.hbs"
    }
  });

  _exports.default = _default;
});