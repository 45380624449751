define("trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/cells/email/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['title'],
    title: Ember.computed('record', function () {
      return this.get('record.email');
    })
  });

  _exports.default = _default;
});