define("trivver-frontend/pods/asset/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    uid: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    subtitle: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    is_active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    is_generic: (0, _model.attr)('boolean'),
    tags: (0, _model.attr)('string'),
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    campaign_id: (0, _model.attr)('number'),
    campaign_uid: (0, _model.attr)('string'),
    advertiser_id: (0, _model.attr)('number'),
    advertiser_uid: (0, _model.attr)('string'),
    categories: (0, _model.hasMany)('category'),
    file_tbso: (0, _model.attr)(),
    file_standalone: (0, _model.attr)(),
    file_ios: (0, _model.attr)(),
    file_android: (0, _model.attr)(),
    file_preview: (0, _model.attr)(),
    file_icon: (0, _model.attr)(),
    file_image: (0, _model.attr)(),
    file_coupon: (0, _model.attr)(),
    video_mobile: (0, _model.attr)(),
    video_standalone: (0, _model.attr)(),
    where_to_buy: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    arrayOfCategories: Ember.computed('categories.@each.id', function () {
      return this.categories.mapBy('id');
    }),
    hasTBSO: Ember.computed('file_tbso', function () {
      return Boolean(this.file_tbso);
    })
  });

  _exports.default = _default;
});