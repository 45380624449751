define("trivver-frontend/pods/home/cabinet/developer/funds-earned-details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    earningsColumns: [{
      propertyName: 'date',
      title: 'Date',
      component: 'models-table/funds-earned-daily/cells/date',
      className: 'mt-date'
    }, {
      propertyName: 'title',
      title: 'Property',
      className: 'mt-title'
    }, {
      propertyName: 'source',
      title: 'Property Type',
      className: 'mt-source'
    }, {
      propertyName: 'action',
      title: 'Action',
      className: 'mt-action'
    }, {
      propertyName: 'count',
      title: 'Count',
      className: 'mt-count'
    }, {
      propertyName: 'sum',
      title: 'Amount',
      className: 'mt-sum',
      component: 'models-table/funds-earned-daily/cells/sum'
    }],
    actions: {
      updateEarnings: function updateEarnings(start, end) {
        this.set('model.earnings', this.store.query('earnings-daily', {
          start: start,
          end: end
        }));
      }
    }
  });

  _exports.default = _default;
});