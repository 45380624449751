define("trivver-frontend/pods/components/modals/embedded-code/code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f2dCiVsR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"textarea\",true],[10,\"id\",\"embedded-code\"],[10,\"class\",\"form-control pp-campaign-embedded-code\"],[10,\"onclick\",\"this.focus();this.select()\"],[10,\"readonly\",\"readonly\"],[8],[0,\"<script src=\\\"https://unpkg.com/vue@2.6.10/dist/vue.min.js\\\"></script><script src=\\\"\"],[1,[22,\"host\"],false],[0,\"/ppa.umd.min.js\\\"></script><script src=\\\"\"],[1,[22,\"host\"],false],[0,\"/f.js\\\"></script><div id=\\\"tpp\\\"></div><script nonce=\\\"tpp\\\">cPP([\\\"\"],[1,[24,[\"campaign\",\"uid\"]],false],[0,\"\\\",\\\"\"],[1,[24,[\"campaign\",\"advertiser\",\"uid\"]],false],[0,\"\\\",\\\"\"],[1,[28,\"or\",[[24,[\"campaign\",\"anchorImagePreview\"]],null],null],false],[0,\"\\\",\\\"\"],[1,[28,\"or\",[[24,[\"campaign\",\"anchor_video\"]],null],null],false],[0,\"\\\"])</script>\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/embedded-code/code/template.hbs"
    }
  });

  _exports.default = _default;
});