define("trivver-frontend/pods/components/models-table/numeric-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sb7p9Dwj",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"paginate\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[29,[\"paginate_button previous \",[28,\"if\",[[23,0,[\"isActiveFirstPage\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"previous\"]],[8],[0,\"Prev\"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"visiblePageNumbers\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",[29,[\"paginate_button \",[28,\"if\",[[23,1,[\"isActive\"]],\"current\"],null]]]],[3,\"action\",[[23,0,[]],[23,0,[\"gotoCustomPage\"]],[23,1,[\"label\"]]]],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[29,[\"paginate_button next \",[28,\"if\",[[23,0,[\"isActiveLastPage\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"next\"]],[8],[0,\"Next\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/numeric-pagination/template.hbs"
    }
  });

  _exports.default = _default;
});