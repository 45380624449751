define("trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vzh7cejZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"title\",[23,0,[\"record\",\"title\"]]],[11,\"href\",[29,[\"/v2/advertiser/pp/campaigns/default?campaign=\",[23,0,[\"record\",\"id\"]]]]],[8],[1,[23,0,[\"record\",\"title\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/title/template.hbs"
    }
  });

  _exports.default = _default;
});