define("trivver-frontend/validators/phone-number", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/messages", "trivver-frontend/constants/variables"], function (_exports, _base, _messages, _variables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PhoneNumber = _base.default.extend({
    validate: function validate(value, opts, model) {
      if (opts.isValidField) {
        return model.get(opts.isValidField) || _messages.PHONE_NUMBER_INVALID;
      }

      if (value.match(_variables.PHONE_REGEX)) {
        return true;
      }

      return _messages.PHONE_NUMBER_ERROR;
    }
  });

  var _default = PhoneNumber;
  _exports.default = _default;
});