define("trivver-frontend/validators/end-date-limit", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/variables", "trivver-frontend/constants/messages"], function (_exports, _base, _variables, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convertToDate(value) {
    // creates date object without time (just define 00:00)
    return new Date(value.getYear(), value.getMonth(), value.getDate());
  }

  var EndDateLimit = _base.default.extend({
    validate: function validate(value) {
      if (!value) return true;
      var lastValidDay = new Date();
      lastValidDay.setDate(lastValidDay.getDate() + _variables.END_DATE_CAMPAIGN_LIMIT * 365);
      return convertToDate(value) <= convertToDate(lastValidDay) ? true : _messages.END_DATE_CAMPAIGN_ERROR;
    }
  });

  var _default = EndDateLimit;
  _exports.default = _default;
});