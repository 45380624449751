define("trivver-frontend/validators/age", ["exports", "ember-cp-validations/validators/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      // eslint-disable-line no-unused-vars
      return (0, _moment.default)().diff(value, 'years') >= options.min ? true : "You should be older than ".concat(options.min);
    }
  });

  _exports.default = _default;
});