define("trivver-frontend/pods/components/models-table/advertiser-campaigns/hints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    bso: 'Branded Smart Object - This is the branded 3D asset that was placed in the application.',
    t_views: 'A “T-View” is recorded when a user has viewed a 3D product model (BSO) the viewability was at least 5%. Viewability is analogous to the percent of the screen that is covered by the object. Only one T-View per BSO will be counted per each user/session. A new session will begin when the user exits and restarts the 3D application.',
    engagements: '“Engagements” shows the number of clicks to-date that each user has performed on Product Portal buttons at least once. Only one click for each product & button type is recorded for a single user/session. This limit restarts when the user begins a new application session.',
    budgeted: '“Budgeted” shows the initial dollar amount paid. “Remaining” represents the remaining dollar amount yet to be refunded.',
    spend: '“Spend” is the total charge for the campaign to-date.'
  };
  _exports.default = _default;
});