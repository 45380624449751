define("trivver-frontend/pods/components/models-table/delete-row/component", ["exports", "trivver-frontend/constants/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    helpMessage: _messages.NOT_DELETE_TBSO,
    actions: {
      onDeleteRow: function onDeleteRow(record) {
        var deleteRowAction = this.deleteRowAction;
        deleteRowAction(record);
      }
    }
  });

  _exports.default = _default;
});