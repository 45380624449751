define("trivver-frontend/pods/components/switcher-/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      switch: function _switch() {
        this.set('active', !this.active);

        if (this.action) {
          this.sendAction('action', this.active);
        }
      }
    }
  });

  _exports.default = _default;
});