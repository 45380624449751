define("trivver-frontend/pods/version/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE, "/game"),
    pathForType: function pathForType() {
      return 'versions/';
    },
    urlForQuery: function urlForQuery(query) {
      var currentHeaders = this.headers;
      currentHeaders['Game-UID'] = query.game_uid;
      delete currentHeaders['Game-Version']; // eslint-disable-next-line no-param-reassign

      delete query.game_uid;
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/versions/");
    },
    urlForCreateRecord: function urlForCreateRecord(id, snapshot) {
      var currentHeaders = this.headers;
      currentHeaders['Game-UID'] = snapshot.record.get('game.game_uid');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/versions/");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var model = snapshot.record;
      var currentHeaders = this.headers;
      currentHeaders['Game-UID'] = model.get('game.game_uid');
      var changedAttrs = model.changedAttributes();

      if (changedAttrs.version) {
        var _changedAttrs$version = _slicedToArray(changedAttrs.version, 1);

        currentHeaders['Game-Version'] = _changedAttrs$version[0];
      } else {
        currentHeaders['Game-Version'] = model.get('version');
      }

      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/version/");
    },
    urlForFindHasMany: function urlForFindHasMany(id, modelName, snapshot) {
      // "Actually endpoint to make post '/publish' request"
      // for this was chosen most rarely used url name
      var currentHeaders = this.headers;
      currentHeaders['Game-UID'] = snapshot.record.get('game.game_uid');
      currentHeaders['Game-Version'] = snapshot.record.get('version');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/version/");
    }
  });

  _exports.default = _default;
});