define("trivver-frontend/pods/pp-campaign/model", ["exports", "trivver-frontend/constants/date-formats", "@ember-data/model", "trivver-frontend/constants/deployment-types", "ember-cp-validations", "trivver-frontend/mixins/copyable", "trivver-frontend/constants/variables", "trivver-frontend/config/environment", "trivver-frontend/constants/campaign-types", "ember-api-actions", "moment"], function (_exports, _dateFormats, _model, _deploymentTypes, _emberCpValidations, _copyable, _variables, _environment, _campaignTypes, _emberApiActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      max: 70
    })],
    budget: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("number", {
      allowString: true,
      gte: 0
    })],
    start: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("campaign-start-date")],
    end: [(0, _emberCpValidations.validator)("end-date-limit"), (0, _emberCpValidations.validator)("dependent", {
      on: ["start"],
      message: "Missed or incorrect start date."
    })],
    isAgreed: (0, _emberCpValidations.validator)("boolean", {
      presence: true
    }),
    assets: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Add at least one product."
    }), (0, _emberCpValidations.validator)("has-many", true), (0, _emberCpValidations.validator)("has-primary-asset", true)],
    anchor_image: [(0, _emberCpValidations.validator)("image-format"), (0, _emberCpValidations.validator)("image-size"), (0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Please select image file from your disk.",
      disabled: Ember.computed("model.validationAttempted", "model.anchor_video", "model.deployment_type", function () {
        var isDisabled = this.get("model.deployment_type") === _deploymentTypes.NETWORK || this.get("model.deployment_type") === _deploymentTypes.NETWORK_AR || this.get("model.anchor_video") || !this.get("model.validationAttempted");
        return isDisabled;
      })
    })],
    brand_logo: [(0, _emberCpValidations.validator)("image-format"), (0, _emberCpValidations.validator)("image-size")],
    deployment_type: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Please select one of the options."
    })],
    brand_name: [(0, _emberCpValidations.validator)("length", {
      max: 40
    })],
    design: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Please select one of designs."
    })],
    brand_url: [(0, _emberCpValidations.validator)("length", {
      max: 128
    }), (0, _emberCpValidations.validator)("url")],
    slug: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      max: 128
    })],
    mv_url: [(0, _emberCpValidations.validator)("format", {
      regex: /(^$|^https?:\/\/[\w. /%=&#@*(){}+?!^$-_~\\]+$)/i,
      message: "Incorrect url"
    }), (0, _emberCpValidations.validator)("length", {
      max: 1024
    })]
  }, {
    disabled: Ember.computed("model.validationAttempted", function () {
      return !this.get("model.validationAttempted");
    })
  });

  var previewOrNull = function previewOrNull(file) {
    if (file && file.size) {
      return URL.createObjectURL(file);
    }

    if (typeof file === "string") {
      return "".concat(file.startsWith("http") ? "" : "https://").concat(file);
    }

    return null;
  };

  var _default = _model.default.extend(_copyable.default, Validations, {
    campaign_type: (0, _model.attr)("string", {
      defaultValue: "original"
    }),
    enabled: (0, _model.attr)("boolean", {
      defaultValue: true
    }),
    title: (0, _model.attr)("string"),
    description: (0, _model.attr)("string"),
    start: (0, _model.attr)("pst", {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    end: (0, _model.attr)("pst-end"),
    created: (0, _model.attr)("date"),
    modified: (0, _model.attr)("date"),
    uid: (0, _model.attr)("string"),
    status: (0, _model.attr)("string"),
    anchor_image: (0, _model.attr)(),
    anchor_video: (0, _model.attr)("nullable"),
    type: (0, _model.attr)("string", {
      defaultValue: _campaignTypes.PRODUCT_PORTAL
    }),
    bso: (0, _model.attr)("string"),
    isAgreed: (0, _model.attr)("boolean"),
    impressions: (0, _model.attr)(),
    clicks: (0, _model.attr)(),
    ctr: (0, _model.attr)(),
    total_count: (0, _model.attr)(),
    total_sec: (0, _model.attr)(),
    total_cost: (0, _model.attr)("cent"),
    view_time: (0, _model.attr)(),
    deployment_type: (0, _model.attr)("string"),
    brand_name: (0, _model.attr)("forced-string"),
    brand_url: (0, _model.attr)("forced-string"),
    share_url: (0, _model.attr)("forced-string"),
    master_campaign_id: (0, _model.attr)("number"),
    design: (0, _model.attr)("string"),
    brand_logo: (0, _model.attr)(),
    slug: (0, _model.attr)("string"),
    mv_name: (0, _model.attr)("string"),
    mv_coordinate_x: (0, _model.attr)("number"),
    mv_coordinate_y: (0, _model.attr)("number"),
    mv_platform: (0, _model.attr)("string"),
    mv_url: (0, _model.attr)(),
    t_view_total_count: (0, _model.attr)(),
    advertiser: (0, _model.belongsTo)("org", {
      async: true
    }),
    assets: (0, _model.hasMany)("pp-asset", {
      async: true
    }),
    isEndedOrStarted: Ember.computed.or("isStarted", "isEnded"),
    startDateTime: Ember.computed("start", function () {
      return (0, _moment.default)(this.start).format(_dateFormats.MAIN_DATE_TIME_FORMAT);
    }),
    endDateTime: Ember.computed("end", function () {
      return this.end ? (0, _moment.default)(this.end).format(_dateFormats.MAIN_DATE_TIME_FORMAT) : "";
    }),
    minStartDate: Ember.computed("start", function () {
      if (this.isStarted || this.isEnded) {
        return new Date(this.start);
      }

      return new Date(Date.now());
    }),
    minEndDate: Ember.computed("start", function () {
      if (!this.start) {
        return new Date(Date.now());
      }

      var date = this.start;

      if (new Date(this.start) < Date.now()) {
        date = Date.now();
      }

      return new Date((0, _moment.default)(date).add(1, "days"));
    }),
    maxEndDate: Ember.computed("start", function () {
      var date = new Date((0, _moment.default)(Date.now()).add(_variables.END_DATE_CAMPAIGN_LIMIT, "years"));
      return date;
    }),
    startDate: Ember.computed("start", {
      get: function get() {
        return (0, _moment.default)(this.start).format(_dateFormats.SEND_DATE_FORMAT);
      },
      set: function set() {
        var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "start";
        var value = arguments.length > 1 ? arguments[1] : undefined;
        return this.set(key, value);
      }
    }),
    isStarted: Ember.computed("start", "hasDirtyAttributes", "balance", function () {
      return this.start < new Date() && !this.changedAttributes().start && this.balance > 0;
    }),
    isEnded: Ember.computed("end", "hasDirtyAttributes", "balance", "enabled", function () {
      return this.end && this.end <= new Date() && !this.changedAttributes().end;
    }),
    primaryAsset: Ember.computed("assets.@each.is_primary", function () {
      return this.assets.filterBy("is_primary", true)[0];
    }),
    anchorImagePreview: Ember.computed("anchor_image", function () {
      var url = previewOrNull(this.anchor_image);

      if (url && !url.startsWith("blob:")) {
        url = "".concat(url, "?").concat(this.modified / 1);
      }

      return url;
    }),
    anchorImageNoCachePreview: Ember.computed("anchor_image", function () {
      var url = previewOrNull(this.anchor_image);

      if (url && !url.startsWith("blob:")) {
        url = "".concat(url, "?").concat(Date.now() / 1);
      }

      return url;
    }),
    brandLogoPreview: Ember.computed("brand_logo", function () {
      var url = previewOrNull(this.brand_logo);

      if (url && !url.startsWith("blob:")) {
        url = "".concat(url, "?").concat(this.modified / 1);
      }

      return url;
    }),
    sortedAssets: Ember.computed.sort("assets", function (a, b) {
      var field = "created";

      if (a.get(field) > b.get(field)) {
        return 1;
      }

      if (a.get(field) < b.get(field)) {
        return -1;
      }

      return 0;
    }),
    isForWebsite: Ember.computed("deployment_type", function () {
      return this.deployment_type === _deploymentTypes.WEBSITE;
    }),
    isForAdNetwork: Ember.computed("deployment_type", function () {
      return this.deployment_type === _deploymentTypes.NETWORK || this.deployment_type === _deploymentTypes.NETWORK_AR;
    }),
    adSiteLink: Ember.computed("share_url", "type", function () {
      if (this.type === "T") return "".concat(this.share_url, "?view=ar");
      return this.share_url;
    }),
    adSiteLinkForEmail: Ember.computed("adSiteLink", "type", function () {
      if (this.type === "T") return "".concat(this.adSiteLink, "&type=email");
      return "".concat(this.adSiteLink, "?type=email");
    }),
    emailButtonImageURL: Ember.computed(function () {
      return "".concat(_environment.default.APP.EMBER_HOST, "/img/pp.jpeg");
    }),
    emailButtonCode: Ember.computed("adSiteLink", function () {
      return "<a href=\"".concat(this.adSiteLinkForEmail, "\">") + '<img width="32" height="32" ' + "src=\"".concat(this.emailButtonImageURL, "\"/>") + "</a>";
    }),
    deploymentType: Ember.computed("deployment_type", function () {
      return {
        W: "Website (mobile & desktop)",
        N: "Ad Network (Product Portal)",
        NAR: "Ad Network (AR Only)"
      }[this.deployment_type];
    }),
    campaignType: Ember.computed("type", function () {
      return {
        P: "Commerce Portal",
        T: "AR Viewer or T-Mod"
      }[this.type];
    }),
    labelForGetLink: Ember.computed("type", function () {
      return {
        P: "Get Ad Network Link",
        T: "Get AR Viewer Link"
      }[this.type];
    }),
    mvCoordinatesString: Ember.computed("mv_coordinate_x", "mv_coordinate_y", function () {
      var mvX = this.mv_coordinate_x;
      var mvY = this.mv_coordinate_y;

      if (mvX && mvY) {
        return "".concat(mvX, ", ").concat(mvY);
      }

      return "";
    }),
    uploadFiles: (0, _emberApiActions.memberAction)({
      path: "asset-files/",
      type: "post",
      urlType: "query"
    }),
    getFilesResult: (0, _emberApiActions.memberAction)({
      path: "task-status/",
      type: "get",
      urlType: "query"
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: "deactivate/",
      type: "PUT"
    })
  });

  _exports.default = _default;
});