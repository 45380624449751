define("trivver-frontend/services/earned-funds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    getFunds: function getFunds(gameUID, month) {
      this.set('ajax.customHeaders.Game-UID', gameUID);
      var result = this.ajax.request('/game/amount/', {
        method: 'GET',
        data: {
          month: month
        }
      });
      this.set('ajax.customHeaders', {});
      return result;
    }
  });

  _exports.default = _default;
});