define("trivver-frontend/pods/register/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE, "/auth"),
    pathForType: function pathForType() {
      return 'register/';
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/auth");
    }
  });

  _exports.default = _default;
});