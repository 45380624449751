define("trivver-frontend/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(args) {
      return args.filter(function (arg) {
        return arg !== undefined;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
    }
  });

  _exports.default = _default;
});