define("trivver-frontend/pods/components/image-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['image-preview'],
    attributeBindings: ['style'],
    style: Ember.computed('image', function () {
      var image = this.image;
      if (!image) return null;
      return "background-image:url(".concat(image, ");");
    })
  });

  _exports.default = _default;
});