define("trivver-frontend/pods/components/with-cp-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+IP4ETs",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"get\",[[23,0,[\"model\",\"validations\",\"attrs\"]],[28,\"concat\",[[23,0,[\"field\"]],\".errors\"],null]],null],\"has-error\"],null]]]],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[4,\"each\",[[28,\"get\",[[23,0,[\"model\",\"validations\",\"attrs\"]],[28,\"concat\",[[23,0,[\"field\"]],\".errors\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"help-block\"],[8],[1,[23,1,[\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/with-cp-errors/template.hbs"
    }
  });

  _exports.default = _default;
});