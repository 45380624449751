define("trivver-frontend/pods/stripe-checkout/service", ["exports", "accounting/format-money", "trivver-frontend/config/environment"], function (_exports, _formatMoney, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    campaignImage: '/img/general/logo_official.png',
    stripePK: _environment.default.stripe.publishableKey,
    ppStripePK: _environment.default.stripe.ppPublishableKey,
    openCheckoutForm: function openCheckoutForm(campaignTitle, amount, callbackFunc, closeFunc) {
      var campaignType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'G';
      var tokenObtained = false;
      var stripeKeyName = campaignType === 'G' ? 'stripePK' : 'ppStripePK'; // eslint-disable-next-line no-undef

      var handler = StripeCheckout.configure({
        key: this.get(stripeKeyName),
        image: this.campaignImage,
        locale: 'en',
        apiVersion: '2019-12-03',
        token: function token(_token) {
          // You can access the token ID with `token.id`.
          // Get the token ID to your server-side code for use.
          callbackFunc(_token);
          tokenObtained = !tokenObtained;
        }
      });
      handler.open({
        name: 'Trivver Campaign',
        description: "".concat(campaignTitle, " (").concat((0, _formatMoney.default)(amount / 100), ")"),
        amount: amount,
        closed: function closed() {
          if (!tokenObtained) {
            closeFunc();
          }
        }
      });
    }
  });

  _exports.default = _default;
});