define("trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/cells/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hJhV6M2l",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"truncate\",[[23,0,[\"record\",\"email\"]],32],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/cells/email/template.hbs"
    }
  });

  _exports.default = _default;
});