define("trivver-frontend/pods/home/cabinet/payment-history/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "trivver-frontend/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _scrollToTop.default, {
    breadCrumb: {
      title: 'Payment History',
      linkable: false
    },
    currentUser: Ember.inject.service(),
    spinner: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.get('currentUser.user'),
        org: this.store.queryRecord('org', {
          my: true
        }),
        history: this.store.findAll('payment-history')
      });
    }
  });

  _exports.default = _default;
});