define("trivver-frontend/pods/components/inputs/dropdown-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ftOdM5zd",
    "block": "{\"symbols\":[\"name\"],\"statements\":[[7,\"div\",true],[11,\"id\",[29,[[23,0,[\"uniqueId\"]]]]],[10,\"class\",\"category-selector\"],[8],[0,\"\\n  \"],[5,\"power-select\",[],[[\"@options\",\"@searchEnabled\",\"@noMatchesMessage\",\"@onChange\"],[[23,0,[\"getList\"]],false,\"\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"value\"]]],null]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,0,[\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n  \"],[1,[28,\"input\",null,[[\"value\",\"autocomplete\",\"focus-in\",\"class\"],[[23,0,[\"getValue\"]],\"off\",[28,\"action\",[[23,0,[]],\"openSelect\"],null],\"form-control\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/dropdown-input/template.hbs"
    }
  });

  _exports.default = _default;
});