define("trivver-frontend/pods/components/partials/pp-website-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKbRyv0f",
    "block": "{\"symbols\":[],\"statements\":[[5,\"copyable-content\",[[12,\"class\",\"pull-left\"]],[[\"@buttonText\",\"@copyButtonText\",\"@buttonClass\"],[\"Get Embed Code\",\"Copy Code\",[23,0,[\"buttonClass\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Select \"],[7,\"code\",true],[8],[0,\"Copy Code\"],[9],[0,\" below to copy, then paste the \\n  Embed code where you want.\"],[9],[0,\"\\n  \"],[7,\"textarea\",true],[10,\"class\",\"form-control mb10\"],[10,\"readonly\",\"readonly\"],[10,\"onclick\",\"this.focus();this.select()\"],[10,\"rows\",\"10\"],[8],[0,\"<script src=\\\"https://unpkg.com/vue@2.6.10/dist/vue.min.js\\\"></script><script src=\\\"\"],[1,[23,0,[\"host\"]],false],[0,\"/ppa.umd.min.js\\\"></script><script src=\\\"\"],[1,[23,0,[\"host\"]],false],[0,\"/f.js\\\"></script><div id=\\\"tpp\\\"></div><script nonce=\\\"tpp\\\">cPP([\\\"\"],[1,[23,0,[\"campaign\",\"uid\"]],false],[0,\"\\\",\\\"\"],[1,[23,0,[\"campaign\",\"advertiser\",\"uid\"]],false],[0,\"\\\",\\\"\"],[1,[28,\"or\",[[23,0,[\"campaign\",\"anchorImagePreview\"]],null],null],false],[0,\"\\\",\\\"\"],[1,[28,\"or\",[[23,0,[\"campaign\",\"anchor_video\"]],null],null],false],[0,\"\\\"])</script><script src=\\\"\"],[1,[23,0,[\"host\"]],false],[0,\"/fallbackPollyfill.js\\\"></script>\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/partials/pp-website-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});