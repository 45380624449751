define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/color-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    colorSettings: Ember.computed('model.campaign', function () {
      var design = this.get('model.campaign.design');
      var designs = this.get('model.designs').reduce(function (result, model) {
        var res = result;
        res[model.get('id')] = model.get('color_settings');
        return res;
      }, {});
      return designs[design];
    })
  });

  _exports.default = _default;
});