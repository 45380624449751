define("trivver-frontend/transforms/pst-end", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var END_WITH_TZ = 'YYYY-MM-DDT23:59:00Z';
  var END_WITHOUT_TZ = 'YYYY-MM-DDT23:59:00';
  var PST = 'America/Los_Angeles';

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) return null;
      return new Date((0, _moment.default)(serialized).tz(PST).format(END_WITHOUT_TZ));
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) return null; // force converting to PST midn

      return (0, _moment.default)(deserialized).tz(PST, true).format(END_WITH_TZ);
    }
  });

  _exports.default = _default;
});