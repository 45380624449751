define("trivver-frontend/mixins/copyable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    copy: function copy(deepClone) {
      var model = this;
      var attrs = model.toJSON();
      var classType = model.constructor;
      var root = Ember.String.decamelize(classType.toString().split(':')[1]);

      if (deepClone) {
        this.eachRelationship(function (key, relationship) {
          if (relationship.kind === 'belongsTo') {
            attrs[key] = model.get(key).copy ? model.get(key).copy(true) : model.get(key);
          } else if (relationship.kind === 'hasMany' && Ember.isArray(attrs[key])) {
            attrs[key].splice(0);
            model.get(key).forEach(function (obj) {
              attrs[key].addObject(obj.copy ? obj.copy(true) : obj);
            });
          }
        });
      }

      return this.store.createRecord(root, attrs);
    }
  });

  _exports.default = _default;
});