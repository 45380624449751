define("trivver-frontend/pods/phone-number/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    number: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    code: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })]
  });

  var _default = _model.default.extend(Validations, {
    number: (0, _model.attr)('string'),
    code: (0, _model.attr)('string', {
      defaultValue: null
    }),
    slug: (0, _model.attr)('string')
  });

  _exports.default = _default;
});