define("trivver-frontend/pods/components/models-table/advertiser-products-min/product-radio-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'checked', 'disabled', 'title'],
    type: 'radio',
    checked: Ember.computed(function () {
      return this.get('record.is_primary');
    }),
    disabled: true,
    title: Ember.computed('record.is_primary', function () {
      return this.checked ? 'Primary product' : null;
    })
  });

  _exports.default = _default;
});