define("trivver-frontend/helpers/is-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isCollection = isCollection;

  function isCollection(value) {
    return Array.isArray(value[0]);
  }

  var _default = Ember.Helper.helper(isCollection);

  _exports.default = _default;
});