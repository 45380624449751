define("trivver-frontend/constants/entity-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INDIVIDUAL = _exports.COMPANY = void 0;
  var COMPANY = 'company';
  _exports.COMPANY = COMPANY;
  var INDIVIDUAL = 'individual';
  _exports.INDIVIDUAL = INDIVIDUAL;
});