define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/cpv/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5HGA6Xme",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"if\",[[23,0,[\"value\"]],[28,\"format-money\",[[28,\"cent-to-dollar\",[[23,0,[\"value\"]]],null]],[[\"symbol\"],[\"$\"]]],\"-\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/cpv/template.hbs"
    }
  });

  _exports.default = _default;
});