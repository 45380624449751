define("trivver-frontend/helpers/currency-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      // eslint-disable-line no-unused-vars
      return parseFloat(params[0] / 100).toFixed(2);
    }
  });

  _exports.default = _default;
});