define("trivver-frontend/pods/components/models-table/advertiser-coupons/remove-coupon-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qct6j2fj",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Remove Coupon\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-coupons/remove-coupon-button/template.hbs"
    }
  });

  _exports.default = _default;
});