define("trivver-frontend/pods/components/inputs/custom-file-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      browse: function browse() {
        Ember.$("#id_".concat(this.field)).click();
      },
      changeFile: function changeFile(field, file) {
        this.sendAction('changeFile', field, file);
      }
    },
    fileName: Ember.computed('model', {
      get: function get() {
        var model = this.model;
        var empty = '';

        if (!model) {
          return empty;
        }

        var filePath = model.get("".concat(this.field));

        if (typeof filePath === 'string') {
          return 'File uploaded';
        }

        filePath = Ember.$("#id_".concat(this.field)).val();

        if (filePath) {
          return filePath.substring(12);
        }

        return empty;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$("#id_".concat(this.field)).change(function (change) {
        if (change.target.value) {
          _this.notifyPropertyChange('fileName');
        }
      });
    }
  });

  _exports.default = _default;
});