define("trivver-frontend/mixins/routes/unlogged-user-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      var userType = this.get('currentUser.user.type'); // skip redirecting if logout param exists (force logout)

      if (transition.queryParams && transition.queryParams.logout) return;

      if (userType === 'AD') {
        this.transitionTo('home.cabinet.advertiser');
      } else if (userType === 'DEV') {
        this.transitionTo('home.cabinet.developer');
      }
    }
  });

  _exports.default = _default;
});