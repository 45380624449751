define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/update/coupons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G/o5zx3w",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"form\",false],[3,\"action\",[[23,0,[]],\"create\",[23,0,[\"model\",\"campaign\"]]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"page__content\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"page-subtitle\"],[8],[0,\"Create Coupons\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\",\"campaign\",\"assets\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"models-table/advertiser-coupons\",null,[[\"data\",\"showProductCouponForm\",\"removeCoupon\"],[[23,0,[\"model\",\"campaign\",\"sortedAssets\"]],\"showProductCouponForm\",\"removeCoupon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mt30 mb30 buttons\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"campaignCopy\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-grey btn-short\"],[3,\"action\",[[23,0,[]],\"cancelChanges\",[23,0,[\"model\"]]]],[8],[0,\"\\n        Cancel Changes\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-blue btn-short\"],[3,\"action\",[[23,0,[]],\"goto\",\"review\"]],[8],[0,\"\\n      Update\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"modals/product-coupon-form\",null,[[\"onHideAction\",\"open\",\"saveProductCouponAction\",\"product\"],[\"cleanOutTemporary\",[23,0,[\"showProductCouponFormModal\"]],\"saveProductCouponAction\",[23,0,[\"product\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/update/coupons/template.hbs"
    }
  });

  _exports.default = _default;
});