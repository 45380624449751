define("trivver-frontend/pods/pp-campaign/adapter", ["exports", "trivver-frontend/pods/drf/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    assetRequests: Ember.inject.service(),
    pathForType: function pathForType() {
      return "productportal/campaigns";
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (payload !== null && payload !== void 0 && payload.assets) {
        var primaryAssetIDX = this.assetRequests.getPrimaryAssetIDX();
        this.assetRequests.setPrimaryAssetID(payload.assets[primaryAssetIDX]);
      }

      return this._super(status, headers, payload);
    }
  });

  _exports.default = _default;
});