define("trivver-frontend/validators/card-expirity", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CardExpirity = _base.default.extend({
    stripe: Ember.inject.service(),
    validate: function validate(value
    /* options, model, attribute */
    ) {
      var stripe = this.stripe;
      return stripe.card.validateExpiry(value) ? true : 'Invalid  expiration date';
    }
  });

  var _default = CardExpirity;
  _exports.default = _default;
});