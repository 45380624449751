define("trivver-frontend/pods/components/image-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Image upload component
   *
   * It's used to image upload and to save it in ``userImage``
   *
   * @class ImageUploadComponent
   * @module component/image-upload
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Upload and preview image
       *
       * Upload user's image in userImage and preview this
       *
       * @method uploadImage
       * @return {undefined}
       * @public
       */
      uploadImage: function uploadImage(evt) {
        if (evt.target.files && evt.target.files[0]) {
          var image = evt.target.files[0];
          var ext = image.name.substr(image.name.lastIndexOf('.') + 1);

          if (['jpeg', 'png', 'jpg'].includes(ext.toLowerCase())) {
            this.set('userImage', image);
            Ember.$('.preview').attr('src', URL.createObjectURL(image));
            this.set('errors', null);
          } else {
            this.set('errors', {
              detail: 'The image was not loaded. Only files with jpeg, png extension are allowed'
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});