define("trivver-frontend/pods/components/estimated-reach-help-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qwr2briv",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Based off of average usage, this is the estimated number of users that would \\nsee your advertisement.\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/estimated-reach-help-text/template.hbs"
    }
  });

  _exports.default = _default;
});