define("trivver-frontend/pods/earnings-daily/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    date: (0, _model.attr)('date'),
    title: (0, _model.attr)('string'),
    source: (0, _model.attr)('string'),
    action: (0, _model.attr)('string'),
    count: (0, _model.attr)('number'),
    sum: (0, _model.attr)('cent')
  });

  _exports.default = _default;
});