define("trivver-frontend/pods/asset-files/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function nullOrFileLink(file, url) {
    return file ? URL.createObjectURL(file) : url;
  }

  var _default = _model.default.extend({
    file_tbso: (0, _model.attr)('file'),
    file_icon: (0, _model.attr)('file'),
    file_image: (0, _model.attr)('file'),
    video_mobile: (0, _model.attr)('file'),
    asset: (0, _model.belongsTo)('asset'),
    uploadFiles: (0, _emberApiActions.memberAction)({
      path: 'upload/',
      type: 'post',
      urlType: 'createRecord'
    }),
    iconPreview: Ember.computed('file_icon', 'asset.file_icon', function () {
      var url = this.get('asset.file_icon');
      var file = this.file_icon;
      return nullOrFileLink(file, url);
    }),
    imagePreview: Ember.computed('file_image', 'asset.file_image', function () {
      var url = this.get('asset.file_image');
      var file = this.file_image;
      return nullOrFileLink(file, url);
    })
  });

  _exports.default = _default;
});