define("trivver-frontend/constants/deployment-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WEBSITE = _exports.NETWORK_AR = _exports.NETWORK = _exports.GAME = void 0;
  var GAME = 'G';
  _exports.GAME = GAME;
  var WEBSITE = 'W';
  _exports.WEBSITE = WEBSITE;
  var NETWORK = 'N';
  _exports.NETWORK = NETWORK;
  var NETWORK_AR = 'NAR';
  _exports.NETWORK_AR = NETWORK_AR;
});