define("trivver-frontend/services/history-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    stack: null,
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line


      this.set('stack', []);
    },
    getDefaultUrl: function getDefaultUrl() {
      if (this.get('currentUser.user.type') === 'AD') {
        return ['home.cabinet.advertiser'];
      }

      return ['home.cabinet.developer'];
    },
    popUrl: function popUrl() {
      var preventDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var url = this.stack.popObject();

      if (!url) {
        return preventDefault ? url : this.getDefaultUrl();
      }

      return url;
    },
    pushUrl: function pushUrl() {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }

      return this.stack.pushObject(params);
    },
    clear: function clear() {
      this.set('stack', []);
    },
    isEmpty: function isEmpty() {
      return !this.stack.length;
    }
  });

  _exports.default = _default;
});