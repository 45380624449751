define("trivver-frontend/pods/screenshot/adapter", ["exports", "ember-cli-form-data/mixins/form-data-adapter", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _formDataAdapter, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_formDataAdapter.default, {
    namespace: "".concat(_environment.default.APP.API_NAMESPACE, "/game"),
    urlForQuery: function urlForQuery(query) {
      this.customHeaders['Game-UID'] = query.game_uid;
      delete query.game_uid; // eslint-disable-line no-param-reassign

      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/screenshots/");
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      this.customHeaders['Game-UID'] = snapshot.record.get('game.game_uid');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/screenshots/").concat(id, "/");
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      this.customHeaders['Game-UID'] = snapshot.record.get('game.game_uid');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/screenshots/").concat(id, "/");
    },
    urlForCreateRecord: function urlForCreateRecord(model, snapshot) {
      this.customHeaders['Game-UID'] = snapshot.record.get('game.game_uid');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/screenshots/");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      this.customHeaders['Game-UID'] = snapshot.record.get('game.game_uid');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/screenshots/").concat(id, "/");
    }
  });

  _exports.default = _default;
});