define("trivver-frontend/pods/pp-campaign-vimeo-upload-url/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    upload_url: (0, _model.attr)('string'),
    link: (0, _model.attr)('string')
  });

  _exports.default = _default;
});