define("trivver-frontend/pods/components/design-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aarrhgMp",
    "block": "{\"symbols\":[\"design\"],\"statements\":[[4,\"each\",[[23,0,[\"designs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n    \"],[5,\"design-list/item\",[[12,\"disabled\",[23,0,[\"disabled\"]]],[12,\"class\",[28,\"if\",[[28,\"eq\",[[23,0,[\"selected\"]],[23,1,[\"id\"]]],null],\"selected\"],null]]],[[\"@record\",\"@setDesign\"],[[23,1,[]],\"setDesign\"]],{\"statements\":[[0,\"\\n      \"],[7,\"h3\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n      \"],[7,\"input\",true],[11,\"id\",[23,1,[\"id\"]]],[11,\"checked\",[28,\"eq\",[[23,0,[\"selected\"]],[23,1,[\"id\"]]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"setDesign\",[23,1,[]]],null]],[11,\"value\",[23,1,[\"id\"]]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"preview\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\",true],[10,\"class\",\"preview\"],[11,\"src\",[29,[[23,1,[\"preview\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/design-list/template.hbs"
    }
  });

  _exports.default = _default;
});