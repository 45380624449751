define("trivver-frontend/mixins/routes/force-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.queryParams && transition.queryParams.logout && this.get('session.isAuthenticated')) {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});