define("trivver-frontend/pods/components/models-table/advertiser-campaign/component", ["exports", "trivver-frontend/pods/components/models-table/advertiser-campaign/theme", "trivver-frontend/pods/components/models-table/hints"], function (_exports, _theme, _hints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    campaignsData: [],
    attributeBindings: ['style'],
    themeInstance: _theme.default.create(),
    columns: Ember.computed(function () {
      return [{
        title: '',
        propertyName: 'title',
        disableSorting: true,
        className: 'mt-engagement-name'
      }, {
        title: 'Clicks',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.clicks,
        propertyName: 'clicks',
        disableSorting: true,
        className: 'mt-clicks orderable'
      }, {
        title: 'CPC',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.cpc,
        propertyName: 'cost_per_click',
        className: 'mt-cpc orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/cost-per-click'
      }, {
        title: 'Total',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.total,
        propertyName: 'cost',
        className: 'mt-total orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/cost'
      }];
    })
  });

  _exports.default = _default;
});