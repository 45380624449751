define("trivver-frontend/pods/components/modals/upload-smart-object/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      changeFile: function changeFile(field, file) {
        this.set('model.file_tbso', file);
      },
      hide: function hide() {
        this.set('open', false);
        this.set('errors', null);
        this.model.destroyRecord();
      },
      show: function show() {
        this.set('open', true);
        this.set('model', this.store.createRecord('asset-3d-model'));
      }
    }
  });

  _exports.default = _default;
});