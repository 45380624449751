define("trivver-frontend/pods/components/link-to-docs/component", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    path: '',
    target: '_blank',
    attributeBindings: ['href', 'path', 'target', 'title'],
    href: Ember.computed(function () {
      var host = _environment.default.docsHost || 'https://docs.trivver.com';
      var path = this.path;
      return "".concat(host, "/").concat(path);
    })
  });

  _exports.default = _default;
});