define("trivver-frontend/constants/messages", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ZIP_CODE_ERROR = _exports.YOU_CAN_NOT_REMOVE_PRIMARY_PRODUCT = _exports.YOU_CAN_NOT_REMOVE_LAST_PRODUCT = _exports.VIDEO_SIZE_ERROR = _exports.VIDEO_FORMAT_ERROR = _exports.VERIFICATION_STEP_MESSAGE = _exports.VERIFICATION_CODE_SENT = _exports.VERIFICATION_CODE_INFO = _exports.VALIDATOR_NOT_BLANK = _exports.VALIDATION_ERROR = _exports.TBSO_FILE_SIZE_ERROR = _exports.TBSO_FILE_FORMAT_ERROR = _exports.SPOT_EMPTY_DESCRIPTION = _exports.SOMETHING_GOES_WRONG = _exports.SMART_OBJECT_TITLE_EXISTS = _exports.SMART_OBJECT_CREATED = _exports.SHOULD_CONTAIN_A_VALID_PROTO = _exports.SELECT_PRIMARY_PRODUCT = _exports.REQUIRED = _exports.PHONE_NUMBER_INVALID = _exports.PHONE_NUMBER_ERROR = _exports.PHONE_CHANGED = _exports.PASSWORD_VALIDATOR_UPPER_CASE = _exports.PASSWORD_VALIDATOR_SPECIAL_CHAR = _exports.PASSWORD_VALIDATOR_LOWER_CASE = _exports.PASSWORD_VALIDATOR_DIGIT = _exports.PASSWORD_SUCCESSFULLY_RESET = _exports.PASSWORD_SUCCESSFULLY_CHANGED = _exports.PASSWORD_CONFIRMATION = _exports.PASSWORD_CHANGE_VALIDATOR_LENGTH = _exports.PASSWORD_CHANGE_PASSWORD_NOT_VALID = _exports.PASSWORD_CHANGE_FORM_HAS_ERRORS = _exports.PASSWORD_CHANGE_CONFIRMATION = _exports.NO_MATCHED_CATEGORY_MESSAGE = _exports.NOT_DELETE_TBSO = _exports.NEED_ALLOW_SMS = _exports.INVALID_URL_ERROR = _exports.IMAGE_SIZE_ERROR = _exports.IMAGE_FORMAT_ERROR = _exports.FORM_HAS_ERRORS = _exports.FAILED_TO_PAY = _exports.ERROR_500 = _exports.END_DATE_CAMPAIGN_ERROR = _exports.EMAIL_ERROR = _exports.COUPON_CREATED = _exports.COUNTRY_ERROR = _exports.COPIED_TO_THE_CLIPBOARD = _exports.CLEAR_FILTERING_PARAMS = _exports.CHANGES_SUCCESSFULLY_SAVED = _exports.CATEGORY_REQUEST_SENT = _exports.CATEGORY_ALREADY_EXIST = _exports.CAMPAIGN_DEACTIVATED = _exports.ASSET_TBSO_FILE_INVALID = _exports.ASSET_TBSO_FILE_CORRUPTED = _exports.ASSET_3D_MODEL_REMOVED = _exports.API_ERROR_PASSWORD = _exports.API_ERROR = void 0;
  // eslint-disable-next-line no-console
  console.log("Message ENV: ", _environment.default);
  var API_ERROR = String("Unfortunately, there are some problems with the data you committed");
  _exports.API_ERROR = API_ERROR;
  var API_ERROR_PASSWORD = String("Unable to Save Password. Please check the password requirements, or disable Ad blocking software.");
  _exports.API_ERROR_PASSWORD = API_ERROR_PASSWORD;
  var EMAIL_ERROR = "Wrong or already registered email address";
  _exports.EMAIL_ERROR = EMAIL_ERROR;
  var PHONE_NUMBER_ERROR = "This field must be a valid phone number (begin with a plus sign + and length 11 characters)";
  _exports.PHONE_NUMBER_ERROR = PHONE_NUMBER_ERROR;
  var PHONE_NUMBER_INVALID = "Please enter a valid phone number";
  _exports.PHONE_NUMBER_INVALID = PHONE_NUMBER_INVALID;
  var COUNTRY_ERROR = "Country not support";
  _exports.COUNTRY_ERROR = COUNTRY_ERROR;
  var SPOT_EMPTY_DESCRIPTION = "Spot description is empty";
  _exports.SPOT_EMPTY_DESCRIPTION = SPOT_EMPTY_DESCRIPTION;
  var FAILED_TO_PAY = "Failed to pay";
  _exports.FAILED_TO_PAY = FAILED_TO_PAY;
  var SOMETHING_GOES_WRONG = "Something goes wrong";
  _exports.SOMETHING_GOES_WRONG = SOMETHING_GOES_WRONG;
  var CLEAR_FILTERING_PARAMS = "Erase the filter params";
  _exports.CLEAR_FILTERING_PARAMS = CLEAR_FILTERING_PARAMS;
  var ASSET_TBSO_FILE_INVALID = "Unable to upload TBSO file. Please make sure that you used the " + "latest version of Asset Editor. If not, download the latest version and resave TBSO file. If the error still exists, " + "please contact our support.";
  _exports.ASSET_TBSO_FILE_INVALID = ASSET_TBSO_FILE_INVALID;
  var ASSET_TBSO_FILE_CORRUPTED = "Your file is corrupted. Choose existing or upload another one.";
  _exports.ASSET_TBSO_FILE_CORRUPTED = ASSET_TBSO_FILE_CORRUPTED;
  var IMAGE_FORMAT_ERROR = "Wrong file format, only PNG, JPG, and JPEG are acceptable.";
  _exports.IMAGE_FORMAT_ERROR = IMAGE_FORMAT_ERROR;
  var VIDEO_FORMAT_ERROR = "Wrong file format, only MP4 is acceptable.";
  _exports.VIDEO_FORMAT_ERROR = VIDEO_FORMAT_ERROR;
  var TBSO_FILE_FORMAT_ERROR = "Wrong file format, only TBSO is acceptable.";
  _exports.TBSO_FILE_FORMAT_ERROR = TBSO_FILE_FORMAT_ERROR;
  var IMAGE_SIZE_ERROR = "File size should be less than 10 MB";
  _exports.IMAGE_SIZE_ERROR = IMAGE_SIZE_ERROR;
  var TBSO_FILE_SIZE_ERROR = "File size should be less than ".concat(_environment.default.APP.TBSO_FILE_MAX_SIZE / 1024 / 1024, " MB");
  _exports.TBSO_FILE_SIZE_ERROR = TBSO_FILE_SIZE_ERROR;
  var VIDEO_SIZE_ERROR = "File size should be less than 200 MB";
  _exports.VIDEO_SIZE_ERROR = VIDEO_SIZE_ERROR;
  var VALIDATOR_NOT_BLANK = "This field can not be blank.";
  _exports.VALIDATOR_NOT_BLANK = VALIDATOR_NOT_BLANK;
  var FORM_HAS_ERRORS = "Not all required fields are correctly filled.";
  _exports.FORM_HAS_ERRORS = FORM_HAS_ERRORS;
  var SMART_OBJECT_CREATED = "New Product Successfully Created";
  _exports.SMART_OBJECT_CREATED = SMART_OBJECT_CREATED;
  var PASSWORD_VALIDATOR_DIGIT = "Password must include at least one digit";
  _exports.PASSWORD_VALIDATOR_DIGIT = PASSWORD_VALIDATOR_DIGIT;
  var PASSWORD_VALIDATOR_LOWER_CASE = "Password must include at least one lower-case character";
  _exports.PASSWORD_VALIDATOR_LOWER_CASE = PASSWORD_VALIDATOR_LOWER_CASE;
  var PASSWORD_VALIDATOR_UPPER_CASE = "Password must contain at least one upper case character";
  _exports.PASSWORD_VALIDATOR_UPPER_CASE = PASSWORD_VALIDATOR_UPPER_CASE;
  var PASSWORD_VALIDATOR_SPECIAL_CHAR = "Password must contain at least one special character like as !@#$%^&";
  _exports.PASSWORD_VALIDATOR_SPECIAL_CHAR = PASSWORD_VALIDATOR_SPECIAL_CHAR;
  var PASSWORD_CONFIRMATION = "Doesn't match Password";
  _exports.PASSWORD_CONFIRMATION = PASSWORD_CONFIRMATION;
  var PASSWORD_SUCCESSFULLY_RESET = "Your password successfully reset";
  _exports.PASSWORD_SUCCESSFULLY_RESET = PASSWORD_SUCCESSFULLY_RESET;
  var PASSWORD_SUCCESSFULLY_CHANGED = "Your password has been changed";
  _exports.PASSWORD_SUCCESSFULLY_CHANGED = PASSWORD_SUCCESSFULLY_CHANGED;
  var PASSWORD_CHANGE_VALIDATOR_LENGTH = "Password must be at least 8 characters";
  _exports.PASSWORD_CHANGE_VALIDATOR_LENGTH = PASSWORD_CHANGE_VALIDATOR_LENGTH;
  var PASSWORD_CHANGE_CONFIRMATION = "Passwords do not match";
  _exports.PASSWORD_CHANGE_CONFIRMATION = PASSWORD_CHANGE_CONFIRMATION;
  var PASSWORD_CHANGE_FORM_HAS_ERRORS = "Please review error(s)";
  _exports.PASSWORD_CHANGE_FORM_HAS_ERRORS = PASSWORD_CHANGE_FORM_HAS_ERRORS;
  var PASSWORD_CHANGE_PASSWORD_NOT_VALID = "Your password is not valid. Please re-enter your password.";
  _exports.PASSWORD_CHANGE_PASSWORD_NOT_VALID = PASSWORD_CHANGE_PASSWORD_NOT_VALID;
  var REQUIRED = "Required";
  _exports.REQUIRED = REQUIRED;
  var CAMPAIGN_DEACTIVATED = "The Campaign has been deactivated";
  _exports.CAMPAIGN_DEACTIVATED = CAMPAIGN_DEACTIVATED;
  var VALIDATION_ERROR = "Validation error has been occured";
  _exports.VALIDATION_ERROR = VALIDATION_ERROR;
  var END_DATE_CAMPAIGN_ERROR = "End date must be no more than 75 days from today";
  _exports.END_DATE_CAMPAIGN_ERROR = END_DATE_CAMPAIGN_ERROR;
  var NO_MATCHED_CATEGORY_MESSAGE = "Press enter to add a new category";
  _exports.NO_MATCHED_CATEGORY_MESSAGE = NO_MATCHED_CATEGORY_MESSAGE;
  var CATEGORY_REQUEST_SENT = "Category request has been sent";
  _exports.CATEGORY_REQUEST_SENT = CATEGORY_REQUEST_SENT;
  var CATEGORY_ALREADY_EXIST = "This category already exists";
  _exports.CATEGORY_ALREADY_EXIST = CATEGORY_ALREADY_EXIST;
  var CHANGES_SUCCESSFULLY_SAVED = "Changes successfully saved";
  _exports.CHANGES_SUCCESSFULLY_SAVED = CHANGES_SUCCESSFULLY_SAVED;
  var VERIFICATION_CODE_SENT = "Verification code has been sent. It is valid for 10 minutes";
  _exports.VERIFICATION_CODE_SENT = VERIFICATION_CODE_SENT;
  var VERIFICATION_CODE_INFO = "Verification code has been sent. You can resend code after the timer expires";
  _exports.VERIFICATION_CODE_INFO = VERIFICATION_CODE_INFO;
  var VERIFICATION_STEP_MESSAGE = "Verification code will be sent on the mobile phone you specified. It will be valid for 10 minutes.";
  _exports.VERIFICATION_STEP_MESSAGE = VERIFICATION_STEP_MESSAGE;
  var ERROR_500 = "Oops! 500 Internal Server Error";
  _exports.ERROR_500 = ERROR_500;
  var ZIP_CODE_ERROR = "ZIP code should be at least 1 and can be up to 10 characters. It may contain numbers and letters.";
  _exports.ZIP_CODE_ERROR = ZIP_CODE_ERROR;
  var PHONE_CHANGED = "You changed your phone number, resend code again";
  _exports.PHONE_CHANGED = PHONE_CHANGED;
  var NOT_DELETE_TBSO = "You can not delete a Product used in active campaign";
  _exports.NOT_DELETE_TBSO = NOT_DELETE_TBSO;
  var SMART_OBJECT_TITLE_EXISTS = "A Product already exists with the title";
  _exports.SMART_OBJECT_TITLE_EXISTS = SMART_OBJECT_TITLE_EXISTS;
  var ASSET_3D_MODEL_REMOVED = "The 3D model has been removed";
  _exports.ASSET_3D_MODEL_REMOVED = ASSET_3D_MODEL_REMOVED;
  var NEED_ALLOW_SMS = "You need to give permission to send messages";
  _exports.NEED_ALLOW_SMS = NEED_ALLOW_SMS;
  var SELECT_PRIMARY_PRODUCT = "Select primary product";
  _exports.SELECT_PRIMARY_PRODUCT = SELECT_PRIMARY_PRODUCT;
  var YOU_CAN_NOT_REMOVE_LAST_PRODUCT = "You can not remove last product";
  _exports.YOU_CAN_NOT_REMOVE_LAST_PRODUCT = YOU_CAN_NOT_REMOVE_LAST_PRODUCT;
  var YOU_CAN_NOT_REMOVE_PRIMARY_PRODUCT = "You can not remove primary product";
  _exports.YOU_CAN_NOT_REMOVE_PRIMARY_PRODUCT = YOU_CAN_NOT_REMOVE_PRIMARY_PRODUCT;
  var SHOULD_CONTAIN_A_VALID_PROTO = "The URL address should contain a valid proto";
  _exports.SHOULD_CONTAIN_A_VALID_PROTO = SHOULD_CONTAIN_A_VALID_PROTO;
  var COPIED_TO_THE_CLIPBOARD = "Copied to the Clipboard";
  _exports.COPIED_TO_THE_CLIPBOARD = COPIED_TO_THE_CLIPBOARD;
  var COUPON_CREATED = "Coupon created";
  _exports.COUPON_CREATED = COUPON_CREATED;
  var INVALID_URL_ERROR = "The field must be a valid URL.";
  _exports.INVALID_URL_ERROR = INVALID_URL_ERROR;
});