define("trivver-frontend/pods/new-category/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/field-validation"], function (_exports, _model, _emberCpValidations, _fieldValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    product_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.product_nameFlag')
    })],
    product_category: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.product_category', 'model.product_subcategory1', 'model.product_categoryFlag', function () {
        return !(this.get('model.product_category') || this.get('model.product_subcategory1') || this.get('model.product_categoryFlag'));
      })
    })],
    product_subcategory1: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.product_subcategory1', 'model.product_subcategory2', function () {
        return !(this.get('model.product_subcategory1') || this.get('model.product_subcategory2'));
      })
    })],
    product_subcategory2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.product_subcategory2')
    })]
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    product_name: (0, _model.attr)(),
    product_category: (0, _model.attr)(),
    product_subcategory1: (0, _model.attr)(),
    product_subcategory2: (0, _model.attr)(),
    categories: Ember.computed(function () {
      return {
        0: 'product_category',
        1: 'product_subcategory1',
        2: 'product_subcategory2'
      };
    }),
    clearCategories: function clearCategories() {
      var _this = this;

      Object.values(this.categories).forEach(function (i) {
        return _this.set(i, null);
      });
    }
  });

  _exports.default = _default;
});