define("trivver-frontend/validators/us-state", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/messages"], function (_exports, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var USStateCheck = _base.default.extend({
    validate: function validate(value) {
      if (value.get('id') === 'NO') {
        return _messages.REQUIRED;
      }

      return true;
    }
  });

  var _default = USStateCheck;
  _exports.default = _default;
});