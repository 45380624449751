define("trivver-frontend/pods/components/models-table/smart-object-details/link-to-campaign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZBuK7tAG",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"record\",\"title\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/smart-object-details/link-to-campaign/template.hbs"
    }
  });

  _exports.default = _default;
});