define("trivver-frontend/pods/components/models-table/advertiser-coupons/remove-coupon-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ['title', 'type'],
    classNames: ['btn', 'btn-short', 'btn-link', 'btn-red'],
    type: 'button',
    click: function click() {
      this.sendAction('removeProductCoupon', this.record);
    }
  });

  _exports.default = _default;
});