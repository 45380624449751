define("trivver-frontend/pods/components/modals/site-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backdropClose: true,
    actions: {
      close: function close() {
        this.set('open', false);

        if (this.closeAction) {
          this.sendAction('closeAction');
        }
      }
    }
  });

  _exports.default = _default;
});