define("trivver-frontend/pods/components/number-format/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mDGf3aiU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isNumber\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[14,1,[[24,[\"value\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1,[0]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1,[[24,[\"value\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/number-format/template.hbs"
    }
  });

  _exports.default = _default;
});