define("trivver-frontend/pods/utils/plural-game-word/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Return category's title with plural word for `game`
   *
   * @param count - count games, define pluralize
   * @param title - title of category
   */
  function getCategoryTitle(title, count) {
    var word = count === 1 ? 'game' : 'games';
    return String(Ember.String.htmlSafe("<strong>".concat(title, "</strong> <sup>(").concat(count, " ").concat(word, "*)</sup>")));
  }

  var _default = getCategoryTitle;
  _exports.default = _default;
});