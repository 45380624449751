define("trivver-frontend/pods/utils/crop-file-to-square/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Get coordinates for cropping square area
   *
   * @param width - width of input image
   * @param height - height of input image
   */
  function _getCoordsForCrop(width, height) {
    var sx = 0;
    var sy = 0;
    var a = 0;

    if (width > height) {
      sx = (width - height) / 2;
      a = height;
    } else {
      sy = (height - width) / 2;
      a = width;
    }

    return [sx, sy, a];
  } // Polyfill from https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#Polyfill


  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
      value: function value(callback, type, quality) {
        var dataURL = this.toDataURL(type, quality).split(",")[1];
        setTimeout(function () {
          var binStr = atob(dataURL);
          var len = binStr.length;
          var arr = new Uint8Array(len);

          for (var i = 0; i < len; i += 1) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], {
            type: type || "image/png"
          }));
        });
      }
    });
  }
  /**
   * Function for cropping file image to square
   *
   * @param {Blob} file - image file
   * @param {function} success - callback function which receive cropped file
   * @param {Number} size - result size of square in pixels
   */


  function cropFileToSquare(file, success) {
    var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 320;
    var fileName = file.name;
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      var img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        var elem = document.createElement("canvas");
        elem.width = size;
        elem.height = size;
        var ctx = elem.getContext("2d");

        var _getCoordsForCrop2 = _getCoordsForCrop(img.width, img.height),
            _getCoordsForCrop3 = _slicedToArray(_getCoordsForCrop2, 3),
            sx = _getCoordsForCrop3[0],
            sy = _getCoordsForCrop3[1],
            a = _getCoordsForCrop3[2];

        ctx.drawImage(img, sx, sy, a, a, 0, 0, size, size);
        ctx.canvas.toBlob(function (blob) {
          var newFile = new File([blob], fileName, {
            type: file.type,
            lastModified: Date.now()
          });
          success(newFile);
        }, file.type, 1);
      };
    };
  }

  var _default = cropFileToSquare;
  _exports.default = _default;
});