define("trivver-frontend/pods/components/modals/product-3d-model-form/instructions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6sj36bxI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"Creating a Trivver Branded Smart Object (TBSO) file is best done\\n  by a 3D designer. It requires access to an existing 3D obj file\\n  and material files for your product, and the ability to make 3D\\n  object adjustments.\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"If you do not have access to a 3D designer, you may hire Trivver\\n  to convert your 3D file to a TBSO file:\\n  \"],[7,\"a\",true],[10,\"href\",\"mailto:support@trivver.com\"],[8],[0,\"Contact Trivver\"],[9],[0,\".\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"data-group\"],[8],[0,\"\\n  \"],[7,\"strong\",true],[10,\"class\",\"data-group__label\"],[8],[0,\"Instructions:\"],[9],[0,\"\\n\\n  \"],[7,\"ol\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Download the \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"showAssetEditorModal\"]]],null],true]],[8],[7,\"strong\",true],[8],[0,\"Trivver Asset Editor\"],[9],[9],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Using the Asset Editor, upload your obj and material files (how to convert to .obj format from other formats)\"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Adjust the appearance of your 3D Object as needed (details)\"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Save your 3D object as a SmartObject (TBSO) file\"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"Upload the newly created 3D TBSO File\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"modals/modal-asset-editor\",null,[[\"open\"],[[23,0,[\"showAssetEditorModal\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/product-3d-model-form/instructions/template.hbs"
    }
  });

  _exports.default = _default;
});