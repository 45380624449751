define("trivver-frontend/pods/components/tbso-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    READY_ATTR_NAME: "ready",
    id: "tbso-preview__3d-container-id",
    loaded: false,
    selected: null,
    type: "2d",
    backgroundColor: "#FFFFFF",
    classNames: ["tbso-preview"],
    attributeBindings: ["style", "type"],
    config: Ember.inject.service(),
    buttonsEnabled: Ember.computed("file2d", "file3d", "use3dOnly", function () {
      return this.get("config.environment") === "production" ? false : Boolean(this.file2d || this.file3d);
    }),
    isAR: Ember.computed("campaignType", function () {
      if (this.campaignType === "P") return false;
      return true;
    }),
    observer: Ember.observer("file3d", "backgroundColor", function () {
      if (!this.file3d) {
        this.set("type", null);
        return;
      }

      if (this.type === "3d" || this.type === "2d" && !this.file2d) {
        this.show3d();
      }
    }),
    actions: {
      show: function show(type) {
        switch (type) {
          case "3d":
            this.show3d();
            break;

          case "video":
            this.showVideo();
            break;

          default:
            this.show2d();
        }
      }
    },
    show2d: function show2d() {
      this.set("type", "2d");
    },
    showVideo: function showVideo() {
      this.set("type", "video");
    },
    show3d: function show3d() {
      // open layout
      this.set("type", "3d");
    },
    didInsertElement: function didInsertElement() {
      this._super();

      if (this.file2d && this.type === "2d") {
        this.show2d();
      } else {
        this.show3d();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super();
    }
  });

  _exports.default = _default;
});