define("trivver-frontend/pods/components/inputs/search-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oasx61t4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"search__label\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\",\"type\",\"placeholder\",\"class\"],[[23,0,[\"filterString\"]],\"search\",\"Search\",\"form-control search__text-field\"]]],false],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-search search__icon\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"filterString\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\"glyphicon glyphicon-remove-circle clearable__control\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"filterString\"]]],null],\"\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/search-input/template.hbs"
    }
  });

  _exports.default = _default;
});