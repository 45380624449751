define("trivver-frontend/pods/components/models-table/advertiser-products/product-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['background-cover-image'],
    attributeBindings: ['style'],
    style: Ember.computed('record.file_image', function () {
      return "background-image:url(".concat(this.get('record.fileIconPreview'), ")");
    })
  });

  _exports.default = _default;
});