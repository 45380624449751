define("trivver-frontend/pods/components/models-table/payment-history/cells/campaign-ending/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HNjR4+dZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"record\",\"campaign_end\"]]],null,{\"statements\":[[4,\"if\",[[28,\"is-after\",[[23,0,[\"record\",\"campaign_end\"]]],null]],null,{\"statements\":[[0,\"    Completed\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"moment-format\",[[23,0,[\"record\",\"campaign_end\"]],\"MM/DD/YYYY\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/payment-history/cells/campaign-ending/template.hbs"
    }
  });

  _exports.default = _default;
});