define("trivver-frontend/constants/campaign-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TMOD = _exports.PRODUCT_PORTAL = _exports.PP_TMOD = _exports.GAME = void 0;
  var GAME = 'G';
  _exports.GAME = GAME;
  var PRODUCT_PORTAL = 'P';
  _exports.PRODUCT_PORTAL = PRODUCT_PORTAL;
  var TMOD = "T";
  _exports.TMOD = TMOD;
  var PP_TMOD = 'T,P';
  _exports.PP_TMOD = PP_TMOD;
});