define("trivver-frontend/pods/earnings-monthly/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE, "/ecommerce"),
    // eslint-disable-next-line no-unused-vars
    urlForQuery: function urlForQuery(query) {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/earnings/monthly/");
    }
  });

  _exports.default = _default;
});