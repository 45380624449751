define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/update/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    queryParams: ['finishPage', 'campaign_type'],
    allowRedirect: Ember.computed('router.router.currentURL', {
      get: function get() {
        return this.get('router.router.currentURL').indexOf('next') === -1;
      }
    })
  });

  _exports.default = _default;
});