define("trivver-frontend/authenticators/custom-token", ["exports", "ember-simple-auth-token/authenticators/token"], function (_exports, _token) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _token.default.extend({
    getAuthenticateData: function getAuthenticateData(credentials) {
      var _authentication;

      var authentication = (_authentication = {}, _defineProperty(_authentication, this.passwordField, credentials.password), _defineProperty(_authentication, this.identificationField, credentials.identification), _defineProperty(_authentication, "auth_code", credentials.code), _defineProperty(_authentication, "reset_code", credentials.resetCode), _authentication);
      return authentication;
    }
  });

  _exports.default = _default;
});