define("trivver-frontend/pods/home/cabinet/advertiser/game/details/route", ["exports", "trivver-frontend/mixins/routes/advertiser-only"], function (_exports, _advertiserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _advertiserOnly.default.extend({});

  _exports.default = _default;
});