define("trivver-frontend/pods/home/cabinet/developer/games/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vg5BPjla",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"page-title\"],[8],[0,\"New Game\"],[9],[0,\"\\n\\n\"],[5,\"game-form\",[],[[\"@model\",\"@save\",\"@freezeState\",\"@isCreateForm\",\"@buttonText\",\"@treeData\"],[[23,0,[\"model\"]],[28,\"action\",[[23,0,[]],\"saveGame\"],null],[23,0,[\"freezeState\"]],true,\"Save Game\",[23,0,[\"model\",\"treeData\"]]]],{\"statements\":[[0,\"\\n\\n  \"],[1,[28,\"image-grid\",null,[[\"images\",\"addImage\",\"removeImage\",\"changeImage\"],[[23,0,[\"model\",\"screenshots\"]],[28,\"action\",[[23,0,[]],\"addImage\"],null],[28,\"action\",[[23,0,[]],\"removeImage\"],null],[28,\"action\",[[23,0,[]],\"changeImage\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/cabinet/developer/games/new/template.hbs"
    }
  });

  _exports.default = _default;
});