define("trivver-frontend/pods/account-file/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/field-validation"], function (_exports, _model, _emberCpValidations, _fieldValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    upload: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isVerificationDocumentNeeded')
    })
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    upload: (0, _model.attr)('file'),
    isVerificationDocumentNeeded: Ember.computed(function () {
      var fieldsNeeded = this.store.peekRecord('fields-needed', 1);
      return fieldsNeeded.get('verification_document');
    })
  });

  _exports.default = _default;
});