define("trivver-frontend/pods/components/blizzard-tos/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e5FAmm8X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"p\",true],[8],[0,\"Please review the terms below carefully. In order to advertise using \\n  Trivver, you must agree to all terms.\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"data-group\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"data-group__label\"],[8],[0,\"\\n    You can click on the \\\"Accept\\\" if you read the document to the end.\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"data-group__data-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"data-group__content fixed-height-content\"],[11,\"onscroll\",[28,\"action\",[[23,0,[]],\"scroll\"],null]],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/blizzard-tos/template.hbs"
    }
  });

  _exports.default = _default;
});