define("trivver-frontend/pods/components/sortable-table-radio/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Table component
   *
   * Allows to render dynamic tables with sorting
   *
   * Header titles of table should passed as ``columns`` arg to the component.
   *
   * columns: {
   *   title: {
   *     title: 'Game Title',
   *     sorting: 'title',
   *     linkable: {
   *       route: 'home.cabinet.developer.games.game',
   *       paramField: 'game_uid',
   *     },
   *   },
   *   genres: {
   *     title: 'Genre',
   *   },
   *   amount: {
   *     title: 'Ad Revenue Earned',
   *     prefix: '$',
   *     type: 'number',
   *     sorting: 'amount'
   *   },
   *   platforms_data: {
   *     title: 'Platform',
   *     collection: true,
   *     field: 'title',
   *   },
   *   ages_data: {
   *     title: 'Ages',
   *     collection: true,
   *     field: 'value',
   *   },
   * },
   *
   * Example of using of this component
   *
   * {{sortable-table data=model.games.results columns=columns sorting=sorting}}
   */
  var _default = Ember.Component.extend({
    sortingStatus: null,
    init: function init() {
      this._super();

      this.set('sortingStatus', {});
    },
    willInsertElement: function willInsertElement() {
      var columns = this.columns;

      for (var element in columns) {
        if (columns[element].sorting) {
          this.set("sortingStatus.".concat(element), '');
        }
      }
    },
    actions: {
      toggleSorting: function toggleSorting(prop) {
        // If ``prop`` property doesn't exist in ``sorting`` object, return false
        if (!(prop in this.sortingStatus)) {
          return false;
        } // Reset another properties sorting state


        for (var element in this.sortingStatus) {
          if (element !== prop) {
            this.set("sortingStatus.".concat(element), '');
          }
        } // Set asc if starting state, desc if asc and starting state if desc


        if (this.get("sortingStatus.".concat(prop)) === '') {
          this.set("sortingStatus.".concat(prop), 'asc');
        } else if (this.get("sortingStatus.".concat(prop)) === 'asc') {
          this.set("sortingStatus.".concat(prop), 'desc');
        } else {
          this.set("sortingStatus.".concat(prop), '');
        } // Sending action


        this.sendAction('action', this.get("columns.".concat(prop, ".sorting")), this.get("sortingStatus.".concat(prop)));
        return true;
      },
      chooseRadio: function chooseRadio(item) {
        // need array of assets ids
        this.value.clear();
        this.value.pushObject(item);
      }
    }
  });

  _exports.default = _default;
});