define("trivver-frontend/pods/confirmation-email-link/serializer", ["exports", "trivver-frontend/pods/drf/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    extractId: function extractId() {
      return 1;
    }
  });

  _exports.default = _default;
});