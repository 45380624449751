define("trivver-frontend/pods/home/cabinet/advertiser/campaigns/update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mAdT3QKN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"campaign-wizard\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-title\"],[11,\"title\",[29,[[23,0,[\"model\",\"campaign\",\"title\"]]]]],[8],[0,\"\\n    \"],[1,[28,\"truncate\",[[23,0,[\"model\",\"campaign\",\"title\"]],40],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[28,\"eq\",[[23,0,[\"currentStep\"]],\"review\"],null],[28,\"not\",[[23,0,[\"model\",\"campaign\",\"isEnded\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"buttons pull-right\"],[8],[0,\"\\n      \"],[1,[28,\"campaign-cancel-button\",null,[[\"action\",\"campaign\"],[\"cancelCampaign\",[23,0,[\"model\",\"campaign\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"progress-steps\",null,[[\"steps\",\"current\",\"prevSteps\",\"clickable\",\"goto\"],[[23,0,[\"steps\"]],[23,0,[\"currentStep\"]],[23,0,[\"prevSteps\"]],[23,0,[\"allowRedirect\"]],\"goto\"]]],false],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/cabinet/advertiser/campaigns/update/template.hbs"
    }
  });

  _exports.default = _default;
});