define("trivver-frontend/pods/components/models-table/numeric-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isActiveFirstPage: Ember.computed('pages.@each.active', function () {
      var pages = this.pages;
      if (!pages.length) return false;
      return pages.get('firstObject').isActive;
    }),
    isActiveLastPage: Ember.computed('pages.@each.active', function () {
      var pages = this.pages;
      if (!pages.length) return false;
      return pages.get('lastObject').isActive;
    }),
    pages: Ember.computed.reads('visiblePageNumbers'),
    actions: {
      next: function next() {
        var current = this.pages.filter(function (i) {
          return i.isActive;
        }).get('firstObject.label');
        this.gotoCustomPage(current + 1);
      },
      previous: function previous() {
        var current = this.pages.filter(function (i) {
          return i.isActive;
        }).get('firstObject.label');
        this.gotoCustomPage(current - 1);
      }
    }
  });

  _exports.default = _default;
});