define("trivver-frontend/pods/account/model", ["exports", "@ember-data/model", "ember-cp-validations", "moment", "ember-local-storage", "trivver-frontend/config/environment", "trivver-frontend/constants/ages", "trivver-frontend/constants/entity-type", "trivver-frontend/constants/verification-status", "trivver-frontend/mixins/field-validation"], function (_exports, _model, _emberCpValidations, _moment, _emberLocalStorage, _environment, _ages, _entityType, _verificationStatus, _fieldValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function secureProperty(property, fieldProvided, count) {
    return Ember.computed(property, fieldProvided, {
      // eslint-disable-next-line no-unused-vars
      get: function get(key) {
        if (this.get(fieldProvided)) {
          return '*'.repeat(count || 4);
        }

        return this.get(property);
      },
      set: function set(key, value) {
        return this.set(property, value);
      }
    });
  }

  var Validations = (0, _emberCpValidations.buildValidations)({
    account_type: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.account_type')
    }),
    first_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.first_name')
    }),
    last_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.last_name')
    }),
    city: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.city')
    }),
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.state')
    }),
    zip: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        regex: /[\w0-9]+/,
        message: 'Should be alphanumeric'
      }), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 6
      })],
      disabled: Ember.computed.not('model.fields_needed.zip')
    },
    address_line1: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.address_line1')
    }),
    personal_id_number: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        is: 9
      })],
      disabled: Ember.computed.not('model.fields_needed.personal_id_number')
    },
    day_of_birth: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('date'), (0, _emberCpValidations.validator)('age', {
        min: _ages.MIN_AGE
      }), (0, _emberCpValidations.validator)('dependent', {
        on: ['day_of_birthFlag'],
        message: 'This field can\'t be blank'
      })],
      disabled: Ember.computed.not('model.fields_needed.day_of_birth')
    },
    day_of_birthFlag: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    business_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.isIndividualEntityType', 'model.fields_needed.isBusinessNameNotNeeded')
    }),
    business_tax_id: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: {
          presence: true
        }
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        is: 9
      })],
      disabled: Ember.computed.or('model.isIndividualEntityType', 'model.fields_needed.isBusinessTaxIDNotNeeded')
    },
    ssn_last_4: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: {
          presence: true
        }
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        is: 4
      })],
      disabled: Ember.computed.or('model.isIndividualEntityType', 'model.fields_needed.isSSNLast4NotNeeded')
    },
    tos_accepted: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.fields_needed.tos_accepted')
    })
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    init: function init() {
      // eslint-disable-next-line
      this._super.apply(this, arguments);

      if (!this.day_of_birth) {
        this.set('day_of_birth', (0, _moment.default)().format());
      }
    },
    seen: (0, _emberLocalStorage.storageFor)('seen'),
    verification_status: (0, _model.attr)('string'),
    // Fields needed for filling to allow to do payouts
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zip: (0, _model.attr)('string'),
    address_line1: (0, _model.attr)('string'),
    address_line2: (0, _model.attr)('string'),
    ssn_last_4: (0, _model.attr)('string'),
    personal_id_number: (0, _model.attr)('string'),
    business_name: (0, _model.attr)('string'),
    business_tax_id: (0, _model.attr)('string'),
    day_of_birth: (0, _model.attr)('date-only'),
    tos_accepted: (0, _model.attr)('boolean'),
    account_type: (0, _model.attr)('string'),
    fields_needed: (0, _model.attr)(),
    fields_provided: (0, _model.attr)(),
    isBusinessEntityType: Ember.computed('account_type', function () {
      return this.account_type === _entityType.COMPANY;
    }),
    isIndividualEntityType: Ember.computed.not('isBusinessEntityType'),
    isVerified: Ember.computed('verification_status', function () {
      // I do not know how verified should be presented here
      return this.verification_status === _verificationStatus.VERIFIED;
    }),
    isPending: Ember.computed('verification_status', function () {
      return this.verification_status === _verificationStatus.PENDING;
    }),
    isUnverified: Ember.computed('verification_status', function () {
      return this.verification_status === _verificationStatus.UNVERIFIED;
    }),
    isNotVerified: Ember.computed.not('isVerified'),
    dateOfBirth: Ember.computed('day_of_birth', {
      // eslint-disable-next-line no-unused-vars
      get: function get(key) {
        return (0, _moment.default)(this.day_of_birth).format(_environment.default.dateFormat);
      },
      // eslint-disable-next-line no-unused-vars
      set: function set(key, value) {
        return (0, _moment.default)(this.day_of_birth).format(_environment.default.dateFormat);
      }
    }),
    dateOfBirthAsDate: Ember.computed('day_of_birth', {
      get: function get() {
        return new Date(this.day_of_birth);
      }
    }),
    // Secure properties
    ssnLast4: secureProperty('ssn_last_4', 'fields_provided.ssn_last_4_provided'),
    personalIDNumber: secureProperty('personal_id_number', 'fields_provided.personal_id_number_provided', 9),
    businessTaxID: secureProperty('business_tax_id', 'fields_provided.business_tax_id_provided'),
    // Should fields be shown properties
    isFirstNameShouldBeShown: Ember.computed.or('first_name', 'fields_needed.first_name'),
    isLastNameShouldBeShown: Ember.computed.or('last_name', 'fields_needed.last_name'),
    isCityShouldBeShown: Ember.computed.or('city', 'fields_needed.city'),
    isStateShouldBeShown: Ember.computed.or('state', 'fields_needed.state'),
    isZipShouldBeShown: Ember.computed.or('zip', 'fields_needed.zip'),
    isAddressLine1ShouldBeShown: Ember.computed.or('address_line1', 'fields_needed.address_line1'),
    isAddressLine2ShouldBeShown: Ember.computed.or('address_line1', 'fields_needed.address_line2'),
    // isSSNLast4ShouldBeShown: and(
    //   not('fields_provided.personal_id_number_provided'),
    //   or(
    //     'ssn_last_4',
    //     'fields_needed.ssn_last_4',
    //     'fields_provided.ssn_last_4_provided',
    //     'fields_provided.personal_id_number_provided',
    //   ),
    // ),
    isSSNLast4ShouldBeShown: Ember.computed(function () {
      return !this.get('fields_provided.personal_id_number_provided') && (this.ssn_last_4 || this.get('fields_needed.ssn_last_4') || this.get('fields_provided.ssn_last_4_provided') || this.get('fields_provided.personal_id_number_provided'));
    }),
    isPersonalIDNumberShouldBeShown: Ember.computed.or('personal_id_number', 'fields_needed.personal_id_number', 'fields_provided.personal_id_number_provided'),
    isBusinessNameShouldBeShown: Ember.computed.or('business_name', 'fields_needed.business_name'),
    isBusinessTaxIDShouldBeShown: Ember.computed.or('business_tax_id', 'fields_needed.business_tax_id', 'fields_provided.business_tax_id_provided'),
    isDayOfBirthShouldBeShown: Ember.computed.or('day_of_birth', 'fields_needed.day_of_birth'),
    isAccountTypeShouldBeShown: Ember.computed.or('account_type', 'fields_needed.account_type'),
    isVerificationDocumentShouldBeShown: Ember.computed.or('verification_document', 'fields_needed.verification_document'),
    isTOSAcceptedShouldBeShown: Ember.computed.alias('fields_needed.fields_needed'),
    isDayOfBirthValueShouldBeShown: Ember.computed.or('dayOfBirthNotNeeded', 'day_of_birthFlag'),
    dayOfBirthNotNeeded: Ember.computed.not('fields_needed.day_of_birth'),
    isVerificationNotificationShouldBeShown: Ember.computed.and('isNotVerified', 'fields_needed.isNoFieldsNeeded'),
    // Disabled fields
    isFirstNameDisabled: Ember.computed.and('first_name', 'fields_needed.isFirstNameNotNeeded'),
    isLastNameDisabled: Ember.computed.and('last_name', 'fields_needed.isLastNameNotNeeded'),
    isDayOfBirthDisabled: Ember.computed.and('day_of_birth', 'fields_needed.isDayOfBirthNotNeeded'),
    isAddressLine1Disabled: Ember.computed.and('address_line1', 'fields_needed.isAddressLine1NotNeeded'),
    isAddressLine2Disabled: Ember.computed.and('address_line1', 'fields_needed.isAddressLine2NotNeeded'),
    isCityDisabled: Ember.computed.and('city', 'fields_needed.isCityNotNeeded'),
    isStateDisabled: Ember.computed.and('state', 'fields_needed.isStateNotNeeded'),
    isZipDisabled: Ember.computed.and('zip', 'fields_needed.isZipNotNeeded'),
    isAccountTypeDisabled: Ember.computed.and('account_type', 'fields_needed.isAccountTypeNotNeeded'),
    isSSNLast4Disabled: Ember.computed.and('fields_provided.ssn_last_4_provided', 'fields_needed.isSSNLast4NotNeeded'),
    isBusinessTaxIDDisabled: Ember.computed.and('fields_provided.business_tax_id_provided', 'fields_needed.isBusinessTaxIDNotNeeded'),
    isBusinessNameDisabled: Ember.computed.and('business_name', 'fields_needed.isBusinessNameNotNeeded'),
    isPersonalIDNumberDisabled: Ember.computed.and('fields_provided.personal_id_number_provided', 'fields_needed.isPersonalIDNumberNotNeeded'),
    isDayOfBirthEnabled: Ember.computed.not('isDayOfBirthDisabled'),
    isModalShouldBeShown: Ember.computed('seen.seen', 'fields_needed.fields_needed', 'fields_needed.account_type', function () {
      var fieldsNeeded = this.get('fields_needed.fields_needed');
      var accountTypeNeeded = this.get('fields_needed.account_type');
      var seen = this.get('seen.seen');
      return fieldsNeeded && !accountTypeNeeded && !seen;
    }),
    isTOSAcceptedCheckbox: Ember.computed('tos_accepted', {
      get: function get() {
        return this.tos_accepted || this.verification_status === 'verified' || !this.isTOSAcceptedShouldBeShown;
      },
      set: function set(key, value) {
        this.set('tos_accepted', value);
        return value;
      }
    }),
    // Observers
    accountTypeChanged: Ember.observer('account_type', function () {
      if (this.isIndividualEntityType) {
        this.set('business_name', '');
        this.set('business_tax_id', null);
      }
    })
  });

  _exports.default = _default;
});