define("trivver-frontend/pods/drf/adapter", ["exports", "ember-django-adapter/adapters/drf", "ember-simple-auth/mixins/data-adapter-mixin", "trivver-frontend/config/environment"], function (_exports, _drf, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_dataAdapterMixin.default, {
    cookies: Ember.inject.service(),
    authorizer: 'authorizer:token',
    addTrailingSlashes: true,
    host: Ember.computed(function () {
      return _environment.default.APP.API_V1_HOST;
    }),
    namespace: _environment.default.APP.API_NAMESPACE,
    // should be handled carefully, but sometimes it's necessary to specify
    // additional headers, for example GAME_UID or an another one
    customHeaders: {},
    headers: Ember.computed(function () {
      var cookies = this.cookies;
      var token = cookies.read('user-token');

      if (token) {
        this.customHeaders.Authorization = "token ".concat(token);
        cookies.clear('user-token');
      }

      this.customHeaders['X-CSRFToken'] = cookies.read('csrftoken');
      return this.customHeaders;
    }),
    isInvalid: function isInvalid(status) {
      return status === 400;
    },

    /*
     * It's done to provide ability to display errors in the authentic format, i.e.
     * as is. By default emberJS converts all errors to compliant with the JSON-API
     * format - http://bit.ly/2o4glXb and returns parsed DS.InvalidError object.
     * In our case, we don't intend to propagate API answer to DS layer, however it would
     * be better to implement full support of this functionality later.
     */
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      }

      if (this.isInvalid(status)) {
        return payload;
      }

      return this._super(status, headers, payload);
    }
  });

  _exports.default = _default;
});