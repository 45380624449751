define("trivver-frontend/pods/components/modals/new-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    okButtonText: Ember.computed('verificationStep', function () {
      if (this.verificationStep) {
        return 'Finish';
      }

      return 'Continue';
    }),
    actions: {
      onSubmit: function onSubmit() {
        if (!this.verificationStep) {
          this.verificationStepAction();
        } else {
          this.addCard();
        }
      },
      onHide: function onHide() {
        this.set('verificationStep', false);
        this.closeModalAction();
      },
      updateCardNumber: function updateCardNumber(value) {
        this.set('newCard.number', value);
      }
    }
  });

  _exports.default = _default;
});