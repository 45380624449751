define("trivver-frontend/pods/components/select-all-rows-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+OIbCohh",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[28,\"is-equal\",[[23,0,[\"selectedItems\",\"length\"]],[23,0,[\"data\",\"length\"]]],null],[28,\"action\",[[23,0,[]],\"toggleAllSelection\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/select-all-rows-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});