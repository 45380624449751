define("trivver-frontend/validators/allow-sms", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/messages"], function (_exports, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AllowSms = _base.default.extend({
    validate: function validate(value) {
      return value || _messages.NEED_ALLOW_SMS;
    }
  });

  var _default = AllowSms;
  _exports.default = _default;
});