define("trivver-frontend/pods/components/models-table/advertiser-coupons/product-remove-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-link', 'btn-short'],
    click: function click() {
      this.sendAction('removeProduct', this.record);
      return false;
    }
  });

  _exports.default = _default;
});