define("trivver-frontend/pods/home/cabinet/developer/help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sGOTnfnq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-title\"],[8],[0,\"Trivver Help\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"panel panel-purple\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[0,\"Select sdk\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"docs-panel\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-xs-4 text-center\"],[8],[0,\"\\n          \"],[5,\"link-to-docs\",[],[[\"@path\"],[\"unity\"]],{\"statements\":[[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/img/general/unity-logo.png\"],[10,\"class\",\"img-unity\"],[8],[9],[0,\"\\n            Unity sdk\\n          \"]],\"parameters\":[]}],[0,\", \\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"col-xs-4 text-center\"],[8],[0,\"\\n          \"],[5,\"link-to-docs\",[],[[\"@path\"],[\"ios\"]],{\"statements\":[[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/img/general/ios-icon.svg\"],[10,\"class\",\"img-ios\"],[8],[9],[0,\"\\n            iOS sdk\\n          \"]],\"parameters\":[]}],[0,\", \\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"col-xs-4 text-center\"],[8],[0,\"\\n          \"],[5,\"link-to-docs\",[],[[\"@path\"],[\"android\"]],{\"statements\":[[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/img/general/android-logo.png\"],[10,\"class\",\"img-android\"],[8],[9],[0,\"\\n            Android sdk\\n          \"]],\"parameters\":[]}],[0,\", \\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/cabinet/developer/help/template.hbs"
    }
  });

  _exports.default = _default;
});