define("trivver-frontend/pods/components/models-table/developer-games/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageSize: 10,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    filteringIgnoreCase: true,
    showPageSize: false,
    selectRowOnClick: false,
    multipleSelect: false,
    anyFilterUsed: false,
    gamesData: Ember.computed.reads('model'),
    totals: null,
    gamesColumns: Ember.computed('totals.viewability', function () {
      return [{
        title: 'Name',
        propertyName: 'title',
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/title-',
        className: 'mt-title orderable'
      }, {
        title: 'Total',
        propertyName: 'amount',
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/total-',
        className: 'mt-amount orderable'
      }, {
        propertyName: 'action_viewability_count',
        title: Ember.String.htmlSafe("T-View (ct.)<br/>(".concat(this.get('totals.getViewability'), ")")),
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/viewability-count',
        className: 'mt-t-view orderable'
      }, {
        propertyName: 'action_3d_count',
        title: Ember.String.htmlSafe("See in 3D (ct.)<br/>(".concat(this.get('totals.getAction3D'), ")")),
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/see-in-3d-count',
        className: 'mt-action-3d orderable'
      }, {
        propertyName: 'action_purchase_count',
        title: Ember.String.htmlSafe("Where to Buy (ct.)<br/>(".concat(this.get('totals.getPurchase'), ")")),
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/where-to-buy-count',
        className: 'mt-action-purchase orderable'
      }, {
        propertyName: 'action_video_count',
        title: Ember.String.htmlSafe("Video (ct.)<br/>(".concat(this.get('totals.getVideo'), ")")),
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/video-count',
        className: 'mt-action-video orderable'
      }, {
        propertyName: 'action_coupons_count',
        title: Ember.String.htmlSafe("Coupon (ct.)<br/>(".concat(this.get('totals.getCoupon'), ")")),
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/coupon-',
        className: 'mt-action-coupon orderable'
      }, {
        propertyName: 'action_smarttab_count',
        title: Ember.String.htmlSafe("Product Portal (ct.)<br/>(".concat(this.get('totals.getSmartTab'), ")")),
        filterWithSelect: true,
        component: 'models-table/developer-games/cells/smarttab-',
        className: 'mt-action-smarttab orderable'
      }];
    })
  });

  _exports.default = _default;
});