define("trivver-frontend/pods/components/models-table/advertiser-products-min/product-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2V581bw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"truncate\",[[23,0,[\"record\",\"title\"]],35],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-products-min/product-title/template.hbs"
    }
  });

  _exports.default = _default;
});