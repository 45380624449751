define("trivver-frontend/pods/components/models-table/payment-history/cells/payment-method/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'mt-payment_method dots',
    attributeBindings: ['title'],
    title: Ember.computed('record', function () {
      return this.get('record.payment_method');
    })
  });

  _exports.default = _default;
});