define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/ctr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rWA7pRCg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"title\",[29,[[23,0,[\"record\",\"ctr\"]],\"%\"]]],[8],[1,[28,\"percents\",[[23,0,[\"record\",\"ctr\"]]],null],false],[0,\"%\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/ctr/template.hbs"
    }
  });

  _exports.default = _default;
});