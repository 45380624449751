define("trivver-frontend/pods/price/adapter", ["exports", "ember-cli-form-data/mixins/form-data-adapter", "trivver-frontend/pods/drf/adapter", "trivver-frontend/config/environment"], function (_exports, _formDataAdapter, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_formDataAdapter.default, {
    pathForType: function pathForType() {
      return 'ecommerce/prices';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/ecommerce/prices/");
    }
  });

  _exports.default = _default;
});