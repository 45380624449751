define("trivver-frontend/pods/fields-provided/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ssn_last_4_provided: (0, _model.attr)('boolean'),
    business_tax_id_provided: (0, _model.attr)('boolean'),
    personal_id_number_provided: (0, _model.attr)('boolean'),
    verification_document_provided: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});