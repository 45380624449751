define("trivver-frontend/pods/components/switcher-/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Y9eza+8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"label-status \",[28,\"unless\",[[23,0,[\"active\"]],\"inactive\"],null]]]],[3,\"action\",[[23,0,[]],\"switch\"]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"active\"]]],null,{\"statements\":[[0,\"    Enabled\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Disabled\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/switcher-/template.hbs"
    }
  });

  _exports.default = _default;
});