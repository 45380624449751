define("trivver-frontend/pods/components/blizzard-tos/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    querySelector: '.data-group__content.fixed-height-content',
    actions: {
      scroll: function scroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
          this.sendAction('acquireBottom');
        }
      }
    },
    didRender: function didRender() {
      // need to activate "Accept" button if the content was
      // shown full and aren't need scrolling
      this._super.apply(this, arguments);

      var element = this.element.querySelector(this.querySelector);

      if (element.scrollHeight <= element.offsetHeight) {
        this.sendAction('acquireBottom');
      }
    }
  });

  _exports.default = _default;
});