define("trivver-frontend/pods/coupon/serializer", ["exports", "ember-django-adapter/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      Object.keys(json).forEach(function (key) {
        json[key] = json[key] ? json[key] : null;
      });
      return json;
    }
  });

  _exports.default = _default;
});