define("trivver-frontend/pods/components/models-table/advertiser-campaigns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6OVFtDZ4",
    "block": "{\"symbols\":[\"mt\",\"ft\",\"pn\"],\"statements\":[[5,\"models-table\",[],[[\"@data\",\"@columns\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@filteringIgnoreCase\",\"@pageSize\",\"@showPageSize\",\"@filterString\",\"@selectRowOnClick\",\"@multipleColumnsSorting\",\"@groupedHeaders\"],[[23,0,[\"campaignsData\"]],[23,0,[\"columns\"]],[23,0,[\"useFilteringByColumns\"]],[23,0,[\"showGlobalFilter\"]],[23,0,[\"filteringIgnoreCase\"]],[23,0,[\"pageSize\"]],[23,0,[\"showPageSize\"]],[23,0,[\"filterString\"]],[23,0,[\"selectRowOnClick\"]],false,[23,0,[\"groupedHeaders\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"table\"]],\"expected `mt.table` to be a contextual component but found a string. Did you mean `(component mt.table)`? ('trivver-frontend/pods/components/models-table/advertiser-campaigns/template.hbs' @ L3:C6) \"],null]],[[\"class\"],[\"advertiser-campaigns\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gt\",[[23,0,[\"campaignsData\",\"length\"]],[23,0,[\"pageSize\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"summary\"]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-1 col-sm-1 col-xs-1\"],[8],[9],[0,\"\\n\\n      \"],[6,[23,2,[\"pagination-numeric\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"models-table/numeric-pagination\",null,[[\"class\",\"visiblePageNumbers\",\"gotoCustomPage\"],[\"pull-right\",[23,3,[\"visiblePageNumbers\"]],[23,3,[\"gotoCustomPage\"]]]]],false],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-campaigns/template.hbs"
    }
  });

  _exports.default = _default;
});