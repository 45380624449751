define("trivver-frontend/transforms/url-with-proto", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      var url = String(deserialized);

      if (url.includes('https://') || url.includes('http://')) {
        return url;
      }

      return "http://".concat(url);
    }
  });

  _exports.default = _default;
});