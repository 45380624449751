define("trivver-frontend/pods/components/jstree-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function collectParents(item, from, to) {
    if (item.parent !== '#') {
      var parent = from[item.parent];

      if (!to.includes(parent)) {
        to.push(parent);
      }

      collectParents(parent, from, to);
    }
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    showOnlySelected: false,
    plugins: [],
    selected: [],
    modelName: null,
    leavesOnly: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var to = false;
      var parents = null;
      var id = this.id;
      var treeData = [];
      var modelName = this.modelName;
      var categories = this.data;

      if (this.leavesOnly) {
        parents = _toConsumableArray(new Set(categories.map(function (a) {
          return String(a.parent);
        })));
      } // if we need to show only selected branches


      if (this.showOnlySelected) {
        var invertedTreeData = {}; // create map of items with keys from their IDs

        categories.forEach(function (element) {
          var item = element;
          invertedTreeData[item.id] = item;
        }); // get object by selected ID

        this.selected.forEach(function (element) {
          var item = invertedTreeData[element];
          item.state = {
            selected: true
          }; // collect all parents of item

          collectParents(item, invertedTreeData, treeData);
          treeData.push(item);
        });
      } else {
        // update statuses for selected categories
        categories.forEach(function (element) {
          var item = element;
          item.state = {
            selected: _this.selected && _this.selected.includes(element.id),
            checkbox_disabled: _this.leavesOnly && parents.includes(item.id)
          };
          treeData.push(item);
        });
      } // handle search


      Ember.$("#".concat(id, "-jstree-search")).keyup(function () {
        if (to) {
          clearTimeout(to);
        }

        to = setTimeout(function () {
          Ember.$("#".concat(id, "-jstree")).jstree(true).search(Ember.$("#".concat(id, "-jstree-search")).val());
        }, 250);
      }); // build tree and handle change events

      Ember.$("#".concat(id, "-jstree")).jstree({
        plugins: this.plugins,
        checkbox: {
          three_state: false,
          keep_selected_style: false
        },
        search: {
          show_only_matches: false
        },
        core: {
          themes: {
            icons: false,
            dots: false
          },
          data: treeData,
          loaded_state: true
        }
      }).on('changed.jstree', function (e, data) {
        // if not changed -- like ``read-only`` mode or not model name
        if (!_this.plugins.includes('changed') || !modelName) {
          return;
        } // pass if node is disabled


        if (Ember.get(data, 'node.state.checkbox_disabled')) {
          return;
        }

        var objects = []; // get stored records

        for (var i = 0; i < data.selected.length; i += 1) {
          var nodeId = data.selected[i];
          var inParents = parents && parents.includes(nodeId); // exclude parent nodes in leaves-only mode

          if (_this.leavesOnly && !inParents || !_this.leavesOnly) {
            objects.push(_this.store.peekRecord('category', data.instance.get_node(nodeId).id));
          }
        } // set collected list of objects to model


        _this.set("model.".concat(modelName, ".categories"), objects);
      });
    }
  });

  _exports.default = _default;
});