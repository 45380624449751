define("trivver-frontend/pods/components/interactive/stripe-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mIY09Hap",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb10\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"name\",\"checked\"],[\"checkbox\",\"accepted\",[23,0,[\"accepted\"]]]]],false],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"I Accept\\n      \"],[7,\"a\",true],[10,\"href\",\"https://stripe.com/us/checkout/legal\"],[10,\"class\",\"link-underline\"],[10,\"target\",\"_blank\"],[10,\"title\",\"Stripe Checkout User Terms of Service\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n        Stripe Checkout User Terms of Service\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"id\",\"checkout_form_button\"],[12,\"class\",\"btn btn-blue\"],[12,\"disabled\",[23,0,[\"buttonIsDisabled\"]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openCheckoutForm\"]],[8],[0,\"\\n  \"],[1,[23,0,[\"buttonTitle\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"script\",true],[10,\"src\",\"https://checkout.stripe.com/checkout.js\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/interactive/stripe-form/template.hbs"
    }
  });

  _exports.default = _default;
});