define("trivver-frontend/pods/components/models-table/payout-requests/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    columns: [{
      propertyName: 'created',
      title: 'Date',
      component: 'models-table/us-date-created',
      sortDirection: 'desc',
      sortPrecedence: 0,
      className: 'mt-created'
    }, {
      propertyName: 'getStatusTitle',
      title: 'Status',
      className: 'mt-status'
    }, {
      propertyName: 'amount',
      title: 'Amount',
      className: 'mt-amount',
      component: 'models-table/payout-requests/cells/amount'
    }]
  });

  _exports.default = _default;
});