define("trivver-frontend/pods/components/interactive/uploaders/asset-smart-tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    historyStack: Ember.inject.service(),
    notify: Ember.inject.service(),
    showSaveAndCancel: true,
    nullOrFileLink: function nullOrFileLink(file, url) {
      return file ? URL.createObjectURL(file) : url;
    },
    assetIconPreview: Ember.computed('model.files.file_icon', 'model.asset.file_icon', function () {
      var url = Ember.get(this, 'model.asset.file_icon');
      var file = Ember.get(this, 'model.files.file_icon');
      return this.nullOrFileLink(file, url);
    }),
    assetImagePreview: Ember.computed('model.files.file_image', 'model.asset.file_image', function () {
      var url = Ember.get(this, 'model.asset.file_image');
      var file = Ember.get(this, 'model.files.file_image');
      return this.nullOrFileLink(file, url);
    }),
    assetVideoMobilePreview: Ember.computed('model.files.video_mobile', 'model.asset.video_mobile', function () {
      var url = Ember.get(this, 'model.asset.video_mobile');
      var file = Ember.get(this, 'model.files.video_mobile');
      return this.nullOrFileLink(file, url);
    }),
    actions: {
      addImageFile: function addImageFile(field, file) {
        if (!['jpg', 'png'].includes(file.name.split('.').pop().toLowerCase())) {
          this.notify.alert('Only *.jpg, *.png files are allowed');
          return;
        }

        Ember.set(this, "model.files.".concat(field), file);
      },
      addVideoFile: function addVideoFile(field, file) {
        if (!(file.name.split('.').pop().toLowerCase() === 'mp4')) {
          this.notify.alert('Only *.mp4 files are allowed');
          return;
        }

        Ember.set(this, "model.files.".concat(field), file);
      },
      removeFile: function removeFile(field) {
        Ember.set(this, "model.files.".concat(field), null);
        Ember.set(this, "model.asset.".concat(field), null);
      }
    }
  });

  _exports.default = _default;
});