define("trivver-frontend/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "trivver-frontend/mixins/spinner-loading-action"], function (_exports, _applicationRouteMixin, _spinnerLoadingAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _spinnerLoadingAction.default, {
    currentUser: Ember.inject.service(),
    // intercom: service(),
    router: Ember.inject.service('-routing'),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    _loadCurrentUser: function _loadCurrentUser() {
      return this.currentUser.load();
    },
    _userRedirect: function _userRedirect(type) {
      if (type === 'AD') {
        this.transitionTo('home.cabinet.advertiser');
      } else {
        this.transitionTo('home.cabinet.developer');
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.router.router.url === '/') {
        if (this.get('session.isAuthenticated')) {
          this._loadCurrentUser().then(function (e) {
            _this._userRedirect(e.get('type'));
          });
        } else {
          this.transitionTo('home.login');
        }
      } // if (this.get('session.isAuthenticated')) {
      //   this._loadCurrentUser().then(e => this.get('intercom').boot(e));
      // } else {
      //   return this.get('intercom').bootDefault();
      // }


      return this._loadCurrentUser();
    },
    sessionInvalidated: function sessionInvalidated() {
      this._super.apply(this, arguments);

      this.set('currentUser.user', null); // this.get('intercom').clear();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      // eslint-disable-next-line
      this._super.apply(this, arguments);

      this._loadCurrentUser().then(function (e) {
        // this.get('intercom').update(e);
        _this2._userRedirect(e.get('type'));
      }).catch(function () {
        _this2.session.invalidate();

        _this2.notify.alert('Something went wrong. Please contact us.');
      });
    }
  });

  _exports.default = _default;
});