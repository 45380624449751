define("trivver-frontend/pods/matching-spot/model", ["exports", "@ember-data/model", "trivver-frontend/config/environment", "trivver-frontend/constants/messages"], function (_exports, _model, _environment, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    created: (0, _model.attr)('string'),
    version: (0, _model.attr)('string'),
    game: (0, _model.belongsTo)('matching-game'),
    getImage: Ember.computed('image', function () {
      var url = this.image;

      if (url && url[0] === '/') {
        return _environment.default.APP.API_V1_HOST + url;
      }

      return url;
    }),
    getDescription: Ember.computed('description', function () {
      var text = this.description;

      if (!text) {
        return _messages.SPOT_EMPTY_DESCRIPTION;
      }

      return text;
    })
  });

  _exports.default = _default;
});