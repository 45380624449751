define("trivver-frontend/services/ajax", ["exports", "ember-ajax/services/ajax", "trivver-frontend/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    host: "".concat(_environment.default.APP.API_V1_HOST, "/").concat(_environment.default.APP.API_NAMESPACE),
    customHeaders: {},
    headers: Ember.computed('session.authToken', {
      get: function get() {
        var headers = this.customHeaders;

        if (this.get('session.session.isAuthenticated')) {
          var key = this.get('session.session.authenticated.key');
          headers.Authorization = "token ".concat(key);
        }

        headers['X-CSRFToken'] = this.cookies.read('csrftoken');
        return headers;
      }
    })
  });

  _exports.default = _default;
});