define("trivver-frontend/pods/components/models-table/smart-object-details/link-to-campaign/component", ["exports", "trivver-frontend/constants/campaign-types"], function (_exports, _campaignTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href'],
    router: Ember.inject.service('-routing'),
    href: Ember.computed(function () {
      var campaign = this.record;
      var route = 'home.cabinet.advertiser.campaigns.update.smart-object';

      if (campaign.get('type') === _campaignTypes.PRODUCT_PORTAL) {
        route = 'home.cabinet.advertiser.pp.campaigns.update.products';
      }

      return this.router.generateURL(route, [campaign.get('id')]);
    })
  });

  _exports.default = _default;
});