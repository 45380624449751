define("trivver-frontend/pods/home/email-confirm/route", ["exports", "trivver-frontend/mixins/routes/unlogged-user-only"], function (_exports, _unloggedUserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unloggedUserOnly.default.extend({
    breadCrumb: null,
    config: Ember.inject.service(),
    getAppLink: function getAppLink() {
      var _this = this;

      if (/Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        Ember.run.later(this, function () {
          document.location = _this.get('config.mobileDeepLinks.iOS');
        }, 500);
        Ember.run.later(this, function () {
          document.location = _this.get('config.mobileDeepLinks.android');
        }, 1000);
      }
    },
    model: function model(params, queryParams) {
      return Ember.RSVP.hash({
        userType: params.type || queryParams.queryParams.type
      });
    },
    afterModel: function afterModel() {
      this.getAppLink();
    }
  });

  _exports.default = _default;
});