define("trivver-frontend/pods/components/modals/confirm-remove-product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7SFikcds",
    "block": "{\"symbols\":[],\"statements\":[[5,\"modals/yes-no-delete\",[],[[\"@open\",\"@object\",\"@deleteAction\",\"@yes\",\"@yesColor\",\"@no\",\"@title\"],[[23,0,[\"open\"]],[23,0,[\"object\"]],[28,\"action\",[[23,0,[]],[23,0,[\"deleteAction\"]]],null],\"Remove Product\",\"red\",\"Cancel\",\"Remove Product\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Are you sure you want to remove the product?\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/confirm-remove-product/template.hbs"
    }
  });

  _exports.default = _default;
});