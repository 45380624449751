define("trivver-frontend/pods/home/cabinet/developer/games/game/route", ["exports", "trivver-frontend/mixins/routes/developer-only"], function (_exports, _developerOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _developerOnly.default.extend({
    store: Ember.inject.service(),
    breadCrumb: null,
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        gameUID: params.uid,
        game: this.store.findRecord('game', params.uid),
        genders: this.store.findAll('gender'),
        platforms: this.store.findAll('platform'),
        ages: this.store.findAll('age'),
        genres: this.store.findAll('genre')
      });
    }
  });

  _exports.default = _default;
});