define("trivver-frontend/helpers/to-yes-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(value, params) {
      return value[0] === (params.true || true) ? 'Yes' : 'No';
    }
  });

  _exports.default = _default;
});