define("trivver-frontend/pods/screenshot/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = _model.default.extend({
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    image: (0, _model.attr)('file'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    thumbnail: (0, _model.attr)('string'),
    game: (0, _model.belongsTo)('game'),
    imagePreview: Ember.computed('image', function () {
      var image = this.image;
      return _typeof(image) === 'object' && image ? URL.createObjectURL(this.image) : this.image;
    }),
    uploadImage: (0, _emberApiActions.memberAction)({
      path: 'upload/',
      type: 'post',
      urlType: 'updateRecord'
    }),
    // Flags
    for_delete: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    is_changed: Ember.computed('image', 'id', function () {
      return this.id && this.changedAttributes().image;
    }),
    is_new: Ember.computed('id', function () {
      return !this.id && this.image;
    })
  });

  _exports.default = _default;
});