define("trivver-frontend/mixins/routes/advertiser-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ALLOWED_USERS = ['ADMIN', 'AD'];

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var userType = this.get('currentUser.user.type');

      if (!ALLOWED_USERS.includes(userType)) {
        this.transitionTo('home.403');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render();
      this.set('title', document.title);
      document.title = 'Advertiser Dashboard - Trivver';
    },
    actions: {
      willTransition: function willTransition() {
        document.title = this.title;
      }
    }
  });

  _exports.default = _default;
});