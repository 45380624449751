define("trivver-frontend/services/unity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    items: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('items', {});
    },
    unloadAll: function unloadAll() {
      var items = this.items;
      Object.keys(items).forEach(function (key) {
        items[key].SendMessage('Canvas', 'LoadAsset', '');
      });
    }
  });

  _exports.default = _default;
});