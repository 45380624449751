define("trivver-frontend/pods/pp-asset/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/copyable", "trivver-frontend/constants/colors", "ember-api-actions"], function (_exports, _model, _emberCpValidations, _copyable, _colors, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    asset_3d_model: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Asset 3D model required."
    }), (0, _emberCpValidations.validator)("belongs-to", true)],
    title: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      max: 40
    })],
    description: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      max: 1024
    })],
    where_to_buy: [(0, _emberCpValidations.validator)("format", {
      regex: /(^$|^https?:\/\/[\w. /%=&#@*(){}+?!^$-_~\\]+$)/i,
      message: "Incorrect url"
    }), (0, _emberCpValidations.validator)("length", {
      max: 1024
    })],
    subtitle: [(0, _emberCpValidations.validator)("format", {
      regex: /(^$|^https?:\/\/[\w. /%=&#@*(){}+?!^$-_~\\]+$)/i,
      message: "Incorrect url"
    }), (0, _emberCpValidations.validator)("length", {
      max: 255
    })],
    file_image: [(0, _emberCpValidations.validator)("image-format"), (0, _emberCpValidations.validator)("image-size"), (0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Please select image file from your disk."
    })],
    background_color: [(0, _emberCpValidations.validator)("presence", true)],
    coupon_settings: [(0, _emberCpValidations.validator)("belongs-to", true)]
  }, {
    disabled: Ember.computed.not("model.validationAttempted")
  });

  var previewOrNull = function previewOrNull(file) {
    if (file && file.size) {
      return URL.createObjectURL(file);
    }

    if (typeof file === "string") {
      var proto = file.startsWith("http") ? "" : "https://";
      return "".concat(proto).concat(file);
    }

    return null;
  };

  function convertNullToUndefined(field) {
    return Ember.observer(field, function () {
      if (!this.get(field)) {
        this.set(field, null);
      }
    });
  }

  var _default = _model.default.extend(_copyable.default, Validations, {
    _id: (0, _model.attr)("number"),
    uid: (0, _model.attr)("string"),
    title: (0, _model.attr)("string"),
    description: (0, _model.attr)("string"),
    where_to_buy: (0, _model.attr)("string", {
      defaultValue: ""
    }),
    subtitle: (0, _model.attr)("string", {
      defaultValue: ""
    }),
    file_image: (0, _model.attr)(),
    file_icon: (0, _model.attr)(),
    file_standalone: (0, _model.attr)(),
    coupon_id: (0, _model.attr)("number"),
    campaign_id: (0, _model.attr)("string"),
    is_primary: (0, _model.attr)("boolean", {
      defaultValue: false
    }),
    social_share_facebook: (0, _model.attr)("boolean"),
    social_share_twitter: (0, _model.attr)("boolean"),
    created: (0, _model.attr)("date", {
      defaultValue: function defaultValue() {
        return new Date().toLocaleString();
      }
    }),
    background_color: (0, _model.attr)("string", {
      defaultValue: _colors.WHITE
    }),
    is_shared: (0, _model.attr)("boolean"),
    AR_options: (0, _model.attr)(),
    otherCampaigns: (0, _model.attr)("array"),
    file_glb_full_res: (0, _model.attr)("string"),
    objectFile: Ember.computed("file_glb_full_res", "subtitle", "file_standalone", function () {
      var _this$subtitle;

      var glbAsset = !!this.subtitle && (_this$subtitle = this.subtitle) !== null && _this$subtitle !== void 0 && _this$subtitle.startsWith("https://") ? this.subtitle : this.file_glb_full_res;
      if (glbAsset) return glbAsset;
      return this.file_standalone;
    }),
    asset_3d_model: (0, _model.belongsTo)("asset-3d-model", {
      async: true
    }),
    coupon_settings: (0, _model.belongsTo)("pp-coupon", {
      async: true,
      defaultValue: null
    }),
    assetUrl: Ember.computed("where_to_buy", function () {
      // If the link is not absolute, fix it
      var url = String(this.where_to_buy);

      if (url.includes("https://") || url.includes("http://")) {
        return url;
      }

      return "http://".concat(url);
    }),
    fileImagePreview: Ember.computed("file_image", function () {
      return previewOrNull(this.file_image);
    }),
    fileIconPreview: Ember.computed("file_icon", "file_image", function () {
      return previewOrNull(this.file_icon || this.file_image);
    }),
    fileStandalonePreview: Ember.computed("file_standalone", "asset_3d_model.file_standalone", function () {
      var preview = this.get("asset_3d_model.file_standalone");

      if (!preview) {
        preview = previewOrNull(this.file_standalone);
      }

      return preview;
    }),
    uploadFiles: (0, _emberApiActions.memberAction)({
      path: "api/v1/asset-files/",
      type: "post"
    }),
    getFilesResult: (0, _emberApiActions.memberAction)({
      path: "api/v1/task-status/",
      type: "get"
    }),
    fileImageChanged: convertNullToUndefined("file_image")
  });

  _exports.default = _default;
});