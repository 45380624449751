define("trivver-frontend/pods/components/toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kpVYa01E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"toggle-switch__label\"],[8],[1,[23,0,[\"onLabel\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"toggle-switch__button\"],[8],[0,\" \"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"toggle-switch__label\"],[8],[1,[23,0,[\"offLabel\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});