define("trivver-frontend/mixins/routes/set-date-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      setStartDate: function setStartDate(momentObject) {
        this.controller.set('model.campaign.start', momentObject);
      },
      setEndDate: function setEndDate(momentObject) {
        if (!momentObject) {
          this.controller.set('model.campaign.end', null);
          return;
        }

        this.controller.set('model.campaign.end', momentObject);
      }
    }
  });

  _exports.default = _default;
});