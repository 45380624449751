define("trivver-frontend/pods/components/models-table/advertiser-products/product-title-min/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M1FeI5Vz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"strong\",true],[8],[1,[28,\"truncate\",[[23,0,[\"record\",\"title\"]],12],null],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-products/product-title-min/template.hbs"
    }
  });

  _exports.default = _default;
});