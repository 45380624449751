define("trivver-frontend/pods/components/inputs/interactive-file-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeFile: function changeFile(field, file) {
        if (file) {
          this.set("model.".concat(field), file);
          this.sendAction('changeFile', field, file);
        }
      }
    }
  });

  _exports.default = _default;
});