define("trivver-frontend/pods/drs-user/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    state: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('us-state')],
    city: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    address: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    zip_code: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('zipcode-valid')],
    is_agreed: [(0, _emberCpValidations.validator)('inclusion', {
      in: [true],
      message: _messages.REQUIRED
    })]
  });

  var _default = _model.default.extend(Validations, {
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    state: (0, _model.belongsTo)('state'),
    city: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    zip_code: (0, _model.attr)('string'),
    is_agreed: (0, _model.attr)('boolean'),
    recaptcha: (0, _model.attr)('string')
  });

  _exports.default = _default;
});