define("trivver-frontend/pods/components/progress-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JAuSWtUf",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"steps\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"steps__line\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"steps\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[11,\"class\",[29,[\"step\\n      \",[28,\"if\",[[23,0,[\"current\"]],[28,\"if\",[[28,\"eq\",[[23,0,[\"current\"]],[23,1,[\"slug\"]]],null],\"step--active\"],null]],null],\"\\n      \",[28,\"if\",[[23,0,[\"prevSteps\"]],[28,\"if\",[[28,\"includes\",[[23,0,[\"prevSteps\"]],[23,1,[\"slug\"]]],null],\"step--done\"],null]],null]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"step__text\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"step__title\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"clickable\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"allowedSteps\"]]],null,{\"statements\":[[4,\"if\",[[28,\"includes\",[[23,0,[\"allowedSteps\"]],[23,1,[\"slug\"]]],null]],null,{\"statements\":[[0,\"              \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"step__link\"],[3,\"action\",[[23,0,[]],\"goto\",[23,1,[\"slug\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"step__link\"],[3,\"action\",[[23,0,[]],\"goto\",[23,1,[\"slug\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/progress-steps/template.hbs"
    }
  });

  _exports.default = _default;
});