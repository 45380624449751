define("trivver-frontend/pods/org/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/field-validation", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _fieldValidation, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', {
      disabled: Ember.computed.not('model.titleFlag'),
      presence: true,
      message: _messages.VALIDATOR_NOT_BLANK
    })],
    url: [(0, _emberCpValidations.validator)('length', {
      max: 128
    }), (0, _emberCpValidations.validator)('url')]
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    title: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    budget: (0, _model.attr)('number'),
    uid: (0, _model.attr)('string'),
    spend_total: (0, _model.attr)('cent'),
    spend_month: (0, _model.attr)('cent'),
    spend_today: (0, _model.attr)('cent'),
    spend_total_pp: (0, _model.attr)('cent'),
    spend_month_pp: (0, _model.attr)('cent'),
    spend_today_pp: (0, _model.attr)('cent'),
    isFormValid: Ember.computed('validations.isValid', function () {
      this.enableAllFlags();
      return this.get('validations.isValid');
    }),
    spend_today_all: Ember.computed('spend_today', 'spend_today_pp', function () {
      this.enableAllFlags();
      return this.spend_today + this.spend_today_pp;
    }),
    spend_month_all: Ember.computed('spend_month', 'spend_month_pp', function () {
      this.enableAllFlags();
      return this.spend_month + this.spend_month_pp;
    }),
    spend_total_all: Ember.computed('spend_total', 'spend_total_pp', function () {
      this.enableAllFlags();
      return this.spend_total + this.spend_total_pp;
    })
  });

  _exports.default = _default;
});