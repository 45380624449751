define("trivver-frontend/validators/tbso-size", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TBSOSizeValidator = _base.default.extend({
    validate: function validate(value) {
      if (value && !(typeof value === "string")) {
        if (value.size <= _environment.default.APP.TBSO_FILE_MAX_SIZE) {
          return true;
        }

        return "File size should be less than ".concat(_environment.default.APP.TBSO_FILE_MAX_SIZE / 1024 / 1024, " MB");
      }

      return true;
    }
  });

  var _default = TBSOSizeValidator;
  _exports.default = _default;
});