define("trivver-frontend/pods/components/date-range/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Range datepicker
   *
   * Start and end dated by default is current date and 4 month ago accordingly
   *
   * @class DateRangeComponent
   * @module components/date-range
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * Initialize datepicker
     *
     * @method didInsertElement
     * @return {undefined}
     * @public
     */
    didInsertElement: function didInsertElement() {
      Ember.$('#datepicker').datepicker({});
    }
  });

  _exports.default = _default;
});