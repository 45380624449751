define("trivver-frontend/pods/components/interactive/stripe-form/component", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // another example https://jsfiddle.net/osrLsc8m/
    stripePK: _environment.default.stripe.publishableKey,
    isProcessed: false,
    accepted: false,
    buttonIsDisabled: Ember.computed('accepted', 'isProcessed', 'model.validations.isValid', function () {
      return !this.get('model.validations.isValid') || !this.accepted || this.isProcessed;
    }),
    campaignImage: '/img/general/logo_official.png',
    campaignTitle: 'Trivver Campaign',
    campaignDescription: Ember.computed('model.campaign.title', 'model.amount', function () {
      return "".concat(this.get('model.campaign.title'), " ($").concat(parseFloat(this.get('model.amount')).toFixed(2), ")");
    }),
    transferAmount: Ember.computed('model.amount', function () {
      return this.get('model.amountToCents');
    }),
    releaseButton: function releaseButton() {
      this.set('isProcessed', false);
    },
    freezeButton: function freezeButton() {
      this.set('isProcessed', true);
    },
    actions: {
      openCheckoutForm: function openCheckoutForm() {
        this.freezeButton(); // eslint-disable-next-line no-undef

        var handler = StripeCheckout.configure({
          key: this.stripePK,
          image: this.campaignImage,
          locale: 'en',
          token: function (token) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            var paymentAction = this.paymentAction;
            paymentAction(token);
          }.bind(this),
          closed: function () {
            this.releaseButton();
          }.bind(this)
        });
        handler.open({
          name: this.campaignTitle,
          description: this.campaignDescription,
          amount: this.transferAmount
        });
      }
    }
  });

  _exports.default = _default;
});