define("trivver-frontend/constants/verification-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VERIFIED = _exports.UNVERIFIED = _exports.PENDING = void 0;
  var VERIFIED = 'verified';
  _exports.VERIFIED = VERIFIED;
  var UNVERIFIED = 'unverified';
  _exports.UNVERIFIED = UNVERIFIED;
  var PENDING = 'pending';
  _exports.PENDING = PENDING;
});