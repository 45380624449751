define("trivver-frontend/pods/components/progress-steps/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: null,
    actions: {
      goto: function goto(slug) {
        this.sendAction('goto', slug);
      }
    }
  });

  _exports.default = _default;
});