define("trivver-frontend/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xkIc5hmk",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[5,\"ember-notify\",[],[[\"@messageStyle\",\"@classPrefix\"],[\"bootstrap\",\"custom-notify\"]]],[0,\"\\n\\n\"],[1,[28,\"presenters/spinner-layout\",null,[[\"name\"],[\"overall\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});