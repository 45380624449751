define("trivver-frontend/pods/components/toggle-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['toggle-switch'],
    attributeBindings: ['state', 'href'],
    state: null,
    tagName: 'a',
    href: '#',
    toggleState: function toggleState() {
      this.set('state', this.state === 'off' ? 'on' : 'off');
    },
    click: function click() {
      this.toggleState();
      this.set('value', this.state === 'on');
      return false;
    },
    didInsertElement: function didInsertElement() {
      this.set('state', this.value ? 'on' : 'off');
    }
  });

  _exports.default = _default;
});