define("trivver-frontend/pods/pp-campaign-custom-color/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE),
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.createRecord(store, type, snapshot);
    },
    pathForType: function pathForType() {
      return 'productportal/campaigns/request-color/';
    }
  });

  _exports.default = _default;
});