define("trivver-frontend/pods/components/clearable-date-range-picker/component", ["exports", "ember-cli-daterangepicker/components/date-range-picker"], function (_exports, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateRangePicker.default.extend({
    rangeText: Ember.computed('start', 'end', {
      get: function get() {
        var format = this.format;
        var serverFormat = this.serverFormat;
        var start = this.start;
        var end = this.end;

        if (!Ember.isEmpty(start) && !Ember.isEmpty(end)) {
          return moment(start, serverFormat).format(format) + this.separator + moment(end, serverFormat).format(format);
        }

        return '';
      },
      set: function set(key, value) {
        if (!value) {
          this.send('clearAction');
        }
      }
    }),
    attachPickerEvents: function attachPickerEvents() {
      var _this = this;

      this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        _this.handleDateRangePickerEvent('applyAction', picker);
      });
      this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
        _this.handleDateRangePickerEvent('cancelAction', undefined, true);
      });
    },
    actions: {
      clearAction: function clearAction() {
        this.set('start', undefined);
        this.set('end', undefined);
        this.sendAction(this.clearAction);
      }
    }
  });

  _exports.default = _default;
});