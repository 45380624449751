define("trivver-frontend/pods/home/403/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tp+h1St7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\"Error 403\"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\"You do not have the permission\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/403/template.hbs"
    }
  });

  _exports.default = _default;
});