define("trivver-frontend/helpers/asset-category-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(categories, params) {
      return categories[0].filterBy('parent.id', params.parent.get('id'));
    }
  });

  _exports.default = _default;
});