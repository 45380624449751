define("trivver-frontend/pods/home/register-drs/controller", ["exports", "trivver-frontend/constants/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    spinner: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    openSuccessModal: false,
    errors: {},
    actions: {
      validate: function validate() {
        var _this = this;

        var fields = ['first_name', 'last_name', 'address', 'city', 'state', 'zip_code', 'email', 'is_agreed'];
        var model = this.get('model.user');
        model.validate({
          on: fields
        }).then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.send('save', model);
          }

          if (validations.get('errors')) {
            _this.set('errors', {
              detail: _messages.API_ERROR,
              validation_errors: null
            });

            _this.set('errors.validation_errors', validations.get('errors').map(function (error) {
              return {
                field: error.attribute,
                errors: [error.message]
              };
            }));
          }
        });
      },
      save: function save() {
        var _this2 = this;

        this.spinner.show('overall');
        this.get('model.user').save().then(function () {
          _this2.set('model.user', _this2.store.createRecord('drs-user'));

          _this2.set('openSuccessModal', true);
        }).catch(function (e) {
          _this2.set('errors', {
            detail: _messages.API_ERROR,
            validation_errors: e.validation_errors
          });

          _this2.notify.error(_messages.SOMETHING_GOES_WRONG);

          _this2.send('onCaptchaExpired');
        }).finally(function () {
          _this2.spinner.hide('overall');
        });
      },
      closeAction: function closeAction() {
        document.location.href = 'https://trivver.com';
      },
      onCaptchaResolved: function onCaptchaResolved(reCaptchaResponse) {
        this.set('model.user.recaptcha', reCaptchaResponse);
      },
      onCaptchaRendered: function onCaptchaRendered() {
        grecaptcha.execute(); // eslint-disable-line
      },
      onCaptchaExpired: function onCaptchaExpired() {
        grecaptcha.reset(); // eslint-disable-line

        grecaptcha.execute(); // eslint-disable-line
      }
    }
  });

  _exports.default = _default;
});