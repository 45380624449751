define("trivver-frontend/pods/campaign-report-export/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE),
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace) + "/xr/report/".concat(id, "/export/csv/");
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      return this.ajax(this.buildURL(type.modelName, id, snapshot, 'findRecord'), 'GET', {
        data: snapshot.adapterOptions
      });
    }
  });

  _exports.default = _default;
});