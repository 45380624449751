define("trivver-frontend/pods/home/cabinet/advertiser/campaigns/create/review/controller", ["exports", "trivver-frontend/mixins/draft-campaign"], function (_exports, _draftCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_draftCampaign.default, {
    router: Ember.inject.service('-routing'),
    config: Ember.inject.service(),
    campaignUrl: Ember.computed('model.campaign', function () {
      return "".concat(document.location.host).concat(this.router.generateURL('home.cabinet.advertiser.campaigns.update.review', this.get('model.campaign')));
    })
  });

  _exports.default = _default;
});