define("trivver-frontend/pods/components/balance-/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    earnedFunds: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var gameUID = this.gameUID;
      this.earnedFunds.getFunds(gameUID, 0).then(function (balance) {
        _this.set('balance', balance ? balance.amount / 100 : 0);
      });
    }
  });

  _exports.default = _default;
});