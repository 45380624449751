define("trivver-frontend/pods/components/select-row-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nQjp3Y9L",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[23,0,[\"isSelected\"]],[28,\"action\",[[23,0,[]],\"clickOnRow\",[23,0,[\"record\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/select-row-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});