define("trivver-frontend/pods/components/link-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * Link tabs component
   *
   * Configurable through ``data`` following object format:
   *
   * tabLinks: [
   *   {
   *     title: 'All time',
   *     actionArgs: [0],
   *     active: true,
   *   },
   *   {
   *     title: 'Last 30 Days',
   *     actionArgs: [1],
   *   },
   *   {
   *     title: 'Last Quarter',
   *     actionArgs: [3],
   *   },
   *   {
   *     title: 'Last 6 Months',
   *     actionArgs: [6],
   *   },
   *   {
   *     title: 'This Year',
   *     actionArgs: [12],
   *   },
   * ],
   *
   * @class LinkTabsComponent
   * @module components/link-tabs
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    actions: {
      /**
       * Set active tab
       *
       * @method setActive
       * @param {int} index number of tab
       * @return {undefined}
       * @public
       */
      setActive: function setActive(index) {
        var _this = this;

        this.data.forEach(function (value, i) {
          return _this.set("data.".concat(i, ".active"), i === index);
        });
        var args = this.get("data.".concat(index, ".actionArgs"));
        this.sendAction.apply(this, ['action'].concat(_toConsumableArray(args)));
      }
    }
  });

  _exports.default = _default;
});