define("trivver-frontend/pods/confirm-password/adapter", ["exports", "trivver-frontend/pods/drf/adapter", "trivver-frontend/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE, "/auth/password"),
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/reset/");
    }
  });

  _exports.default = _default;
});