define("trivver-frontend/pods/retrieve-balance/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    balance: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    available_for_payout: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    payout_fee: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    requested_for_payout: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    paid_out: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    not_available_for_payout: Ember.computed(function () {
      return this.balance - (this.available_for_payout + this.requested_for_payout);
    })
  });

  _exports.default = _default;
});