define("trivver-frontend/pods/components/product-portal-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultWidgetTitle: 'Product Portal',
    defaultTitle: 'Product title',
    defaultDescription: 'Product description',
    type: '3d',
    selected: null,
    center: null,
    campaignType: 'orignal',
    colorSettings: {},
    settings: {},
    backgroundColor: null,
    // Is visible is required for invisible elements which are presented in DOM.
    // For instance it's required for tabs and dialogs because they are always in DOM but not visible.
    isVisible: true,
    attributeBindings: ['style'],
    classNames: ['product-portal'],
    assets: [],
    items: Ember.computed('assets', function () {
      var array = [];
      this.assets.forEach(function (asset) {
        array.push(asset);
      });
      return array;
    }),
    titleFontSize: Ember.computed('title', function () {
      var title = this.title;

      if (title && title.length > 11) {
        return '18px';
      }

      return '24px';
    }),
    style: Ember.computed('colorSettings', function () {
      var mainColor = this.get('colorSettings.mainColor') || '';
      return "background-color: ".concat(mainColor);
    }),
    calculate: function calculate(item) {
      var items = this.items;

      if (!item) {
        if (items.length > 1) {
          this.set('left', items[0]);
          this.set('center', items[1]);
          this.set('right', items[2]);
        } else {
          this.set('center', items[0]);
        }

        this.set('selected', null);
        return;
      }

      var left = items.indexOf(item) - 1;
      var right = items.indexOf(item) + 1;
      this.set('center', item);
      this.set('selected', item);
      this.set('asset', item);

      if (items.length > 1) {
        this.set('left', items[left < 0 ? items.length - 1 : left]);
        this.set('right', items[right + 1 > items.length ? 0 : right]);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.calculate(this.asset);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('settings', this.colorSettings);
    },
    actions: {
      select: function select(item) {
        if (item) {
          this.calculate(item);
        }
      }
    }
  });

  _exports.default = _default;
});