define("trivver-frontend/pods/components/partials/pp-ad-network-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PbWTqzqI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"copyable-content\",[[12,\"class\",\"pull-left\"]],[[\"@buttonText\",\"@buttonClass\",\"@copyButtonText\"],[[24,[\"campaign\",\"labelForGetLink\"]],[23,0,[\"buttonClass\"]],\"Copy Link\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Select \"],[7,\"code\",true],[8],[0,\"“Copy Link”\"],[9],[0,\" below to copy, then paste the \\n  Portal Link (URL) into the HTML code for your product Ad.\"],[9],[0,\"\\n  \"],[7,\"textarea\",true],[10,\"class\",\"form-control mb10\"],[10,\"readonly\",\"readonly\"],[10,\"onclick\",\"this.focus();this.select()\"],[10,\"rows\",\"3\"],[8],[1,[24,[\"campaign\",\"adSiteLink\"]],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Suggested link placement: Add this link as the target of a \\n  \"],[7,\"code\",true],[8],[0,\"“See in 3D”\"],[9],[0,\" button, locate it in the top left corner of \\n  main product image or video in the Ad.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"copyable-content\",[[12,\"class\",\"pull-left\"]],[[\"@buttonText\",\"@buttonClass\",\"@copyButtonText\"],[\"Get Email Button Code\",[23,0,[\"buttonClass\"]],\"Copy Code\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"The code below contains a \"],[7,\"code\",true],[8],[0,\"“See in 3D”\"],[9],[0,\" icon and a link\\n    to your portal campaign. Select \"],[7,\"code\",true],[8],[0,\"“Copy Code”\"],[9],[0,\" to copy\\n    this code. Paste the copied code into your HTML E-Mail:\"],[9],[0,\"\\n  \"],[7,\"textarea\",true],[10,\"class\",\"form-control mb10\"],[10,\"readonly\",\"readonly\"],[10,\"onclick\",\"this.focus();this.select()\"],[10,\"rows\",\"5\"],[8],[1,[23,0,[\"campaign\",\"emailButtonCode\"]],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Please consult documentation for your E-Mail client\\n    if you plan to add this code to a personal E-Mail.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/partials/pp-ad-network-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});