define("trivver-frontend/helpers/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.split = split;

  /**
   * Split and trim by comma
   *
   * @function split
   * @param {Array} params 1st element is string to split and trim by comma
   * @return {Array} array of strings split by comma
   * @public
   */
  function split(params) {
    return params[0].trim().split(/\s*,\s*/);
  }

  var _default = Ember.Helper.helper(split);

  _exports.default = _default;
});