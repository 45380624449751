define("trivver-frontend/pods/components/modals/product-coupon-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      hide: function hide() {
        this.set('open', false);
        this.sendAction('onHideAction');
      },
      show: function show() {
        this.set('open', true);
      },
      saveProductCouponAction: function saveProductCouponAction(model) {
        this.sendAction('saveProductCouponAction', model);
      }
    }
  });

  _exports.default = _default;
});