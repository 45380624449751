define("trivver-frontend/pods/change-password/serializer", ["exports", "trivver-frontend/pods/drf/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    store: Ember.inject.service(),
    extractId: function extractId() {
      this.store.peekAll('change-password').forEach(function (i) {
        return !i.get('isNew') && i.unloadRecord();
      });
      return 1;
    }
  });

  _exports.default = _default;
});