define("trivver-frontend/constants/transfer-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FAILED = 'failed';
  var _default = FAILED;
  _exports.default = _default;
});