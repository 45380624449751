define("trivver-frontend/pods/asset-3d-model/model", ["exports", "@ember-data/model", "ember-cp-validations", "ember-api-actions"], function (_exports, _model, _emberCpValidations, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 80
    }), (0, _emberCpValidations.validator)('smart-object-title')],
    file_tbso: [(0, _emberCpValidations.validator)('tbso-format'), (0, _emberCpValidations.validator)('tbso-size'), (0, _emberCpValidations.validator)('presence', true)]
  }, {
    disabled: Ember.computed.not('model.validationAttempted')
  });

  var _default = _model.default.extend(Validations, {
    uid: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    file_preview: (0, _model.attr)('file'),
    file_tbso: (0, _model.attr)('file'),
    file_standalone: (0, _model.attr)('file'),
    is_have_campaign: (0, _model.attr)('boolean'),
    uploadingStatus: (0, _emberApiActions.memberAction)({
      path: 'uploading_status/',
      type: 'get'
    })
  });

  _exports.default = _default;
});