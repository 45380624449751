define("trivver-frontend/constants/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEVELOPER = _exports.ADVERTISER = void 0;
  var DEVELOPER = 'DEV';
  _exports.DEVELOPER = DEVELOPER;
  var ADVERTISER = 'AD';
  _exports.ADVERTISER = ADVERTISER;
});