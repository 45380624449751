define("trivver-frontend/helpers/get-validation-message", ["exports", "ember-advanced-validations/helpers/get-validation-message"], function (_exports, _getValidationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getValidationMessage.default;
    }
  });
  Object.defineProperty(_exports, "getValidationMessage", {
    enumerable: true,
    get: function get() {
      return _getValidationMessage.getValidationMessage;
    }
  });
});