define("trivver-frontend/pods/engagement-stat/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    coupon: (0, _model.attr)('number'),
    facebook: (0, _model.attr)('number'),
    instagram: (0, _model.attr)('number'),
    purchase: (0, _model.attr)('number'),
    smarttab: (0, _model.attr)('number'),
    action_3d: (0, _model.attr)('number'),
    twitter: (0, _model.attr)('number'),
    video: (0, _model.attr)('number'),
    viewability: (0, _model.attr)('number')
  });

  _exports.default = _default;
});