define("trivver-frontend/router", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    return this.route("home", {
      path: "/"
    }, function () {
      this.route("user-types");
      this.route("email-confirm");
      this.route("register-dev");
      this.route("register-adv");
      this.route("register-drs");
      this.route("login");
      this.route("login-token", {
        path: "login_token/"
      });
      this.route("confirmation-email-link");
      this.route("forgot-password");
      this.route("forgot-password-confirm", {
        path: "forgot-password/confirm/:uid/:token/"
      });
      this.route("cabinet", {
        path: "/"
      }, function () {
        this.route("account-settings");
        this.route("payment-history");
        this.route("developer", function () {
          this.route("org");
          this.route("games", function () {
            this.route("new");
            this.route("game", {
              path: ":uid"
            }, function () {
              this.route("details");
              this.route("edit");
            });
          });
          this.route("funds-earned-details");
          this.route("payouts");
          this.route("help");
        }); // this.route("v2", { path: "v2/advertiser/pp/campaigns/" }, function () {
        //   this.route("retrieve", { path: ":id" });
        // });

        this.route("advertiser", function () {
          this.route("campaigns", function () {
            this.route("retrieve", {
              path: ":id"
            });
            this.route("update", {
              path: ":id/update"
            }, function () {
              this.route("smart-object");
              this.route("smart-object-category");
              this.route("smart-tab");
              this.route("games");
              this.route("coupons");
              this.route("review");
            });
            this.route("create", function () {
              this.route("smart-object");
              this.route("smart-object-category");
              this.route("smart-tab");
              this.route("games");
              this.route("coupons");
              this.route("costs");
              this.route("review");
            });
          });
          this.route("pp", function () {
            this.route("campaigns", function () {
              this.route("retrieve", {
                path: ":id"
              });
              this.route("update", {
                path: ":id/update"
              }, function () {
                this.route("design");
                this.route("products");
                this.route("media");
                this.route("coupons");
                this.route("review");
              });
              this.route("create", function () {
                this.route("design");
                this.route("products");
                this.route("media");
                this.route("coupons");
                this.route("costs");
                this.route("review");
              });
            });
          });
          this.route("assets", function () {
            this.route("add");
            this.route("asset", {
              path: ":id"
            });
            this.route("edit", {
              path: ":id/edit"
            });
          });
          this.route("game", {
            path: "game/:uid"
          }, function () {
            this.route("details");
          });
          this.route("smart-objects");
        });
      });
      this.route("403");
      this.route("404", {
        path: "/*path"
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});