define("trivver-frontend/pods/asset/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/application/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    pathForType: function pathForType() {
      return 'assets/';
    },
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/assets/").concat(id, "/");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/assets/").concat(id, "/");
    }
  });

  _exports.default = _default;
});