define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/cpv/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: Ember.computed('record.t_view_count', 'record.t_view_cost', function () {
      var count = this.get('record.t_view_count');
      var cost = this.get('record.t_view_cost');

      if (count != null && cost != null && count > 0) {
        return cost / count;
      }

      return null;
    })
  });

  _exports.default = _default;
});