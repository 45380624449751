define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/media-step-actions", ["exports", "trivver-frontend/constants/deployment-types"], function (_exports, _deploymentTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    openClickButtonNotifyModal: false,
    actions: {
      onTabChange: function onTabChange(prop) {
        /**
         * Set deployment type and show modal for some types
         */
        if (prop === _deploymentTypes.NETWORK || prop === _deploymentTypes.NETWORK_AR) {
          this.set('openClickButtonNotifyModal', true);
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});