define("trivver-frontend/pods/drs-user-campaign/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zip_code: (0, _model.attr)('string'),
    balance: (0, _model.attr)('cent'),
    budget: (0, _model.attr)('cent'),
    date_created: (0, _model.attr)('date'),
    coupons_sum: (0, _model.attr)('cent'),
    coupon_rate: (0, _model.attr)('cent'),
    name: Ember.computed('first_name', 'last_name', function () {
      return "".concat(this.first_name, " ").concat(this.last_name);
    }),
    location: Ember.computed('city', 'state', function () {
      var city = this.city;
      var state = this.state;

      if (!state) {
        return city;
      }

      return "".concat(city, ", ").concat(state);
    }),
    giftable: Ember.computed('balance', 'coupons_sum', function () {
      return this.budget - this.balance - this.coupons_sum - this.coupon_rate >= 0;
    }),
    gift: (0, _emberApiActions.memberAction)({
      path: 'gift/',
      type: 'PUT'
    })
  });

  _exports.default = _default;
});