define("trivver-frontend/pods/home/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fhs6jYC0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\"There was an error\"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\"Please try again later. \"],[7,\"span\",true],[10,\"class\",\"text-muted\"],[8],[0,\"That's all we know\"],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/error/template.hbs"
    }
  });

  _exports.default = _default;
});