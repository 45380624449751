define("trivver-frontend/pods/components/static/report-values-rounding-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CdRgUAgD",
    "block": "{\"symbols\":[],\"statements\":[[0,\"* Due to rounding, some totals may differ slightly calculations \\ncreated by summing values shown here. Please\\n\"],[7,\"a\",true],[10,\"href\",\"mailto:support@trivver.com\"],[8],[0,\"contact Trivver support\"],[9],[0,\" if \\nyou have questions.\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/static/report-values-rounding-note/template.hbs"
    }
  });

  _exports.default = _default;
});