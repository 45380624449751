define("trivver-frontend/pods/components/modals/product-3d-model-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    activeId: 'upload',
    items: Ember.computed('product3dModels.@each', 'search', function () {
      var search = (this.search || '').replace(/^\s+|\s+$/g, '');
      return this.product3dModels.filter(function (model) {
        return model.get('id') && model.get('title').toLowerCase().indexOf(search) >= 0;
      });
    }),
    actions: {
      changeFile: function changeFile(field, file) {
        this.set('model.file_tbso', file);
      },
      hide: function hide() {
        this.set('open', false);
        this.set('errors', null);
        this.set('search', '');
        this.model.destroyRecord();
      },
      show: function show() {
        this.set('selected', this.get('product.asset_3d_model'));
        this.set('open', true); // create asset-3d-model with prefilled title -- product title

        this.set('model', this.store.createRecord('asset-3d-model', {
          title: this.get('product.title')
        })); // scroll to selected element

        if (this.get('selected.id')) {
          this.set('activeId', 'select');
          Ember.$('.product-3d-models').scrollTo(Ember.$("#model".concat(this.get('selected.id'))));
        }
      },
      saveModelAction: function saveModelAction(model) {
        this.sendAction('saveModelAction', model);
      }
    }
  });

  _exports.default = _default;
});