define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/component", ["exports", "trivver-frontend/pods/components/models-table/pp-advertiser-campaign/theme", "trivver-frontend/pods/components/models-table/hints"], function (_exports, _theme, _hints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    campaignsData: [],
    attributeBindings: ['style'],
    themeInstance: _theme.default.create(),
    columns: Ember.computed(function () {
      return [{
        title: '',
        propertyName: 'title',
        disableSorting: true,
        className: 'mt-engagement-name'
      }, {
        title: 'Actions',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.clicks,
        propertyName: 'clicks',
        disableSorting: true,
        className: 'mt-clicks orderable'
      }, {
        title: 'CPA',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.cpa,
        propertyName: 'cost_per_click',
        className: 'mt-cpc orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/cost-per-click'
      }, {
        title: 'T-Views',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.tViews,
        propertyName: 't_view_count',
        className: 'mt-t-view orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/t-views'
      }, {
        title: 'CPV',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.cpv,
        propertyName: 'cpv',
        className: 'mt-cpv orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/cpv'
      }, {
        title: 'Total',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.total,
        propertyName: 'cost',
        className: 'mt-total orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/cost'
      }, {
        title: 'CTR',
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.ctr,
        propertyName: 'ctr',
        className: 'mt-ctr orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/ctr'
      }, {
        title: Ember.String.htmlSafe('View Time<br /><small>(avg.&nbsp;sec./user)</small>'),
        componentForSortCell: 'models-table/sort-cell-with-hint',
        sortCellHint: _hints.default.viewTime,
        propertyName: 'view_time',
        className: 'mt-time orderable',
        disableSorting: true,
        component: 'models-table/pp-advertiser-campaign/cells/view-time'
      }];
    })
  });

  _exports.default = _default;
});