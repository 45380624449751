define("trivver-frontend/adapters/drf", ["exports", "ember-django-adapter/adapters/drf", "trivver-frontend/config/environment"], function (_exports, _drf, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    host: Ember.computed(function () {
      return _environment.default.APP.API_HOST;
    }),
    namespace: Ember.computed(function () {
      return _environment.default.APP.API_NAMESPACE;
    })
  });

  _exports.default = _default;
});