define("trivver-frontend/pods/home/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cookies: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isCookiesAccepted', this.cookies.read('qubit_consent'));
    }
  });

  _exports.default = _default;
});