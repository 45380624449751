define("trivver-frontend/helpers/to-fixed-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      return parseFloat(params[0]).toFixed(2);
    }
  });

  _exports.default = _default;
});