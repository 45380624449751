define("trivver-frontend/pods/components/pp-coupon-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KexmQund",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"coupon-preview__header\"],[8],[0,\"\\n  \"],[1,[28,\"or\",[[23,0,[\"title\"]],\"Coupon Title\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"background-cover-image\",null,[[\"class\",\"url\"],[\"coupon-preview__image\",[23,0,[\"image\"]]]]],false],[0,\"\\n\\n\"],[7,\"input\",true],[10,\"class\",\"form-control coupon-preview__code\"],[11,\"value\",[29,[[23,0,[\"code\"]]]]],[10,\"disabled\",\"disabled\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"coupon-preview__summary\"],[8],[0,\"\\n  \"],[1,[28,\"br\",[[28,\"or\",[[23,0,[\"summary\"]],\"Coupon Summary\"],null]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"coupon-preview__terms\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"href\",\"\"],[10,\"onclick\",\"return false;\"],[8],[0,\"Terms and Conditions\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"coupon-preview__buttons\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-blue\"],[10,\"type\",\"button\"],[8],[0,\"Copy code\"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn\"],[10,\"type\",\"button\"],[8],[0,\"Redeem\"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"btn\"],[10,\"type\",\"button\"],[8],[0,\"Close\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/pp-coupon-preview/template.hbs"
    }
  });

  _exports.default = _default;
});