define("trivver-frontend/pods/price/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    price_3d: (0, _model.attr)('cent'),
    price_coupon: (0, _model.attr)('cent'),
    price_facebook: (0, _model.attr)('cent'),
    price_instagram: (0, _model.attr)('cent'),
    price_purchase: (0, _model.attr)('cent'),
    price_smarttab: (0, _model.attr)('cent'),
    price_twitter: (0, _model.attr)('cent'),
    price_video: (0, _model.attr)('cent'),
    price_viewability: (0, _model.attr)('cent'),
    price_pp_small_2d: (0, _model.attr)('cent'),
    price_pp_full_2d: (0, _model.attr)('cent'),
    price_pp_small_3d: (0, _model.attr)('cent'),
    price_pp_full_3d: (0, _model.attr)('cent'),
    price_pp_ar_link: (0, _model.attr)('cent'),
    price_pp_ar_view: (0, _model.attr)('cent'),
    price_pp_intent_promo: (0, _model.attr)('cent'),
    price_pp_intent_shop: (0, _model.attr)('cent'),
    price_pp_advocacy_share: (0, _model.attr)('cent'),
    price_pp_brand_url: (0, _model.attr)('cent'),
    price_pp_metaverse_visit: (0, _model.attr)('cent')
  });

  _exports.default = _default;
});