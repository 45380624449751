define("trivver-frontend/pods/home/cabinet/developer/games/index/route", ["exports", "trivver-frontend/mixins/routes/developer-only"], function (_exports, _developerOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _developerOnly.default.extend({
    currentUser: Ember.inject.service(),
    queryParams: {
      genres_query: {
        refreshModel: true
      },
      ages_query: {
        refreshModel: true
      },
      platforms_query: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        totals: this.store.queryRecord('games-total', {
          me: true
        }),
        games: this.store.query('game', {
          genres: params.genres_query,
          ages: params.ages_query,
          platforms: params.platforms_query
        }),
        genres: this.store.findAll('genre'),
        platforms: this.store.findAll('platform'),
        ages: this.store.findAll('age')
      });
    }
  });

  _exports.default = _default;
});