define("trivver-frontend/mixins/spinner-loading-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments); // eslint-disable-line


      this.set('actions.loading', this.loading);
      this.set('actions.addBlockedSection', this.addBlockedSection);
    },
    loading: function loading(transition, originRoute) {
      var router = Ember.getOwner(this).lookup('route:application');
      var controller = this.controllerFor(router.controller.currentRouteName);

      try {
        if (originRoute.connections && originRoute.connections.length) {
          // start spinners the first - common one, either for the page,
          // or partial, save for compatibility the second - every
          // from ``blockedParts`` array
          var _iterator = _createForOfIteratorHelper(controller.blockedParts || []),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var id = _step.value;
              controller.spinner.show(id);
            } // close up spinners

          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          transition.promise.finally(function () {
            var _iterator2 = _createForOfIteratorHelper(controller.blockedParts || []),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var id = _step2.value;
                controller.spinner.hide(id);
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }

            controller.set('blockedParts', []);
          });
        } else {
          controller.spinner.show('overall'); // close up spinners

          transition.promise.finally(function () {
            controller.spinner.hide('overall');
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    addBlockedSection: function addBlockedSection(id) {
      var router = Ember.getOwner(this).lookup('route:application');
      var controller = this.controllerFor(router.controller.currentRouteName);
      controller.blockedParts.pushObject(id);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments); // eslint-disable-line


      var controller = this.controllerFor(this.routeName);
      controller.set('blockedParts', []);
      this.set('actions.loading', null);
      this.set('actions.addBlockedSection', null);
    }
  });

  _exports.default = _default;
});