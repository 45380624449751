define("trivver-frontend/helpers/has-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasError = hasError;

  /**
   * Helper for determining if there error occurs in some field
   *
   * @function hasError
   * @param {Array} params
   * @return {boolean} true if field in error list, false otherwise
   * @public
   */
  function hasError(params) {
    var errors = params[0];
    var field = params[1];

    if (errors && errors.validation_errors) {
      return errors.validation_errors.reduce(function (acc, value) {
        return acc || value.field === field;
      }, false);
    }

    return false;
  }

  var _default = Ember.Helper.helper(hasError);

  _exports.default = _default;
});