define("trivver-frontend/pods/pp-campaign/serializer", ["exports", "@ember-data/serializer/rest", "ember-django-adapter/serializers/drf"], function (_exports, _rest, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      assets: {
        embedded: 'always',
        deserialize: 'records',
        serialize: 'records'
      },
      isAgreed: {
        serialize: false
      },
      advertiser: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});