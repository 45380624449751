define("trivver-frontend/pods/components/models-table/sort-cell-with-hint/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fly2rvyg",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"column\",\"title\"]],false],[0,\"\\n\\n\"],[1,[28,\"sup-tooltip\",[[23,0,[\"column\",\"sortCellHint\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"column\",\"useSorting\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"\\n      \",[28,\"if\",[[23,0,[\"column\",\"sortAsc\"]],[23,0,[\"themeInstance\",\"sort-asc\"]]],null],\" \\n      \",[28,\"if\",[[23,0,[\"column\",\"sortDesc\"]],[23,0,[\"themeInstance\",\"sort-desc\"]]],null],\"\\n    \"]]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/sort-cell-with-hint/template.hbs"
    }
  });

  _exports.default = _default;
});