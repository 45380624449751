define("trivver-frontend/pods/components/publisher-tos-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      isAgreedChanged: function isAgreedChanged() {
        this.set('isAgreedObserver', Ember.$('.tos').prop('checked')); // Сan put consent in the checkbox only through the modal window

        if (this.isAgreedObserver) {
          Ember.$('.tos').prop('checked', false);
          this.set('isAgreedObserver', false);
          this.set('tosModalOpen', true);
        }
      },
      openModal: function openModal() {
        this.set('tosModalOpen', true);
        return false;
      },
      acquireBottom: function acquireBottom() {
        this.set('doNotObserve', true);
        this.set('tosEnabled', true);
      }
    }
  });

  _exports.default = _default;
});