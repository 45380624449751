define("trivver-frontend/pods/components/inputs/currency-field/component", ["exports", "inputmask"], function (_exports, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    getValue: Ember.computed('value', {
      // eslint-disable-next-line no-unused-vars
      get: function get(key) {
        return this.value;
      },
      set: function set(key, value) {
        var cleaned = value.split(',').join('');
        this.set('value', cleaned);
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      var name = this.name;
      var element = Ember.$("#id_".concat(name));
      var inputMask = new _inputmask.default('numeric', {
        radixPoint: '.',
        groupSeparator: ',',
        digits: 2,
        autoGroup: true,
        allowDecimal: true,
        requireDecimal: true,
        prefix: '',
        rightAlign: false
      });
      inputMask.mask(element);
    }
  });

  _exports.default = _default;
});