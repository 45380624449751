define("trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/cells/campaign/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    spinner: Ember.inject.service(),
    tagName: 'a',
    classNames: ['w100p', 'btn', 'btn-grey', 'btn-short'],
    attributeBindings: ['href', 'title'],
    title: 'Campaign Dashboard',
    click: function click() {
      this.spinner.show('overall');
      this.router.transitionTo('home.cabinet.advertiser.pp.campaigns.retrieve', this.get('record.id'));
      return false;
    }
  });

  _exports.default = _default;
});