define("trivver-frontend/pods/account/serializer", ["exports", "@ember-data/serializer/rest", "ember-django-adapter/serializers/drf"], function (_exports, _rest, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      fields_needed: {
        embedded: 'always'
      },
      fields_provided: {
        embedded: 'always'
      }
    },
    _makeUnderscored: function _makeUnderscored(obj) {
      var newObj = obj;
      delete newObj.id;
      var objUnderscored = {};

      for (var key in newObj) {
        if (Object.prototype.hasOwnProperty.call(newObj, key)) {
          var keyUnderscored = key.replace(new RegExp('-', 'g'), '_');
          objUnderscored[keyUnderscored] = newObj[key];
        }
      }

      return objUnderscored;
    },
    _buildRelationship: function _buildRelationship(obj, json, modelName) {
      var newJson = json;
      var modelNameUnderscored = modelName.replace(new RegExp('-', 'g'), '_');
      newJson.data.relationships[modelNameUnderscored] = {
        data: {
          type: modelName,
          id: 1
        }
      };
      var data = {
        id: 1,
        type: modelName,
        attributes: obj
      };

      if (newJson.included) {
        newJson.included.push(data);
      } else {
        newJson.included = [data];
      }

      return newJson;
    },

    /**
     * Add fields from "fields_needed" field to JSONAPI standard relationships to achieve embedding
     * of record ("fields_needed" will be considered as model) to make easier computed properties
     * implementation for "account" model.
     */
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var fieldsNeededUnderscored = this._makeUnderscored(payload.fields_needed);

      var fieldsProvidedUnderscored = this._makeUnderscored(payload.fields_provided);

      var json = this._super(store, primaryModelClass, payload, id, requestType);

      json = this._buildRelationship(fieldsNeededUnderscored, json, 'fields_needed');
      json = this._buildRelationship(fieldsProvidedUnderscored, json, 'fields_provided');
      return json;
    }
  });

  _exports.default = _default;
});