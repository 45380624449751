define("trivver-frontend/pods/pp-campaign-custom-color/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/field-validation"], function (_exports, _model, _emberCpValidations, _fieldValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEX_CODE_REGEX = /^#([A-Fa-f0-9]{6})$/;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.nameFlag')
    }),
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })],
      disabled: Ember.computed.not('model.emailFlag')
    },
    color_code: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        regex: HEX_CODE_REGEX,
        message: 'Color code must be in HEX format (e.g. #ffffff)'
      })],
      disabled: Ember.computed.not('model.color_codeFlag')
    }
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    color_code: (0, _model.attr)('string')
  });

  _exports.default = _default;
});