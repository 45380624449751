define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/create/coupons/controller", ["exports", "trivver-frontend/mixins/draft-campaign", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/upload-coupon-files", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/coupon-step-actions"], function (_exports, _draftCampaign, _uploadCouponFiles, _couponStepActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_draftCampaign.default, _uploadCouponFiles.default, _couponStepActions.default);

  _exports.default = _default;
});