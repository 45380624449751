define("trivver-frontend/validators/zipcode-valid", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/messages"], function (_exports, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ZipCodeCheck = _base.default.extend({
    validate: function validate(value) {
      if (!value) return true;
      var regExp = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

      if (value.match(regExp)) {
        return true;
      }

      return _messages.ZIP_CODE_ERROR;
    }
  });

  var _default = ZipCodeCheck;
  _exports.default = _default;
});