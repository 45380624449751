define("trivver-frontend/pods/components/models-table/payment-history/cells/amount/footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    classNames: 'mt-amount',
    value: Ember.computed('parentView.parentView.visibleContent', function () {
      var content = this.get('parentView.parentView.visibleContent');
      return content.reduce(function (a, b) {
        return a + (b.get('amount') || 0);
      }, 0);
    })
  });

  _exports.default = _default;
});