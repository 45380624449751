define("trivver-frontend/pods/pp-coupon/model", ["exports", "@ember-data/model", "ember-cp-validations", "ember-api-actions", "trivver-frontend/mixins/copyable"], function (_exports, _model, _emberCpValidations, _emberApiActions, _copyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 40
    })],
    description: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    code: [(0, _emberCpValidations.validator)('length', {
      max: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[0-9a-zA-Z]+$/i,
      message: 'Only alphanumeric characters: a-z, A-Z, 0-9',
      disabled: Ember.computed.empty('model.code')
    }), (0, _emberCpValidations.validator)('code-existence', {
      disabled: Ember.computed.alias('model.isCouponCodeDisabled')
    })],
    end: [(0, _emberCpValidations.validator)('date', {
      after: 'now',
      precision: 'day',
      disabled: Ember.computed.empty('model.end')
    })],
    terms_and_conditions: [(0, _emberCpValidations.validator)('presence', true)],
    qr_code: [(0, _emberCpValidations.validator)('image-format'), (0, _emberCpValidations.validator)('image-size')]
  }, {
    disabled: Ember.computed.not('model.validationAttempted')
  });

  var _default = _model.default.extend(_copyable.default, Validations, {
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    terms_and_conditions: (0, _model.attr)('string'),
    end: (0, _model.attr)('coupon-date-format'),
    qr_code: (0, _model.attr)(),
    uploadFiles: (0, _emberApiActions.memberAction)({
      path: 'asset-files/',
      type: 'post',
      urlType: 'query'
    }),
    getFilesResult: (0, _emberApiActions.memberAction)({
      path: 'task-status/',
      type: 'get',
      urlType: 'query'
    }),
    checkExistence: (0, _emberApiActions.collectionAction)({
      path: 'check_existence/',
      type: 'get',
      urlType: 'findRecord'
    }),
    isCouponCodeDisabled: Ember.computed('code', 'hasDirtyAttributes', function () {
      var changedAttributes = this.changedAttributes();

      if (changedAttributes.code) {
        var _changedAttributes$co = _slicedToArray(changedAttributes.code, 1),
            originalValue = _changedAttributes$co[0];

        return !!originalValue;
      }

      return !!this.code;
    }),
    minStartDate: Ember.computed(function () {
      return new Date(moment(Date.now()).add(1, 'days'));
    })
  });

  _exports.default = _default;
});