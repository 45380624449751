define("trivver-frontend/pods/tos-document/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    version: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    content: (0, _model.attr)('string'),
    created: (0, _model.attr)('date', {
      defaultValue: function defaultValue() {
        return new Date().toLocaleString();
      }
    }),
    accepted: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    accept: (0, _emberApiActions.memberAction)({
      path: 'accept/',
      type: 'POST'
    })
  });

  _exports.default = _default;
});