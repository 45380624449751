define("trivver-frontend/pods/components/models-table/advertiser-coupons/button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      showProductCouponForm: function showProductCouponForm(product) {
        this.sendAction('showProductCouponForm', product);
      },
      removeProductCoupon: function removeProductCoupon(product) {
        this.sendAction('removeProductCoupon', product);
      }
    }
  });

  _exports.default = _default;
});