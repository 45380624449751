define("trivver-frontend/pods/components/models-table/summary/component", ["exports", "ember-models-table/components/models-table/summary"], function (_exports, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _summary.default.extend({
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.set('themeInstance.messages.tableSummary', 'Showing %@ - %@ of %@');
    }
  });

  _exports.default = _default;
});