define("trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/money/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tHVeavmd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"format-money\",[[23,0,[\"upperPropertyValue\"]]],[[\"symbol\"],[\"$\"]]],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n(\"],[1,[28,\"format-money\",[[23,0,[\"lowerPropertyValue\"]]],[[\"symbol\"],[\"$\"]]],false],[0,\")\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/money/template.hbs"
    }
  });

  _exports.default = _default;
});