define("trivver-frontend/pods/tos-document/serializer", ["exports", "ember-django-adapter/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    primaryKey: 'slug'
  });

  _exports.default = _default;
});