define("trivver-frontend/pods/components/inputs/clearable-file-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      browse: function browse() {
        Ember.$("#id_".concat(this.field)).click();
      },
      changeFile: function changeFile(field, file) {
        this.set("selectedFile", file);
        this.set("fileName", file ? file.name : "");
      },
      cancel: function cancel() {
        this.set("fileName", null);
        this.model.set(this.field, null);
        Ember.$("#id_".concat(this.field)).val("");
      },
      download: function download() {
        var fileName = this.fileName;

        if (fileName === "File uploaded") {
          // Download the uploaded file itself
          window.open(this.model.get("".concat(this.field)));
        } else if (fileName) {
          var uploadedFile = this.selectedFile;

          if (uploadedFile) {
            var url = URL.createObjectURL(uploadedFile);
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", uploadedFile.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    },
    fileName: Ember.computed("model", {
      get: function get() {
        var model = this.model;
        var empty = "";

        if (!model) {
          return empty;
        }

        var filePath = model.get("".concat(this.field));

        if (typeof filePath === "string") {
          return "File uploaded";
        }

        filePath = Ember.$("#id_".concat(this.field)).val();

        if (filePath) {
          return filePath.substring(12);
        }

        return empty;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$("#id_".concat(this.field)).change(function () {
        _this.notifyPropertyChange("fileName");
      });
    }
  });

  _exports.default = _default;
});