define("trivver-frontend/pods/components/partials/pp-website-buttons/component", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    host: _environment.default.APP.PP_HOST
  });

  _exports.default = _default;
});