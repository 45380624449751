define("trivver-frontend/pods/components/modals/yes-no-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lBX47DsO",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[5,\"bs-modal\",[[12,\"class\",[23,0,[\"class\"]]]],[[\"@open\",\"@onHide\",\"@backdropTransitionDuration\",\"@keyboard\",\"@transitionDuration\"],[[23,0,[\"open\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"open\"]]],null]],null],0,false,0]],{\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"campaign-wizard-modal\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"h3\",true],[10,\"class\",\"modal-header__title\"],[8],[1,[28,\"or\",[[23,0,[\"title\"]],\"Confirm, please\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"buttons text-center\"],[8],[0,\"\\n      \"],[5,\"bs-button\",[[12,\"class\",\"btn btn-grey\"]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"or\",[[24,[\"no\"]],\"No\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"button\",true],[11,\"class\",[29,[\"btn btn-\",[28,\"or\",[[23,0,[\"yesColor\"]],\"blue\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"deleteAction\",[24,[\"object\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n        \"],[1,[28,\"or\",[[23,0,[\"yes\"]],\"Yes\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/yes-no-delete/template.hbs"
    }
  });

  _exports.default = _default;
});