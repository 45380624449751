define("trivver-frontend/pods/spot/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    pathForType: function pathForType() {
      return 'spots';
    },
    urlForQuery: function urlForQuery(query) {
      var currentHeaders = this.headers;
      currentHeaders['Game-UID'] = query.game_uid;
      currentHeaders['Game-Version'] = query.version;
      delete query.game_uid; // eslint-disable-line

      delete query.version; // eslint-disable-line

      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/spots/");
    }
  });

  _exports.default = _default;
});