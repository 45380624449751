define("trivver-frontend/pods/components/models-table/funds-earned-daily/cells/sum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vYo/1hSM",
    "block": "{\"symbols\":[],\"statements\":[[0,\"$\"],[1,[23,0,[\"record\",\"sum\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/funds-earned-daily/cells/sum/template.hbs"
    }
  });

  _exports.default = _default;
});