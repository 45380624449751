define("trivver-frontend/pods/state/adapter", ["exports", "trivver-frontend/pods/drf/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    pathForType: function pathForType() {
      return 'auth/states';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.pathForType(), "/");
    }
  });

  _exports.default = _default;
});