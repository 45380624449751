define("trivver-frontend/helpers/domain-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parent;

  function parent(domain) {
    var parts = domain.split('.');

    if (parts.length > 2) {
      return parts.slice(1, parts.length).join('.');
    }

    return parts[0];
  }
});