define("trivver-frontend/services/finish-page-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getFinishPage: function getFinishPage(queryParam) {
      var FINISH_PAGES = {
        dashboard: 'home.cabinet.advertiser'
      };
      return FINISH_PAGES[queryParam] || FINISH_PAGES.dashboard;
    }
  });

  _exports.default = _default;
});