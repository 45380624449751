define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/cost-per-click/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z14eY0vW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"format-money\",[[28,\"cent-to-dollar\",[[23,0,[\"record\",\"cost_per_click\"]]],null]],[[\"symbol\"],[\"$\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/cost-per-click/template.hbs"
    }
  });

  _exports.default = _default;
});