define("trivver-frontend/pods/components/design-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'row design-list',
    classNameBindings: ['disabled:disabled'],
    disabled: false,
    actions: {
      setDesign: function setDesign(design, event) {
        if (event) {
          event.stopImmediatePropagation();
        }

        this.sendAction('setDesign', design);
      }
    }
  });

  _exports.default = _default;
});