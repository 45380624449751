define("trivver-frontend/pods/asset-3d-model/serializer", ["exports", "ember-django-adapter/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      // customize how DS.attr - add to json filed
      // if record create or changes this field
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    }
  });

  _exports.default = _default;
});