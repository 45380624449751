define("trivver-frontend/pods/home/cabinet/advertiser/campaigns/retrieve/hints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    tViews: 'A “T-View” is recorded when a user has viewed a 3D product model (BSO) where the viewability was at least 5% for 5 seconds. Viewability is analogous to the percent of the screen that is covered by the object. Only one T-View per BSO will be counted per each user/session. A new session will begin when the user exits and restarts the 3D application.',
    clicks: 'The number of times each button related to this campaign (product) was clicked at least once. Only one click for each click type (e.g. 2D, 3D, etc.) is recorded for a 24 hour session.',
    smarttabClicks: 'Combined clicks for all click types.',
    clicksSpend: 'The amount charged against your campaign account for clicks. Equal to the cost per click for each click type multiplied by the count of each click type.',
    costPerTView: 'Set cost for each recorded T-view.',
    tViewSpend: ' The amount charged against your campaign account for T-Views. Equal to the cost per T-view multiplied by the count of T-views.'
  };
  _exports.default = _default;
});