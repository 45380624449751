define("trivver-frontend/pods/home/cabinet/payment-history/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    detailsModalIsOpen: false,
    start: null,
    end: null,
    data: Ember.computed('model', 'start', 'end', function () {
      var start = this.start ? moment(this.start) : null;
      var end = this.end ? moment(this.end) : null;
      return this.get('model.history').filter(function (item) {
        // need to convert to date format for comparsion without time
        var date = moment(item.get('transaction_date').toLocaleDateString());

        if (!start && !end // just return an item if daterange isn't provided
        || start && end && start <= date && date <= end) {
          return item;
        }

        return false;
      });
    }),
    actions: {
      showDetails: function showDetails(record) {
        this.set('payment', record);
        this.set('detailsModalIsOpen', true);
      },
      clearAction: function clearAction() {
        this.set('start', '');
        this.set('end', '');
      }
    }
  });

  _exports.default = _default;
});