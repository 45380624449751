define("trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/t-views/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mqH2J15j",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"record\",\"t_view_total_count\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/t-views/template.hbs"
    }
  });

  _exports.default = _default;
});