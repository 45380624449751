define("trivver-frontend/pods/components/pagination-/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    page: 1,
    actions: {
      previous: function previous() {
        this.sendAction('onClick');
        this.set('page', this.page - 1);
      },
      next: function next() {
        this.sendAction('onClick');
        this.set('page', this.page + 1);
      },
      setPage: function setPage(value) {
        this.sendAction('onClick');
        this.set('page', value);
      }
    }
  });

  _exports.default = _default;
});