define("trivver-frontend/pods/components/models-table/advertiser-coupons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QmPf7/tS",
    "block": "{\"symbols\":[\"mt\",\"ft\",\"pn\",\"table\"],\"statements\":[[5,\"models-table\",[],[[\"@data\",\"@columns\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@pageSize\",\"@showPageSize\",\"@showComponentHeader\",\"@themeInstance\",\"@showProductCouponForm\",\"@removeProductCoupon\",\"@multipleSelect\"],[[23,0,[\"data\"]],[23,0,[\"columns\"]],false,false,10,false,false,[23,0,[\"themeInstance\"]],\"showProductCouponForm\",\"removeProductCoupon\",false]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"table\"]],[[12,\"class\",\"advertiser-coupons\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[23,4,[\"body\"]],false],[0,\"\\n  \"]],\"parameters\":[4]}],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gt\",[[23,0,[\"data\",\"length\"]],[23,0,[\"pageSize\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"summary\"]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-1 col-sm-1 col-xs-1\"],[8],[9],[0,\"\\n\\n      \"],[6,[23,2,[\"pagination-numeric\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"models-table/numeric-pagination\",null,[[\"class\",\"visiblePageNumbers\",\"gotoCustomPage\"],[\"pull-right\",[23,3,[\"visiblePageNumbers\"]],[23,3,[\"gotoCustomPage\"]]]]],false],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-coupons/template.hbs"
    }
  });

  _exports.default = _default;
});