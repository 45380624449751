define("trivver-frontend/pods/components/tos-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    user: true,
    actions: {
      isAgreedChanged: function isAgreedChanged() {
        this.set('isAgreedObserver', Ember.$('.tos').prop('checked'));

        if (this.get('currentUser.user.hasCampaigns') && this.user) {
          this.set('tosEnabled', true);
          return true;
        } // Сan put consent in the checkbox only through the modal window


        if (this.isAgreedObserver) {
          Ember.$('.tos').prop('checked', false);
          this.set('isAgreedObserver', false);
          this.set('tosModalOpen', true);
        }

        return false;
      },
      openModal: function openModal() {
        this.set('isAgreedObserver', Ember.$('.tos').prop('checked'));
        this.set('tosModalOpen', true);
        return false;
      },
      acquireBottom: function acquireBottom() {
        this.set('doNotObserve', true);
        this.set('tosEnabled', true);
      }
    }
  });

  _exports.default = _default;
});