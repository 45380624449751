define("trivver-frontend/pods/components/error-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oIaFZjkj",
    "block": "{\"symbols\":[\"error\",\"error\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"errors\",\"validation_errors\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"field\"]],[23,0,[\"field\"]]],null]],null,{\"statements\":[[4,\"each\",[[23,1,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[10,\"role\",\"alert\"],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[1,[28,\"capitalize\",[[23,0,[\"field\"]]],null],false],[0,\":\"],[9],[0,\" \"],[1,[23,2,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/error-alert/template.hbs"
    }
  });

  _exports.default = _default;
});