define("trivver-frontend/pods/components/copyable-content/component", ["exports", "trivver-frontend/constants/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    open: false,
    buttonText: 'Open',
    copyButtonText: 'Copy',
    defaultButtonClass: 'btn btn-blue',
    select: function select() {
      Ember.$('.copyable textarea').focus().select();
    },
    actions: {
      openModal: function openModal() {
        this.set('open', true);
      },
      select: function select() {
        this.select();
      },
      copy: function copy() {
        var that = this;
        navigator.clipboard.writeText(Ember.$('.copyable textarea').text()).then(function () {
          that.select();
          that.get('notify').success(_messages.COPIED_TO_THE_CLIPBOARD);
        });
      }
    }
  });

  _exports.default = _default;
});