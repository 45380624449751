define("trivver-frontend/pods/fields-needed/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    fields_needed: (0, _model.attr)('boolean'),
    tos_accepted: (0, _model.attr)('boolean'),
    first_name: (0, _model.attr)('boolean'),
    last_name: (0, _model.attr)('boolean'),
    day_of_birth: (0, _model.attr)('boolean'),
    address_line1: (0, _model.attr)('boolean'),
    address_line2: (0, _model.attr)('boolean'),
    city: (0, _model.attr)('boolean'),
    state: (0, _model.attr)('boolean'),
    zip: (0, _model.attr)('boolean'),
    account_type: (0, _model.attr)('boolean'),
    ssn_last_4: (0, _model.attr)('boolean'),
    business_tax_id: (0, _model.attr)('boolean'),
    business_name: (0, _model.attr)('boolean'),
    personal_id_number: (0, _model.attr)('boolean'),
    verification_document: (0, _model.attr)('boolean'),
    generalInfoNeeded: Ember.computed.or('tos_accepted', 'address_line1', 'address_line2', 'city', 'state', 'zip', 'account_type'),
    generalInfoNotNeeded: Ember.computed.not('generalInfoNeeded'),
    companyRepresentativeInfoNeeded: Ember.computed.or('day_of_birth', 'first_name', 'last_name', 'verification_document', 'business_tax_id', 'business_name', 'ssn_last_4', 'personal_id_number'),
    companyRepresentativeInfoNotNeeded: Ember.computed.not('companyRepresentativeInfoNeeded'),
    isNoFieldsNeeded: Ember.computed.not('fields_needed'),
    isFirstNameNotNeeded: Ember.computed.not('first_name'),
    isLastNameNotNeeded: Ember.computed.not('last_name'),
    isDayOfBirthNotNeeded: Ember.computed.not('day_of_birth'),
    isAddressLine1NotNeeded: Ember.computed.not('address_line1'),
    isAddressLine2NotNeeded: Ember.computed.not('address_line2'),
    isCityNotNeeded: Ember.computed.not('city'),
    isStateNotNeeded: Ember.computed.not('state'),
    isZipNotNeeded: Ember.computed.not('zip'),
    isAccountTypeNotNeeded: Ember.computed.not('account_type'),
    isSSNLast4NotNeeded: Ember.computed.not('ssn_last_4'),
    isBusinessTaxIDNotNeeded: Ember.computed.not('business_tax_id'),
    isBusinessNameNotNeeded: Ember.computed.not('business_name'),
    isPersonalIDNumberNotNeeded: Ember.computed.not('personal_id_number')
  });

  _exports.default = _default;
});