define("trivver-frontend/pods/components/interactive/uploaders/asset-tbso-file/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    config: Ember.inject.service(),
    actions: {
      removeTBSOFile: function removeTBSOFile() {
        this.set('model.asset.file_preview', null);
        this.set('model.files.file_tbso', null);
      },
      changeFile: function changeFile(field, file) {
        if (!(file.name.split('.').pop().toLowerCase() === 'tbso')) {
          this.notify.alert('Only *.tbso files are allowed');
          return;
        }

        this.set("model.files.".concat(field), file);
      }
    }
  });

  _exports.default = _default;
});