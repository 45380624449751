define("trivver-frontend/pods/pp-coupon/adapter", ["exports", "ember-cli-form-data/mixins/form-data-adapter", "trivver-frontend/pods/drf/adapter"], function (_exports, _formDataAdapter, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_formDataAdapter.default, {
    pathForType: function pathForType() {
      return 'coupon-settings';
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.createRecord(store, type, snapshot);
    },
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/").concat(this.namespace, "/");
    }
  });

  _exports.default = _default;
});