define("trivver-frontend/pods/components/products-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9X0ryBau",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"products-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"products-list__item\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"class\",\"products-list__preview\"],[11,\"src\",[29,[[23,1,[\"fileIconPreview\"]]]]],[8],[9],[0,\"\\n\\n      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"products-list__title\"],[3,\"action\",[[23,0,[]],\"showProductForm\",[23,1,[]]],[[\"on\"],[\"click\"]]],[8],[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/products-list/template.hbs"
    }
  });

  _exports.default = _default;
});