define("trivver-frontend/pods/home/cabinet/developer/funds-earned-details/route", ["exports", "trivver-frontend/mixins/routes/developer-only"], function (_exports, _developerOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _developerOnly.default.extend({
    store: Ember.inject.service(),
    start: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    model: function model() {
      return Ember.RSVP.hash({
        earnings: this.store.query('earnings-daily', {
          start: this.start,
          end: this.end
        }),
        balance: this.store.queryRecord('retrieve-balance', {
          developer: this.get('currentUser.user.org_id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'start', this.start);
      Ember.set(controller, 'end', this.end);
    }
  });

  _exports.default = _default;
});