define("trivver-frontend/pods/components/models-table/advertiser-campaigns/cells/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q7Jhy6QT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"title\",\"class\",\"route\",\"model\"],[[23,0,[\"record\",\"title\"]],\"nowrap\",\"home.cabinet.advertiser.campaigns.retrieve\",[23,0,[\"record\",\"id\"]]]],{\"statements\":[[1,[23,0,[\"record\",\"title\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-campaigns/cells/title/template.hbs"
    }
  });

  _exports.default = _default;
});