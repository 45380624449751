define("trivver-frontend/pods/home/cabinet/advertiser/campaigns/update/smart-tab/controller", ["exports", "trivver-frontend/mixins/draft-campaign"], function (_exports, _draftCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_draftCampaign.default, {
    actions: {
      cancelChanges: function cancelChanges(model) {
        model.campaign.get('asset').content.rollbackAttributes();
        this.transitionToRoute('home.cabinet.advertiser.campaigns.update.review', model.campaign.id);
      }
    }
  });

  _exports.default = _default;
});