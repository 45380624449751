define("trivver-frontend/pods/components/inputs/interactive-file-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o0wjLXhJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"inputs/file-input\",null,[[\"field\",\"value\",\"changeFile\",\"accept\"],[[23,0,[\"field\"]],[23,0,[\"value\"]],[23,0,[\"changeFile\"]],[23,0,[\"accept\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/interactive-file-input/template.hbs"
    }
  });

  _exports.default = _default;
});