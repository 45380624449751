define("trivver-frontend/pods/components/models-table/advertiser-campaigns/cells/spend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQ3MHnQE",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"format-money\",[[28,\"currency-converter\",[[23,0,[\"record\",\"total_cost\"]]],null]],[[\"symbol\"],[\"$\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-campaigns/cells/spend/template.hbs"
    }
  });

  _exports.default = _default;
});