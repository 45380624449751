define("trivver-frontend/constants/allowed-formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VIDEO_FORMATS = _exports.TBSO_FILE_FORMATS = _exports.IMAGE_FORMATS = void 0;
  var IMAGE_FORMATS = ['jpg', 'png', 'jpeg', 'image/jpeg'];
  _exports.IMAGE_FORMATS = IMAGE_FORMATS;
  var TBSO_FILE_FORMATS = ['tbso'];
  _exports.TBSO_FILE_FORMATS = TBSO_FILE_FORMATS;
  var VIDEO_FORMATS = ['mp4'];
  _exports.VIDEO_FORMATS = VIDEO_FORMATS;
});