define("trivver-frontend/pods/home/user-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "csTgnM/3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-user-type\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-header\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"Select User Type\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Welcome to Trivver! Choose any option from below to get started.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"user-row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"panel-title\"],[8],[0,\"Are You a Content Publisher?\"],[9],[0,\"\\n          \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-blue\",\"home.register-dev\"]],{\"statements\":[[0,\"Continue\"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-divider\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"or\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"panel-title\"],[8],[0,\"Are You an Advertiser?\"],[9],[0,\"\\n          \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-blue\",\"home.register-adv\"]],{\"statements\":[[0,\"Continue\"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/user-types/template.hbs"
    }
  });

  _exports.default = _default;
});