define("trivver-frontend/pods/components/funds-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chart: null,
    didInsertElement: function didInsertElement() {
      this._drawChart();
    },
    didUpdate: function didUpdate() {
      this.chart.destroy();

      this._drawChart();
    },
    _initialize: function _initialize() {
      var rawData = this.data; // Parse start and end dates

      var start = new Date(Date.parse(this.start));
      var end = new Date(Date.parse(this.end));
      var labels = [];
      var data = []; // Avoid memory leak

      if (end.getFullYear() - start.getFullYear() > 10) {
        return {
          labels: labels,
          data: data
        };
      } // Populate labels and data


      var _loop = function _loop(i) {
        // If start and end dates from different years need to populate range to end or from start of
        // the year
        var startMonth = i === start.getFullYear() ? start.getMonth() + 1 : 1;
        var endMonth = i === end.getFullYear() ? end.getMonth() + 1 : 12;

        var _loop2 = function _loop2(j) {
          labels.push("".concat(moment.monthsShort(j - 1), "/").concat(i));
          var monthObj = rawData.filter(function (value) {
            return value.get('month') === j && value.get('year') === i;
          })[0];
          data.push(monthObj ? monthObj.get('amount') / 100 : 0);
        };

        for (var j = startMonth; j <= endMonth; j += 1) {
          _loop2(j);
        }
      };

      for (var i = start.getFullYear(); i <= end.getFullYear(); i += 1) {
        _loop(i);
      }

      return {
        labels: labels,
        data: data
      };
    },
    _drawChart: function _drawChart() {
      var data = this._initialize(); // Default scale


      var scale = 5;

      for (var i = 0; i < data.data.length; i += 1) {
        scale = Math.max(scale, data.data[i]);
      }

      var ctx = document.getElementById('chart-dashboard');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.labels,
          datasets: [{
            data: data.data,
            backgroundColor: '#ccc',
            borderColor: '#797979',
            borderWidth: 1
          }]
        },
        options: {
          animation: {
            onProgress: this._drawBarValues,
            onComplete: this._drawBarValues
          },
          tooltips: {
            enabled: false
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMax: scale * 1.1,
                beginAtZero: true,
                callback: function callback(value) {
                  var formattedValue = value; // if is float -- format to two decimal places

                  if (!Number.isNaN(value) && value % 1 !== 0) {
                    formattedValue = parseFloat(value).toFixed(2);
                  }

                  return "$".concat(formattedValue);
                }
              }
            }]
          }
        }
      });
    },
    _drawBarValues: function _drawBarValues() {
      var ctx = this.chart.ctx;
      ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
      ctx.fillStyle = '#26A2D7';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      this.data.datasets.forEach(function (dataset) {
        for (var i = 0; i < dataset.data.length; i += 1) {
          if (dataset.hidden === true && dataset._meta[Object.keys(dataset._meta)[0]].hidden !== false) {
            /* eslint no-continue: 0 */
            continue;
          }

          var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;

          if (dataset.data[i] !== null) {
            ctx.fillText("$".concat(dataset.data[i]), model.x - 1, model.y - 1);
          }
        }
      });
    }
  });

  _exports.default = _default;
});