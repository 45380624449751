define("trivver-frontend/pods/asset-files/adapter", ["exports", "ember-cli-form-data/mixins/form-data-adapter", "trivver-frontend/config/environment", "trivver-frontend/pods/application/adapter"], function (_exports, _formDataAdapter, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_formDataAdapter.default, {
    pathForType: function pathForType() {
      return 'assets/';
    },
    urlForCreateRecord: function urlForCreateRecord(model, snapshot) {
      var assetId = snapshot.record.get('asset.id');
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(this.namespace, "/assets/").concat(assetId, "/");
    }
  });

  _exports.default = _default;
});