define("trivver-frontend/pods/components/date-range-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/MKvpwO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"hasBlock\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[11,\"class\",[23,0,[\"labelClass\"]]],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"placeholder\",\"editable\"],[[23,0,[\"rangeText\"]],[23,0,[\"inputClasses\"]],[23,0,[\"placeholder\"]],false]]],false],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-calendar calendar__icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/date-range-picker/template.hbs"
    }
  });

  _exports.default = _default;
});