define("trivver-frontend/pods/home/cabinet/developer/index/route", ["exports", "moment", "trivver-frontend/mixins/routes/developer-only"], function (_exports, _moment, _developerOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _developerOnly.default.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    start: (0, _moment.default)().subtract(6, 'months').format('YYYY-MM-DD'),
    end: (0, _moment.default)().format('YYYY-MM-DD'),
    notify: Ember.inject.service(),
    queryParams: {
      genres_query: {
        refreshModel: true
      },
      ages_query: {
        refreshModel: true
      },
      platforms_query: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var gamesParams = {
        genres: params.genres_query,
        ages: params.ages_query,
        platforms: params.platforms_query,
        active: true
      };
      return Ember.RSVP.hash({
        account: this.get('currentUser.paymentAccount'),
        funds: this.store.query('earnings-monthly', {
          start: this.start,
          end: this.end
        }),
        games: this.store.query('game', gamesParams),
        genres: this.store.findAll('genre'),
        platforms: this.store.findAll('platform'),
        totals: this.store.queryRecord('games-total', {
          active: true
        }),
        ages: this.store.findAll('age'),
        balance: this.store.queryRecord('retrieve-balance', {
          developer: this.get('currentUser.user.org_id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'start', this.start);
      Ember.set(controller, 'end', this.end);
    }
  });

  _exports.default = _default;
});