define("trivver-frontend/pods/components/models-table/developer-games/cells/see-in-3d-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3vqaojfu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n  \"],[1,[23,0,[\"record\",\"action_3d_count\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n  (\"],[1,[28,\"format-money\",[[28,\"currency-converter\",[[23,0,[\"record\",\"action_3d_cost\"]]],null]],[[\"symbol\"],[\"$\"]]],false],[0,\")\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/developer-games/cells/see-in-3d-count/template.hbs"
    }
  });

  _exports.default = _default;
});