define("trivver-frontend/helpers/extract-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(url) {
      var hostname;

      if (url[0].indexOf('//') > -1) {
        hostname = url[0].split('/')[2];
      } else {
        hostname = url[0].split('/')[0];
      }

      hostname = hostname.split('?')[0];
      return hostname;
    }
  });

  _exports.default = _default;
});