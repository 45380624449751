define("trivver-frontend/pods/components/funds-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0i7JKk8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"block-chart\"],[8],[0,\"\\n  \"],[7,\"canvas\",true],[10,\"id\",\"chart-dashboard\"],[10,\"width\",\"100\"],[10,\"height\",\"120\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/funds-chart/template.hbs"
    }
  });

  _exports.default = _default;
});