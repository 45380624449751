define("trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/cells/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WvfpcKMS",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"record\",\"first_name\"]],false],[0,\" \"],[1,[23,0,[\"record\",\"last_name\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/cells/name/template.hbs"
    }
  });

  _exports.default = _default;
});