define("trivver-frontend/constants/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ErrorTypes = void 0;

  /**
   * Object of errors are used throughout all project
   *
   * @field INTERNET_CONNECTION
   */
  var ErrorTypes = {
    INTERNET_CONNECTION: 'Something went wrong. Check your internet connection.'
  };
  _exports.ErrorTypes = ErrorTypes;
  var _default = 'ErrorTypes';
  _exports.default = _default;
});