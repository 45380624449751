define("trivver-frontend/pods/components/modals/cropper-avatar/component", ["exports", "ember-cli-image-cropper/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint no-plusplus: [2, { allowForLoopAfterthoughts: true }] */
  var _default = _imageCropper.default.extend({
    aspectRatio: 1,
    minCropBoxWidth: 100,
    minCropBoxHeight: 100,
    cropperContainer: '.cropper-container > img',
    previewClass: '.img-preview',
    croppedAvatar: null,
    minContainerWidth: 200,
    minContainerHeight: 200,
    checkOrientation: true,
    build: true,
    viewMode: 1,
    rotatable: true,
    tagName: null,
    cropperShow: null,
    checkCrossOrigin: false,

    /**
     * Convert Data URI to a Blob
     *
     * Must convert the data to a file for upload to the server
     *
     * @method dataURItoBlob
     * @return { Blob }
     * @public
     */
    dataURItoBlob: function dataURItoBlob(dataURI) {
      // Decodes of dataUrl from base64
      var binary = atob(dataURI.split(',')[1]);
      var array = []; // Transform to Unicode

      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      return new Blob([new Uint8Array(array)], {
        type: 'image/jpeg'
      });
    },
    actions: {
      getCroppedAvatar: function getCroppedAvatar() {
        var container = this.$(this.cropperContainer);
        var croppedImage = container.cropper('getCroppedCanvas');
        var croppedAvatarImage = croppedImage.toDataURL('image/jpeg', 1.0);
        this.set('croppedAvatar', croppedAvatarImage);
      },
      saveCropToAvatar: function saveCropToAvatar() {
        var fileBlob = this.dataURItoBlob(this.croppedAvatar);
        this.set('model.avatar', fileBlob);
        this.set('croppShow', false);
      }
    }
  });

  _exports.default = _default;
});