define("trivver-frontend/app", ["exports", "trivver-frontend/resolver", "trivver-frontend/config/environment", "ember-load-initializers"], function (_exports, _resolver, _environment, _emberLoadInitializers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  fetch('/config.json').then(function (response) {
    return response.json();
  }).then(function (data) {
    // eslint-disable-next-line no-console
    console.log('TEST: ', data);

    var newAPP = _objectSpread(_objectSpread({}, _environment.default.APP), data);

    Ember.set(_environment.default, 'APP', newAPP); // eslint-disable-next-line no-console

    console.log('ENV: ', _environment.default);
  }); // eslint-disable-next-line no-undef

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  Ember.Debug.registerDeprecationHandler(function (message, options, next) {
    if (_environment.default.APP.SHOW_DEPRECATION_LOGS) {
      next(message, options);
    }
  });
  Ember.Debug.registerWarnHandler(function (message, options, next) {
    if (_environment.default.APP.SHOW_WARNING_LOGS) {
      next(message, options);
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});