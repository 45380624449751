define("trivver-frontend/pods/components/link-to-with-spinner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    spinner: Ember.inject.service(),
    tagName: 'a',
    attributeBindings: ['href', 'title', 'classNames'],
    click: function click() {
      this.spinner.show('overall');
      this.router.transitionTo(this.route, this.id);
      return false;
    }
  });

  _exports.default = _default;
});