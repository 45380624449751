define("trivver-frontend/pods/components/product-portal-preview/icon-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['style', 'href'],
    classNameBindings: ['disabled:disabled:enabled'],
    href: '',
    disabled: false,
    style: Ember.computed('backgroundImageUrl', 'backgroundColor', 'borderColor', function () {
      var url = this.backgroundImageUrl;
      var backgroundColor = this.backgroundColor;
      var borderColor = this.borderColor;
      var style = '';

      if (url) {
        style = "background-image:url(".concat(url, ");");
      }

      if (backgroundColor) {
        style = "".concat(style, "background-color:").concat(backgroundColor, ";");
      }

      if (borderColor) {
        style = "".concat(style, "border-color:").concat(borderColor, ";");
      }

      return style;
    }),
    click: function click() {
      this.onClick();
      return false;
    }
  });

  _exports.default = _default;
});