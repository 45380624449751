define("trivver-frontend/pods/components/models-table/advertiser-products-min/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageSize: 10,
    columns: Ember.computed(function () {
      return [{
        component: 'models-table/advertiser-products-min/product-image',
        className: 'preview'
      }, {
        component: 'models-table/advertiser-products-min/product-title',
        className: 'title'
      }, {
        component: 'models-table/advertiser-products-min/product-radio-button',
        className: 'radio-button'
      }];
    })
  });

  _exports.default = _default;
});