define("trivver-frontend/pods/advertiser/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    }), (0, _emberCpValidations.validator)('unique-email')],
    company: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    phone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('phone-number', {
      isValidField: 'phoneIsValid'
    })],
    password1: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d).*$/,
      message: _messages.PASSWORD_VALIDATOR_DIGIT
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[a-z]).*/,
      message: _messages.PASSWORD_VALIDATOR_LOWER_CASE
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[A-Z]).*/,
      message: _messages.PASSWORD_VALIDATOR_UPPER_CASE
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*?[!@#$%^&-])/,
      message: _messages.PASSWORD_VALIDATOR_SPECIAL_CHAR
    })],
    password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password1',
      message: _messages.PASSWORD_CONFIRMATION
    })],
    is_agreed: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    // is_allow_sms: [
    //   validator('presence', {
    //     presence: true,
    //     message: REQUIRED,
    //   }),
    //   validator('allow-sms'),
    // ],
    country: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('country-support')],
    zip_code: [(0, _emberCpValidations.validator)('zipcode-valid')]
  });

  var _default = _model.default.extend(Validations, {
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    password1: (0, _model.attr)('string'),
    password2: (0, _model.attr)('string'),
    company: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    country: (0, _model.belongsTo)('country'),
    is_agreed: (0, _model.attr)('boolean'),
    // is_allow_sms: attr('boolean'),
    type: (0, _model.attr)('string', {
      defaultValue: 'AD'
    }),
    state: (0, _model.belongsTo)('state'),
    city: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    zip_code: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    address: (0, _model.attr)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});