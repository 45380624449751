define("trivver-frontend/pods/components/models-table/checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['checked', 'type', 'disabled', 'title'],
    tagName: 'input',
    type: 'checkbox',
    checked: false,
    disabled: false,
    click: function click(event) {
      this.set('checked', event.target.checked);
      event.stopPropagation();
    },
    didInsertElement: function didInsertElement() {
      if (this.get('record.is_have_campaign')) {
        this.set('disabled', true);
        this.set('title', 'You can not delete the Product, see details.');
      }
    }
  });

  _exports.default = _default;
});