define("trivver-frontend/pods/components/vimeo-player/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @param options - video player options
   * @param player - current player instance
   * @param error - error when player loads or not
   */
  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['embed-player-container'],
    classNameBindings: ['playerReady:ready:loading'],
    options: {
      muted: true,
      byline: false,
      loop: true,
      title: false
    },
    player: null,
    playerReady: false,
    error: false,

    /**
     * Update player, if ``name`` of ``url`` changed
     */
    observer: Ember.observer('url', 'name', function () {
      // remove old player if exists
      if (this.player) {
        this.player.destroy();
        this.set('playerReady', false);
      }

      this.initializePlayer();
    }),

    /**
     * Initialize player instance
     */
    initializePlayer: function initializePlayer() {
      var _this = this;

      var notCachedUrl = "".concat(this.url, "?r=").concat(Date.now()); // eslint-disable-next-line no-undef

      var player = new Vimeo.Player(this.name, _objectSpread({
        url: notCachedUrl
      }, this.options));
      this.set('player', player);
      player.on('loaded', function () {
        _this.set('playerReady', true);
      });
      player.ready().then(function () {
        _this.set('error', false);
      }).catch(function () {
        _this.set('error', true);
      });
    },

    /**
     * initialize player, when component inserted
     */
    didInsertElement: function didInsertElement() {
      this.initializePlayer();
    },

    /**
     * remove player, when component destroyed
     */
    willDestroyElement: function willDestroyElement() {
      if (this.player) {
        this.player.destroy();
      }
    }
  });

  _exports.default = _default;
});