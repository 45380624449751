define("trivver-frontend/pods/components/models-table/advertiser-products-min/product-title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['title'],
    title: Ember.computed.alias('record.title')
  });

  _exports.default = _default;
});