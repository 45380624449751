define("trivver-frontend/pods/components/inputs/bs-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "587XiIj/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group input-file\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"class\",\"disabled\",\"value\"],[\"form-control\",true,[23,0,[\"fileName\"]]]]],false],[0,\"\\n\\n  \"],[7,\"input\",true],[11,\"id\",[28,\"concat\",[\"id_\",[23,0,[\"field\"]]],null]],[11,\"name\",[23,0,[\"field\"]]],[11,\"value\",[23,0,[\"value\"]]],[11,\"accept\",[23,0,[\"accept\"]]],[10,\"class\",\"hidden\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"changeFile\",[23,0,[\"field\"]]],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@type\",\"@onClick\"],[\"btn btn-blue btn-blue-browse\",[28,\"action\",[[23,0,[]],\"browse\"],null]]],{\"statements\":[[0,\"\\n      Browse\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/bs-file/template.hbs"
    }
  });

  _exports.default = _default;
});