define("trivver-frontend/pods/components/add-campaign/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      getStarted: function getStarted() {
        if (this.isCampaignDraftExists) {
          this.set('showRequestDesignerModal', false);
          this.set('showCreateOrContinueModal', true);
        } else {
          this.sendAction('transitionToCreate');
        }
      },
      removeDraftAndContinue: function removeDraftAndContinue() {
        this.sendAction('removeDraftAndContinue');
        this.set('showCreateOrContinueModal', false);
      },
      continueWithoutRemoveDraft: function continueWithoutRemoveDraft() {
        this.set('showCreateOrContinueModal', false);
        this.sendAction('transitionToCreate');
      },
      addCampaign: function addCampaign() {
        // If user already has Objects, don't show modal with request for designer
        if (this.hasObjects) {
          this.send('getStarted');
        } else {
          this.set('showRequestDesignerModal', true);
        }
      }
    }
  });

  _exports.default = _default;
});