define("trivver-frontend/constants/ages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIN_AGE = void 0;
  var MIN_AGE = 18;
  _exports.MIN_AGE = MIN_AGE;
  var _default = 'MIN_AGE';
  _exports.default = _default;
});