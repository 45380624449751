define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/create/review/controller", ["exports", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/color-settings", "trivver-frontend/mixins/draft-campaign", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/existing-product-form", "trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/mixins/product-form"], function (_exports, _colorSettings, _draftCampaign, _existingProductForm, _productForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_draftCampaign.default, _productForm.default, _existingProductForm.default, _colorSettings.default, {
    router: Ember.inject.service("-routing"),
    config: Ember.inject.service(),
    notify: Ember.inject.service(),
    campaignUrl: Ember.computed("model.campaign", function () {
      return document.location.host;
    })
  });

  _exports.default = _default;
});