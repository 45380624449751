define("trivver-frontend/pods/components/add-campaign/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uaKiMDTe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-blue\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"addCampaign\"]],[8],[0,\"Add Campaign\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"modals/request-designer\",null,[[\"open\",\"getStartedAction\"],[[23,0,[\"showRequestDesignerModal\"]],[28,\"action\",[[23,0,[]],\"getStarted\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"modals/create-or-continue\",null,[[\"open\",\"removeDraftAction\",\"continueAction\"],[[23,0,[\"showCreateOrContinueModal\"]],[28,\"action\",[[23,0,[]],\"removeDraftAndContinue\"],null],[28,\"action\",[[23,0,[]],\"continueWithoutRemoveDraft\"],null]]]],false],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/add-campaign/template.hbs"
    }
  });

  _exports.default = _default;
});