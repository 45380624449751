define("trivver-frontend/pods/components/file-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeFile: function changeFile(field, event) {
        this.sendAction('changeFile', field, event.target.files[0]);
      }
    }
  });

  _exports.default = _default;
});