define("trivver-frontend/pods/components/inputs/custom-file-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMI0Xy3Q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group input-file\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"class\",\"disabled\",\"value\"],[\"form-control\",true,[23,0,[\"fileName\"]]]]],false],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[[12,\"disabled\",[23,0,[\"disabled\"]]]],[[\"@type\",\"@onClick\"],[\"btn btn-blue btn-blue-browse\",[28,\"action\",[[23,0,[]],\"browse\"],null]]],{\"statements\":[[0,\"\\n      Browse\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"inputs/interactive-file-input\",null,[[\"field\",\"changeFile\",\"accept\",\"class\",\"model\"],[[23,0,[\"field\"]],\"changeFile\",[23,0,[\"accept\"]],\"hidden\",[23,0,[\"model\"]]]]],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/custom-file-input/template.hbs"
    }
  });

  _exports.default = _default;
});