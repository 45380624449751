define("trivver-frontend/pods/components/models-table/advertiser-products/product-title-min/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'title'],
    classNames: ['dotted'],
    href: '',
    title: Ember.computed.alias('record.title'),
    click: function click(e) {
      this.sendAction('showProductForm', this.record);
      e.preventDefault();
    }
  });

  _exports.default = _default;
});