define("trivver-frontend/pods/card/model", ["exports", "@ember-data/model", "ember-api-actions", "ember-cp-validations"], function (_exports, _model, _emberApiActions, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('card-number')],
    verification_code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 8,
      min: 6,
      message: 'Code must be from six to eight symbols'
    })],
    cvc: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('card-cvc')],
    expirity: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.exp_month', 'model.exp_year']
    }), (0, _emberCpValidations.validator)('card-expirity'), (0, _emberCpValidations.validator)('length', {
      is: 7,
      message: 'Wrong date format'
    })]
  });

  var _default = _model.default.extend(Validations, {
    stripe_id: (0, _model.attr)('string'),
    brand: (0, _model.attr)('string'),
    last4: (0, _model.attr)('number'),
    number: (0, _model.attr)('number'),
    cvc: (0, _model.attr)('number'),
    exp_month: (0, _model.attr)('number'),
    exp_year: (0, _model.attr)('number'),
    currency: (0, _model.attr)('string', {
      defaultValue: 'usd'
    }),
    default: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    source: (0, _model.attr)('string'),
    verification_code: (0, _model.attr)('number'),
    logoImg: Ember.computed('brand', function () {
      var brand = this.brand;

      switch (brand) {
        case 'Visa':
          return '/img/general/cards/visa.png';

        case 'MasterCard':
          return '/img/general/cards/mc.png';

        case 'American Express':
          return '/img/general/cards/ae.png';

        default:
          return '/img/general/cards/default.png';
      }
    }),
    expirity: Ember.computed('exp_month', 'exp_year', function () {
      var month = this.exp_month;
      var year = this.exp_year;

      if (month && year) {
        return "".concat(year, "/").concat(month);
      }

      return null;
    }),
    isCardValid: Ember.computed.alias('validations.isValid'),
    getVerificationCode: Ember.computed('verification_code', function () {
      return this.verification_code;
    }),
    setDefault: (0, _emberApiActions.memberAction)({
      path: 'default/',
      type: 'POST',
      urlType: 'queryRecord'
    })
  });

  _exports.default = _default;
});