define("trivver-frontend/pods/components/models-table/smart-object-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3wi1T4HR",
    "block": "{\"symbols\":[],\"statements\":[[5,\"bs-button\",[[12,\"class\",\"btn btn-short btn-grey\"]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"open\"]]],null],true],null]]],{\"statements\":[[0,\"\\n  Details\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"modals/smart-object-details\",null,[[\"open\",\"record\",\"onDeleteRow\"],[[23,0,[\"open\"]],[23,0,[\"record\"]],\"onDeleteRow\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/smart-object-details/template.hbs"
    }
  });

  _exports.default = _default;
});