define("trivver-frontend/pods/game/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var requiredValidator = (0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: _messages.REQUIRED
  });
  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [requiredValidator],
    description: [requiredValidator, (0, _emberCpValidations.validator)('length', {
      max: 600
    })],
    genres: [requiredValidator],
    platforms: [requiredValidator],
    genders: [requiredValidator],
    ages: [requiredValidator],
    categories: [requiredValidator]
  }, {
    disabled: Ember.computed.not('model.validationAttempted')
  });

  var _default = _model.default.extend(Validations, {
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    game_uid: (0, _model.attr)('string'),
    tags: (0, _model.attr)('string'),
    action_viewability_count: (0, _model.attr)('number'),
    action_viewability_cost: (0, _model.attr)('number'),
    action_coupon_count: (0, _model.attr)('number'),
    action_coupon_cost: (0, _model.attr)('number'),
    action_3d_count: (0, _model.attr)('number'),
    action_3d_cost: (0, _model.attr)('number'),
    action_purchase_count: (0, _model.attr)('number'),
    action_purchase_cost: (0, _model.attr)('number'),
    action_video_count: (0, _model.attr)('number'),
    action_video_cost: (0, _model.attr)('number'),
    action_smarttab_count: (0, _model.attr)('number'),
    action_smarttab_cost: (0, _model.attr)('number'),
    total_cost: (0, _model.attr)('number'),
    total_count: (0, _model.attr)('number'),
    published_versions_count: (0, _model.attr)('number'),
    // developer: belongsTo('org'),
    screenshots: (0, _model.hasMany)('screenshot'),
    versions: (0, _model.hasMany)('version'),
    genres: (0, _model.hasMany)('genre'),
    platforms: (0, _model.hasMany)('platform'),
    genders: (0, _model.hasMany)('gender'),
    ages: (0, _model.hasMany)('age'),
    categories: (0, _model.hasMany)('category'),
    sortedGenres: Ember.computed('genres.@each.title', function () {
      return this.genres.sortBy('title').mapBy('title');
    }),
    sortedGenders: Ember.computed('genders.@each.title', function () {
      return this.genders.sortBy('title').mapBy('title');
    }),
    sortedPlatforms: Ember.computed('platforms.@each.title', function () {
      return this.platforms.sortBy('title').mapBy('title');
    }),
    arrayOfGenres: Ember.computed('genres.@each.title', function () {
      return this.genres.mapBy('title');
    }),
    arrayOfAges: Ember.computed('ages.@each.value', function () {
      return this.ages.mapBy('value');
    }),
    arrayOfPlatforms: Ember.computed('platforms.@each.title', function () {
      return this.platforms.mapBy('title');
    }),
    arrayOfCategories: Ember.computed('categories.@each.id', function () {
      return this.categories.mapBy('id');
    })
  });

  _exports.default = _default;
});