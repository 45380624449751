define("trivver-frontend/pods/draft-campaign/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    json_data: (0, _model.attr)('raw'),
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    last_step: (0, _model.attr)('string'),
    passed_steps: (0, _model.attr)('array'),
    type: (0, _model.attr)('string')
  });

  _exports.default = _default;
});