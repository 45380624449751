define("trivver-frontend/pods/components/date-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rVANE+Pz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-daterange input-group\"],[10,\"id\",\"datepicker\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"name\"],[[23,0,[\"start\"]],\"input-sm form-control\",\"start\"]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"to\"],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"name\"],[[23,0,[\"end\"]],\"input-sm form-control\",\"end\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/date-range/template.hbs"
    }
  });

  _exports.default = _default;
});