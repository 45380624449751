define("trivver-frontend/pods/payment-history/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    transaction_id: (0, _model.attr)('string'),
    campaign: (0, _model.attr)('string'),
    campaign_end: (0, _model.attr)('date'),
    campaign_type: (0, _model.attr)('string'),
    payment_method: (0, _model.attr)('string'),
    amount: (0, _model.attr)('cent'),
    remaining_funds: (0, _model.attr)('cent'),
    transaction_date: (0, _model.attr)('date'),
    transaction_type: (0, _model.attr)('string'),
    stripe_id: (0, _model.attr)('string'),
    transactionType: Ember.computed('transaction_type', function () {
      var type = this.transaction_type;
      return {
        payment_stripe: 'Payment'
      }[type];
    }),
    campaignType: Ember.computed('campaign_type', function () {
      var type = this.campaign_type;
      return {
        P: 'Product Portal',
        G: 'Trivver XR'
      }[type];
    })
  });

  _exports.default = _default;
});