define("trivver-frontend/pods/components/with-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LvTpMGLG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"hasSidebar\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n    \"],[1,[28,\"with-sidebar/sidebar\",null,[[\"groups\"],[[23,0,[\"sidebarConfig\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"col-sm-\",[28,\"if\",[[23,0,[\"hasSidebar\"]],\"9\",\"12\"],null]]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/with-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});