define("trivver-frontend/pods/components/sup-tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'sup',
    classNames: ['help']
  }).reopenClass({
    positionalParams: ['title']
  });

  _exports.default = _default;
});