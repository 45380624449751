define("trivver-frontend/pods/utils/file-to-square-without-crop/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Get coordinates for cropping square area
   *
   * @param width - width of input image
   * @param height - height of input image
   * @param size - destination size
   */
  function _getDestinationCoords(width, height, size) {
    var result = {};
    var ratio = width / height;

    if (ratio > 1) {
      result.dx = 0;
      result.dy = (1 - 1 / ratio) * (size / 2);
      result.dh = size / ratio;
      result.dw = size;
      return result;
    }

    result.dx = (1 - ratio) * (size / 2);
    result.dy = 0;
    result.dh = size;
    result.dw = ratio * size;
    return result;
  } // Polyfill from https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#Polyfill


  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
      value: function value(callback, type, quality) {
        var dataURL = this.toDataURL(type, quality).split(",")[1];
        setTimeout(function () {
          var binStr = atob(dataURL);
          var len = binStr.length;
          var arr = new Uint8Array(len);

          for (var i = 0; i < len; i += 1) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], {
            type: type || "image/png"
          }));
        });
      }
    });
  }
  /**
   * Function for resizing file image to square without crop
   *
   * @param {Blob} file - image file
   * @param {function} success - callback function which receive after resize
   * @param {Number} size - result size of square in pixels
   */


  function fileToSquareWithoutCrop(file, success) {
    var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 320;
    var newType = "image/png";
    var newExt = "png";
    var fileName = file.name.split(".").slice(0, -1).join(".");
    var newFileName = "".concat(fileName, ".").concat(newExt);
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      var img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        var elem = document.createElement("canvas");
        elem.width = size;
        elem.height = size;
        var ctx = elem.getContext("2d");

        var coords = _getDestinationCoords(img.width, img.height, size);

        ctx.drawImage(img, 0, 0, img.width, img.height, coords.dx, coords.dy, coords.dw, coords.dh);
        ctx.canvas.toBlob(function (blob) {
          var newFile = new File([blob], newFileName, {
            type: newType,
            lastModified: Date.now()
          });
          success(newFile);
        }, newType, 1);
      };
    };
  }

  var _default = fileToSquareWithoutCrop;
  _exports.default = _default;
});