define("trivver-frontend/pods/components/modals/drs-campaign-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backdropClose: true,
    actions: {
      close: function close() {
        this.set('open', false);
      },
      gift: function gift() {
        if (this.giftAction) {
          this.sendAction('giftAction', this.drsUserCampaign);
        }
      }
    }
  });

  _exports.default = _default;
});