define("trivver-frontend/pods/home/cabinet/advertiser/campaigns/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x0DWxrY/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"campaign-wizard\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-title\"],[8],[0,\"Create Campaign\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[28,\"eq\",[[23,0,[\"currentStep\"]],\"review\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"page-title-aside pull-right\"],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[0,\"Campaign name:\"],[9],[0,\"\\n      \"],[7,\"span\",true],[11,\"title\",[29,[[23,0,[\"model\",\"campaign\",\"title\"]]]]],[8],[0,\"\\n        \"],[1,[28,\"if\",[[23,0,[\"model\",\"campaign\",\"title\"]],[28,\"truncate\",[[23,0,[\"model\",\"campaign\",\"title\"]],50],null],\"Not set\"],null],false],[9],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"btn btn-short btn-link\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"openCampaignTitleModal\"]]],null],true]],[8],[0,\"Edit\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"progress-steps\",null,[[\"steps\",\"current\",\"prevSteps\",\"clickable\",\"allowedSteps\",\"goto\"],[[23,0,[\"steps\"]],[23,0,[\"currentStep\"]],[23,0,[\"prevSteps\"]],[23,0,[\"allowRedirect\"]],[23,0,[\"allowedSteps\"]],\"goto\"]]],false],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[28,\"eq\",[[23,0,[\"currentStep\"]],\"review\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"modals/change-campaign-title\",null,[[\"open\",\"model\",\"button\",\"action\"],[[23,0,[\"openCampaignTitleModal\"]],[23,0,[\"model\",\"campaign\"]],[28,\"if\",[[23,0,[\"firstVisit\"]],\"Continue\",\"Save\"],null],[28,\"route-action\",[\"saveCampaignTitle\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/cabinet/advertiser/campaigns/create/template.hbs"
    }
  });

  _exports.default = _default;
});