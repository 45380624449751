define("trivver-frontend/pods/pp-payment/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/copyable"], function (_exports, _model, _emberCpValidations, _copyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    amount: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0
    })
  }, {
    disabled: Ember.computed('model.validationAttempted', function () {
      return !this.get('model.validationAttempted');
    })
  });

  var _default = _model.default.extend(Validations, _copyable.default, {
    campaign: (0, _model.belongsTo)('pp-campaign'),
    token: (0, _model.attr)('string'),
    amount: (0, _model.attr)('cent', {
      defaultValue: 0
    }),
    status: (0, _model.attr)('string'),
    failure_message: (0, _model.attr)('string')
  });

  _exports.default = _default;
});