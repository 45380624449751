define("trivver-frontend/services/game-toggler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    toggleGame: function toggleGame(gameUID, status) {
      var callback = null;
      this.set('ajax.customHeaders.Game-UID', gameUID);

      if (status) {
        callback = this.ajax.request('/game/activate/', {
          method: 'POST'
        });
      } else {
        callback = this.ajax.request('/game/activate/', {
          method: 'DELETE'
        });
      }

      this.set('ajax.customHeaders', {});
      return callback;
    }
  });

  _exports.default = _default;
});