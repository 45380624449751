define("trivver-frontend/pods/components/inputs/color-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9KO1ntCf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"color-picker\"],[8],[0,\"\\n  \"],[5,\"input\",[[12,\"id\",[23,0,[\"id\"]]],[12,\"class\",\"form-control\"],[12,\"placeholder\",[23,0,[\"placeholder\"]]],[12,\"onblur\",[23,0,[\"blur\"]]]],[[\"@value\"],[[23,0,[\"value\"]]]]],[0,\"\\n  \"],[5,\"input\",[[12,\"id\",[28,\"concat\",[[23,0,[\"id\"]],\"picker\"],null]]],[[\"@value\",\"@type\"],[[23,0,[\"value\"]],\"color\"]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/color-input/template.hbs"
    }
  });

  _exports.default = _default;
});