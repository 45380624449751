define("trivver-frontend/pods/change-password/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    old_password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    new_password1: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: _messages.PASSWORD_CHANGE_VALIDATOR_LENGTH
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d).*$/,
      message: _messages.PASSWORD_VALIDATOR_DIGIT
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[a-z]).*/,
      message: _messages.PASSWORD_VALIDATOR_LOWER_CASE
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[A-Z]).*/,
      message: _messages.PASSWORD_VALIDATOR_UPPER_CASE
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*?[!@#$%^&-])/,
      message: _messages.PASSWORD_VALIDATOR_SPECIAL_CHAR
    })],
    new_password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'new_password1',
      message: _messages.PASSWORD_CHANGE_CONFIRMATION
    })]
  }, {
    disabled: Ember.computed.not('model.validationAttempted')
  });

  var _default = _model.default.extend(Validations, {
    old_password: (0, _model.attr)('string'),
    new_password1: (0, _model.attr)('string'),
    new_password2: (0, _model.attr)('string')
  });

  _exports.default = _default;
});