define("trivver-frontend/pods/components/modals/payout-request/component", ["exports", "trivver-frontend/constants/e-commerce"], function (_exports, _eCommerce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    minPayoutAmount: _eCommerce.default,
    actions: {
      hide: function hide() {
        this.set('open', false);
        this.set('errors', null);

        if (!this.get('model.id')) {
          this.model.destroyRecord();
        }
      },
      show: function show() {
        this.set('open', true);
        this.set('model', this.store.createRecord('payout-request', {
          amount: (this.available * 0.01).toFixed(2)
        }));
      }
    }
  });

  _exports.default = _default;
});