define("trivver-frontend/pods/components/models-table/us-date-created/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JML5F7yC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"time\",true],[8],[1,[28,\"format-date\",[[23,0,[\"record\",\"created\"]]],[[\"format\"],[\"M/D/YYYY h:mm A\"]]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/us-date-created/template.hbs"
    }
  });

  _exports.default = _default;
});