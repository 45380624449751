define("trivver-frontend/pods/components/avatar-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k6ezWKxP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"id\",\"upload-avatar\"],[10,\"name\",\"Upload\"],[10,\"value\",\"Upload\"],[10,\"accept\",\".jpg, .jpeg, .png\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"uploadImage\"],null]],[10,\"style\",\"display:none\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\",\"avatarPreview\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"display-avatar\"],[11,\"style\",[29,[\"background-image:url(\",[23,0,[\"model\",\"avatarPreview\"]],\");\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"display-avatar\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"buttons text-right buttons-avatar\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"avatarCroppShow\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-button\",[[12,\"disabled\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[\"model\"]],\"validations\"],null],\"attrs\"],null],\"avatar\"],null],\"isInvalid\"],null]]],[[\"@type\",\"@onClick\"],[\"btn btn-blue btn-short\",[28,\"route-action\",[\"croppShowModal\",true],null]]],{\"statements\":[[0,\"\\n      Cropping\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"model\",\"avatarPreview\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn-grey btn-short\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"deleteImage\"]],[8],[0,\"\\n      Delete\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"label\",true],[10,\"class\",\"btn btn-blue btn-short\"],[10,\"for\",\"upload-avatar\"],[8],[0,\"Browse\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/avatar-upload/template.hbs"
    }
  });

  _exports.default = _default;
});