define("trivver-frontend/pods/components/models-table/advertiser-products-min/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23BhbBEl",
    "block": "{\"symbols\":[\"mt\",\"table\",\"body\",\"record\",\"index\"],\"statements\":[[5,\"models-table\",[],[[\"@data\",\"@columns\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@pageSize\",\"@showPageSize\",\"@selectRowOnClick\",\"@showComponentHeader\",\"@multipleSelect\"],[[23,0,[\"data\"]],[23,0,[\"columns\"]],false,false,10,false,true,false,false]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"table\"]],[[12,\"class\",\"advertiser-products\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,3,[\"visibleContent\"]]],null,{\"statements\":[[4,\"if\",[[23,4,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,3,[\"row\"]],\"expected `body.row` to be a contextual component but found a string. Did you mean `(component body.row)`? ('trivver-frontend/pods/components/models-table/advertiser-products-min/template.hbs' @ L6:C12) \"],null]],[[\"class\",\"record\",\"index\"],[[28,\"if\",[[23,4,[\"is_primary\"]],\"selected\"],null],[23,4,[]],[23,5,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[4,5]},null],[0,\"    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-products-min/template.hbs"
    }
  });

  _exports.default = _default;
});