define("trivver-frontend/pods/version/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: (0, _model.attr)('string'),
    modified: (0, _model.attr)('string'),
    is_published: (0, _model.attr)('boolean'),
    version: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    game: (0, _model.belongsTo)('game'),
    publishRequest: (0, _emberApiActions.memberAction)({
      path: 'publish/',
      type: 'post',
      urlType: 'findHasMany'
    })
  });

  _exports.default = _default;
});