define("trivver-frontend/helpers/percents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      return Math.round(params[0] * 100) / 100;
    }
  });

  _exports.default = _default;
});