define("trivver-frontend/pods/components/show-game-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['clickable'],
    actions: {
      sendAction: function sendAction(actionName, record, event) {
        this.sendAction(actionName, record);
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});