define("trivver-frontend/pods/components/cookies-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X9VMVRmh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"push-bottom\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"push__inner\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"push__text\"],[8],[0,\"\\n      This website uses cookies for functionality, analytics and advertising purposes as described in our \"],[7,\"a\",true],[11,\"href\",[29,[[23,0,[\"config\",\"indexPageHost\"]],\"/privacy-policy.html\"]]],[10,\"class\",\"push__link\"],[8],[0,\"Privacy Policy\"],[9],[0,\". If you agree to our use of cookies, please continue to use our site.\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-blue btn-filled\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,0,[\"cookiesAccepted\"]]]],[8],[0,\"Got it\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/cookies-warning/template.hbs"
    }
  });

  _exports.default = _default;
});