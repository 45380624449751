define("trivver-frontend/pods/payment/serializer", ["exports", "ember-django-adapter/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    attrs: {
      campaign: 'campaign_id'
    }
  });

  _exports.default = _default;
});