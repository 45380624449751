define("trivver-frontend/pods/pp-asset/serializer", ["exports", "@ember-data/serializer/rest", "ember-django-adapter/serializers/drf"], function (_exports, _rest, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      asset_3d_model: {
        key: 'asset_model_id'
      },
      coupon_settings: {
        embeded: 'always',
        deserialize: 'records',
        serialize: 'records'
      }
    }
  });

  _exports.default = _default;
});