define("trivver-frontend/pods/components/models-table/pp-advertiser-campaigns/cells/money/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upperPropertyValue: Ember.computed('column.propertyName', function () {
      return this.get("record.".concat(this.get('column.propertyName')));
    }),
    lowerPropertyValue: Ember.computed('column.lowerPropertyName', function () {
      return this.get("record.".concat(this.get('column.lowerPropertyName')));
    })
  });

  _exports.default = _default;
});