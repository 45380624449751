define("trivver-frontend/pods/confirm-password/model", ["exports", "@ember-data/model", "ember-cp-validations", "ember-api-actions"], function (_exports, _model, _emberCpValidations, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    new_password1: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 256
    })],
    new_password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Required'
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'new_password1',
      message: "Passwords Don't Match"
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 256
    })],
    uid: (0, _emberCpValidations.validator)('presence', true),
    token: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    new_password1: (0, _model.attr)('string'),
    new_password2: (0, _model.attr)('string'),
    uid: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    sendRequest: (0, _emberApiActions.memberAction)({
      path: 'confirm/',
      type: 'post',
      urlType: 'createRecord'
    })
  });

  _exports.default = _default;
});