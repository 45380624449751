define("trivver-frontend/pods/home/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    config: Ember.inject.service(),
    isCookiesAccepted: false,
    actions: {
      cookiesAccepted: function cookiesAccepted() {
        this.set('isCookiesAccepted', true);
        this.cookies.write('qubit_consent', true);
      },
      logout: function logout() {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});