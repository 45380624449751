define("trivver-frontend/validators/video-size", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/variables", "trivver-frontend/constants/messages"], function (_exports, _base, _variables, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VideoSizeValidator = _base.default.extend({
    validate: function validate(value) {
      if (value && !(typeof value === 'string')) {
        if (value.size <= _variables.VIDEO_MAX_SIZE) {
          return true;
        }

        return _messages.VIDEO_SIZE_ERROR;
      }

      return true;
    }
  });

  var _default = VideoSizeValidator;
  _exports.default = _default;
});