define("trivver-frontend/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(date, params) {
      return moment(date[0]).format(params.format);
    }
  });

  _exports.default = _default;
});