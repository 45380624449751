define("trivver-frontend/pods/game/serializer", ["exports", "trivver-frontend/pods/drf/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    primaryKey: 'id',
    keyForRelationship: function keyForRelationship(key) {
      if (key === 'versions' || key === 'screenshots') {
        return 'game';
      }

      return key;
    }
  });

  _exports.default = _default;
});