define("trivver-frontend/pods/components/modals/new-category-request/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categories: null,
    categoryModel: Ember.computed.alias('newCategoryModel'),
    didInsertElement: function didInsertElement() {
      var categoriesModel = this.categoriesModel;
      var categories = Array.from(new Set(categoriesModel.map(function (category) {
        return category.get('title');
      }))).sort();
      this.set('categories', categories);
    },
    actions: {
      onHide: function onHide() {
        this.set('open', false);
      }
    }
  });

  _exports.default = _default;
});