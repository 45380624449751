define("trivver-frontend/pods/components/collection-/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8qoKJfo",
    "block": "{\"symbols\":[\"val\",\"&default\"],\"statements\":[[4,\"if\",[[28,\"is-collection\",[[23,0,[\"value\"]]],null]],null,{\"statements\":[[4,\"each\",[[23,0,[\"value\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"field\"]]],null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"get\",[[23,1,[]],[23,0,[\"field\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,2,[[23,0,[\"value\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/collection-/template.hbs"
    }
  });

  _exports.default = _default;
});