define("trivver-frontend/pods/fund/model", ["exports", "@ember-data/model", "ember-cp-validations", "ember-api-actions"], function (_exports, _model, _emberCpValidations, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    amount: [(0, _emberCpValidations.validator)('number', {
      gte: 0.5,
      allowString: true,
      message: 'Transferred amount must be greater or equal to 50 cents.'
    }), (0, _emberCpValidations.validator)('number', {
      lte: 20000000,
      allowString: true,
      message: 'Transferred amount must be no more then 20000000 cents.'
    })]
  });

  var _default = _model.default.extend(Validations, {
    amount: (0, _model.attr)('cent', {
      defaultValue: 0
    }),
    campaign: (0, _model.belongsTo)('adv-campaign'),
    paymentRequest: (0, _emberApiActions.memberAction)({
      path: 'charge/',
      type: 'post',
      urlType: 'createRecord'
    }),
    amountToCents: Ember.computed('amount', function () {
      return Math.trunc(this.amount * 100);
    })
  });

  _exports.default = _default;
});