define("trivver-frontend/pods/campaign-report/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var names = [['smarttab', 'View 2D'], ['3d', 'View 3D'], ['video', 'Watch Video'], ['coupon', 'Get Coupon'], ['purchase', 'Where to Buy']];

  var _default = _model.default.extend({
    uid: (0, _model.attr)('string'),
    impressions: (0, _model.attr)('number'),
    actions: (0, _model.attr)(),
    total: (0, _model.attr)(),
    defaultActions: Ember.computed(function () {
      return names.map(function (item) {
        return {
          action_name: item[0],
          clicks: 0,
          cost: 0,
          cost_per_click: 0,
          ctr: 0,
          seconds: null,
          t_view: 0,
          tvr: 0,
          view_time: null
        };
      });
    }),
    clicks: Ember.computed('actions', function () {
      var data = {};
      this.actions.forEach(function (item) {
        data[item.action_name] = item.clicks;
      });
      return data;
    }),
    dict: Ember.computed('actions', function () {
      var dict = {};
      var actions = this.actions;

      if (!actions.length) {
        actions = this.defaultActions;
      } // create map like { action_name: {...} }


      actions.forEach(function (e) {
        Ember.set(dict, e.action_name, e);
      });
      return dict;
    }),
    report: Ember.computed('actions', function () {
      var dict = this.dict; // create ordered list of actions and fill in titles

      var res = names.map(function (e) {
        var entry = dict[e[0]];
        Ember.set(entry, 'title', e[1]);
        return entry;
      });
      return res;
    }),

    /**
     * the same like ``total`` but does not contain viewability,
     * just actions in list below.
     */
    sum: Ember.computed('actions', function () {
      var actions = ['smarttab', '3d', 'video', 'coupon', 'purchase'];
      var clicks = {};
      var cost = {};
      this.actions.forEach(function (item) {
        clicks[item.action_name] = item.clicks;
        cost[item.action_name] = item.cost;
      });
      return {
        clicks: actions.reduce(function (a, b) {
          return a + clicks[b];
        }, 0),
        cost: actions.reduce(function (a, b) {
          return a + cost[b];
        }, 0)
      };
    })
  });

  _exports.default = _default;
});