define("trivver-frontend/constants/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WHITE = _exports.GRAY = void 0;
  var WHITE = '#FFFFFF';
  _exports.WHITE = WHITE;
  var GRAY = '#808080';
  _exports.GRAY = GRAY;
});