define("trivver-frontend/helpers/br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      return Ember.String.htmlSafe((params[0] || '').replace('\n', '<br />', String.trim));
    }
  });

  _exports.default = _default;
});