define("trivver-frontend/pods/components/modals/metaverse-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    mvPlatforms: ["ROBLOX", "SANDBOX", "DECENTRALAND"],
    otherMVName: null,
    // Store the original value
    originalMvUrl: null,
    product: Ember.computed("campaign.assets", function () {
      var asset = this.campaign.assets.find(function (a) {
        return a.is_primary;
      });
      return asset;
    }),
    actions: {
      hide: function hide() {
        // Revert to the original value if Cancel is clicked
        this.campaign.set("mv_url", this.originalMvUrl);
        this.set("open", false);
        this.sendAction("onHideAction");
      },
      show: function show() {
        this.set("open", true);

        if (this.campaign.mv_url === null || this.campaign.mv_url === undefined) {
          this.campaign.set("mv_url", "");
        } // Store the original value when the modal is shown


        this.set("originalMvUrl", this.campaign.mv_url);
        var platform = this.campaign.mv_platform;

        if (platform && !this.mvPlatforms.includes(platform)) {
          this.set("otherMVName", platform);
        }
      },
      updateCoordinatesValue: function updateCoordinatesValue(value) {
        var _value$split = value.split(","),
            _value$split2 = _slicedToArray(_value$split, 2),
            xString = _value$split2[0],
            yString = _value$split2[1];

        var mvX = Number(xString);
        var mvY = Number(yString);

        if (Number.isNaN(mvX) || Number.isNaN(mvY)) {
          this.campaign.set("mv_coordinate_x", null);
          this.campaign.set("mv_coordinate_y", null);
          return;
        }

        this.campaign.set("mv_coordinate_x", mvX);
        this.campaign.set("mv_coordinate_y", mvY);
      },
      saveMetaverseDetailsAction: function saveMetaverseDetailsAction(data) {
        this.campaign.set("mv_coordinate_x", 1);
        this.campaign.set("mv_coordinate_y", 1);
        this.campaign.set("mv_platform", "SANDBOX");
        this.sendAction("saveMetaverseDetailsAction", data, this.saveDraft);
      },
      updateOtherMVPlatform: function updateOtherMVPlatform(event) {
        var platform = event.target.value;
        this.campaign.set("mv_platform", platform);
        this.set("otherMVName", platform);
      }
    }
  });

  _exports.default = _default;
});