define("trivver-frontend/pods/components/modals/product-form/component", ["exports", "trivver-frontend/constants/colors", "trivver-frontend/pods/utils/file-to-square-without-crop"], function (_exports, _colors, _fileToSquareWithoutCrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // temporary is_primary value for not reactive changing in table of products
    isPrimaryTmp: false,
    isSame3D: true,
    colors: {
      WHITE: _colors.WHITE,
      GRAY: _colors.GRAY
    },
    surface: "horizontal",
    ARElement: null,
    objectSettings: null,
    actions: {
      changeFileImage: function changeFileImage(field, file) {
        var _this = this;

        // set full image
        this.model.set("file_icon", file); // callback for cropper

        var success = function success(croppedFile) {
          _this.model.set("file_icon", croppedFile);
        };

        (0, _fileToSquareWithoutCrop.default)(file, success);
      },
      hide: function hide(model) {
        this.set("open", false);
        this.set("isSame3D", true);
        this.sendAction("onHideAction");
        this.set("objectSettings", null);
        this.set("surface", null);

        if (model) {
          model.set("AR_options", this.product.AR_options);
        }
      },
      show: function show() {
        var _this$product$AR_opti, _this$product$AR_opti2;

        this.set("open", true);
        this.set("model", this.product); // load temporary variable

        this.set("isPrimaryTmp", this.get("model.is_primary"));
        this.set("surface", (_this$product$AR_opti = this.product.AR_options) !== null && _this$product$AR_opti !== void 0 && _this$product$AR_opti.surface ? this.product.AR_options.surface : "horizontal");

        if ((_this$product$AR_opti2 = this.product.AR_options) !== null && _this$product$AR_opti2 !== void 0 && _this$product$AR_opti2.tbso_rotate) {
          this.set("objectSettings", {
            coords: _objectSpread({}, this.product.AR_options.tbso_rotate),
            object_direction: this.product.AR_options.object_direction,
            object_surface: this.product.AR_options.object_surface
          });

          if (this.isSame3D) {
            document.querySelector(".same").click();
          }
        }
      },
      saveProductAction: function saveProductAction(model) {
        // load value from temporary variable
        model.set("is_primary", this.isPrimaryTmp);
        model.set("AR_options", {
          surface: this.surface,
          tbso_rotate: this.objectSettings ? this.objectSettings.coords : null,
          object_direction: this.objectSettings ? this.objectSettings.object_direction : null,
          object_surface: this.objectSettings ? this.objectSettings.object_surface : null
        });

        if (model.description) {
          model.set("description", model.description.replace(/\\"/g, '"'));
        }

        this.set("isSame3D", true);
        this.set("objectSettings", null);
        this.sendAction("saveProductAction", model);
      },
      saveModelAction: function saveModelAction(model) {
        this.sendAction("saveModelAction", model);
      },
      selectModelAction: function selectModelAction(model) {
        this.sendAction("selectModelAction", model);
      },
      ARViewChange: function ARViewChange(event) {
        var _this2 = this;

        this.set("isSame3D", event.target.checked);
        setTimeout(function () {
          if (!_this2.isSame3D) {
            _this2.set("ARElement", document.querySelector(".ng-tbso--AR"));

            _this2.ARElement.addEventListener("onChangeRotate", function (evt) {
              _this2.set("objectSettings", _objectSpread({}, evt.detail));
            });
          } else {
            _this2.ARElement.removeEventListener("onChangeRotate", function () {});

            _this2.set("ARElement", null);

            _this2.set("objectSettings", null);
          }
        }, 150);
      },
      redirectToSmartObjects: function redirectToSmartObjects() {
        this.router.transitionTo("home.cabinet.advertiser", {
          queryParams: {
            hash: "so"
          }
        });
      }
    }
  });

  _exports.default = _default;
});