define("trivver-frontend/mixins/field-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * "Field" class computed property.
   *
   * Returns computed property which checks invalidity of field validation.
   *
   * @param field string field name for checking
   * @return computed
   */
  function fieldClass(field) {
    return Ember.computed(field, "".concat(field, "Flag"), function () {
      return this.get("".concat(field, "Flag")) && this.get("validations.attrs.".concat(field, ".isInvalid")) ? 'has-error' : '';
    });
  }
  /**
   * "Field" message computed property.
   *
   * Returns a computed property which obtains an error message
   *
   * @param field string field name for checking
   * @return computed
   */


  function fieldMessage(field) {
    return Ember.computed(field, "".concat(field, "Flag"), function () {
      return this.get("".concat(field, "Flag")) ? this.get("validations.attrs.".concat(field, ".message")) : '';
    });
  }
  /**
   * Mixin which adds computed props which simplifies templates rendering
   *
   * Props which will be added:
   *
   * - <field>Class (error class of validating field (variants: "", "has-error"))
   * - <field>Message (error message, if exists)
   * - <field>Flag Should message be shown (if false for some field, all computed props related with
   *   this field will do nothing)
   *
   */


  var _default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('constructor.attributes').forEach(function (attr) {
        Ember.defineProperty(_this, "".concat(attr.name, "Class"), fieldClass(attr.name));
        Ember.defineProperty(_this, "".concat(attr.name, "Message"), fieldMessage(attr.name));

        _this.set("".concat(attr.name, "Flag"), false);
      });
    },
    enableAllFlags: function enableAllFlags() {
      var _this2 = this;

      this.get('constructor.attributes').forEach(function (attr) {
        _this2.set("".concat(attr.name, "Flag"), true);
      });
    }
  });

  _exports.default = _default;
});