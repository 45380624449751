define("trivver-frontend/validators/campaign-start-date", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CampaignStartDate = _base.default.extend({
    validate: function validate(value, options, model) {
      if (model.get('isEndedOrStarted')) return true;
      var startOfDay = new Date().setHours(0, 0, 0, 0);

      if (value.setHours(0, 0, 0, 0) >= startOfDay) {
        return true;
      }

      return 'Start date should be greater than now.';
    }
  });

  var _default = CampaignStartDate;
  _exports.default = _default;
});