define("trivver-frontend/validators/url", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/messages"], function (_exports, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var URL_REGEX = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

  var ZipCodeCheck = _base.default.extend({
    validate: function validate(value) {
      if (!value || value.match(URL_REGEX)) {
        return true;
      }

      return _messages.INVALID_URL_ERROR;
    }
  });

  var _default = ZipCodeCheck;
  _exports.default = _default;
});