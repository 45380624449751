define("trivver-frontend/pods/components/modals/site-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjIzrJbK",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[5,\"bs-modal\",[[12,\"class\",\"campaign-wizard__modal\"]],[[\"@open\",\"@onHide\",\"@backdropTransitionDuration\",\"@transitionDuration\"],[[23,0,[\"open\"]],[28,\"action\",[[23,0,[]],\"close\"],null],0,0]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"trvr-modal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"h3\",true],[10,\"class\",\"modal-header__title\"],[8],[1,[28,\"or\",[[23,0,[\"title\"]],\"Site Link\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[14,2],[0,\"\\n\\n      \"],[7,\"p\",true],[8],[0,\"Link to Ad Site: \"],[7,\"a\",true],[10,\"style\",\"word-break:break-all;\"],[11,\"href\",[29,[[23,0,[\"campaign\",\"adSiteLink\"]]]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[1,[23,0,[\"campaign\",\"adSiteLink\"]],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"buttons text-center\"],[8],[0,\"\\n        \"],[5,\"bs-button\",[[12,\"class\",\"btn btn-grey\"]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[28,\"or\",[[24,[\"button\"]],\"Close\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/site-link/template.hbs"
    }
  });

  _exports.default = _default;
});