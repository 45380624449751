define("trivver-frontend/pods/home/cabinet/developer/org/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isCardExists: Ember.computed('model.cards', function () {
      var flag = false;
      this.get('model.cards').forEach(function (card) {
        if (card.get('stripe_id')) {
          flag = true;
        }
      });
      return flag;
    })
  });

  _exports.default = _default;
});