define("trivver-frontend/initializers/ember-advanced-validations-read-config", ["exports", "trivver-frontend/config/environment", "ember-advanced-validations/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    var validationConfig = _environment.default['adValidations'] || {};

    _configuration.default.load(validationConfig);
  }

  var _default = {
    name: 'ember-advanced-validations-read-config',
    initialize: initialize
  };
  _exports.default = _default;
});