define("trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/component", ["exports", "trivver-frontend/pods/components/models-table/pp-advertiser-drs-campaigns/theme"], function (_exports, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pageSize: 1,
    useFilteringByColumns: false,
    filteringIgnoreCase: true,
    showPageSize: false,
    selectRowOnClick: false,
    multipleSelect: false,
    anyFilterUsed: false,
    data: Ember.computed.reads("model"),
    themeInstance: _theme.default.create(),
    columns: Ember.computed(function () {
      return [{
        title: "Name",
        propertyName: "name"
      }, {
        title: "Email",
        propertyName: "email",
        className: "mt-end orderable",
        component: "models-table/pp-advertiser-drs-campaigns/cells/email"
      }, {
        title: "Location",
        propertyName: "location"
      }, {
        title: "Registered",
        propertyName: "date_created",
        sortDirection: "desc",
        sortPrecedence: 1,
        className: "mt-end orderable",
        component: "models-table/pp-advertiser-drs-campaigns/cells/date"
      }, // {
      //   title: htmlSafe('Budgeted<br/>(Remaining)'),
      //   propertyName: 'budget',
      //   lowerPropertyName: 'balance',
      //   component: 'models-table/pp-advertiser-drs-campaigns/cells/money',
      // },
      {
        title: "",
        propertyName: "campaign",
        component: "models-table/pp-advertiser-drs-campaigns/cells/campaign"
      }];
    })
  });

  _exports.default = _default;
});