define("trivver-frontend/pods/draft-campaign/adapter", ["exports", "trivver-frontend/pods/drf/adapter", "trivver-frontend/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    addTrailingSlashes: false,
    pathForType: function pathForType() {
      return 'campaigns/draft/';
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(_environment.default.APP.API_V1_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/campaigns/draft/");
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.createRecord(store, type, snapshot);
    },
    urlForDeleteRecord: function urlForDeleteRecord(type) {
      var url = this.urlForFindRecord();
      return "".concat(url, "?type=").concat(type);
    }
  });

  _exports.default = _default;
});