define("trivver-frontend/helpers/numeral", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(args) {
      return (0, _numeral.default)(args[0]).format(args[1]);
    }
  });

  _exports.default = _default;
});