define("trivver-frontend/pods/home/cabinet/developer/help/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      // eslint-disable-line no-unused-vars
      this._super.apply(this, arguments); // eslint-disable-line


      this.controllerFor('home').set('routeName', this.routeName);
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('home').set('routeName', '');
      }
    }
  });

  _exports.default = _default;
});