define("trivver-frontend/pods/components/coupon-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/ThXqty",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n    \"],[7,\"dl\",true],[8],[0,\"\\n      \"],[7,\"dt\",true],[8],[0,\"Title:\"],[9],[0,\"\\n      \"],[7,\"dd\",true],[8],[1,[23,0,[\"model\",\"title\"]],false],[9],[0,\"\\n\\n      \"],[7,\"dt\",true],[8],[0,\"Description:\"],[9],[0,\"\\n      \"],[7,\"dd\",true],[8],[1,[23,0,[\"model\",\"description\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\",\"end\"]]],null,{\"statements\":[[0,\"      \"],[7,\"dt\",true],[8],[0,\"Expiration Date:\"],[9],[0,\"\\n      \"],[7,\"dd\",true],[8],[0,\"\\n        \"],[1,[28,\"moment-format\",[[23,0,[\"model\",\"end\"]],\"MM/DD/YYYY\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"code\"]]],null,{\"statements\":[[0,\"      \"],[7,\"dt\",true],[8],[0,\"Online Code:\"],[9],[0,\"\\n      \"],[7,\"dd\",true],[8],[1,[23,0,[\"model\",\"code\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n    \"],[1,[28,\"image-preview\",null,[[\"class\",\"textNoSelected\",\"image\"],[\"coupon-preview-image\",\"No product image.\",[23,0,[\"model\",\"imagePreview\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"buttons mt15\"],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"query\",\"route\"],[\"btn btn-white btn-short\",[28,\"hash\",null,[[\"next\"],[\"review\"]]],[24,[\"route\"]]]],{\"statements\":[[0,\"Edit Coupon Details\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/coupon-preview/template.hbs"
    }
  });

  _exports.default = _default;
});