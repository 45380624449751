define("trivver-frontend/pods/home/login/route", ["exports", "trivver-frontend/mixins/routes/unlogged-user-only"], function (_exports, _unloggedUserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unloggedUserOnly.default.extend({
    breadCrumb: null,
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service()
  });

  _exports.default = _default;
});