define("trivver-frontend/pods/components/models-table/cell-edit-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PBemGZ3m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"edit-column\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEditRow\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"btn btn-short btn-grey\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"saveClicked\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n      \"],[1,[23,0,[\"saveButtonLabel\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-short btn-grey\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"cancelClicked\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n      \"],[1,[23,0,[\"cancelButtonLabel\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"btn btn-short btn-grey\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"editClicked\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n      Rename\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/cell-edit-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});