define("trivver-frontend/components/states-select", ["exports", "ember-cli-states-select/components/states-select"], function (_exports, _statesSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _statesSelect.default;
    }
  });
});