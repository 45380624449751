define("trivver-frontend/pods/components/jstree-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjOlTzbF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"includes\",[[23,0,[\"plugins\"]],\"search\"],null]],null,{\"statements\":[[4,\"unless\",[[23,0,[\"customSearch\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"input\",true],[10,\"autocomplete\",\"off\"],[11,\"id\",[29,[[23,0,[\"id\"]],\"-jstree-search\"]]],[10,\"class\",\"form-control has-error-ignore\"],[10,\"placeholder\",\"Search...\"],[10,\"type\",\"search\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"id\",[29,[[23,0,[\"id\"]],\"-jstree\"]]],[11,\"class\",[29,[[23,0,[\"jstreeClass\"]]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/jstree-input/template.hbs"
    }
  });

  _exports.default = _default;
});