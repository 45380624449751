define("trivver-frontend/pods/components/design-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: 'item',
    attributeBindings: ['href', 'title', 'style'],
    href: '#',
    click: function click(e) {
      if (!this.disabled) {
        this.sendAction('setDesign', this.record);
      }

      e.preventDefault();
    }
  });

  _exports.default = _default;
});