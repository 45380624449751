define("trivver-frontend/helpers/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(args) {
      return args[0].includes(args[1]);
    }
  });

  _exports.default = _default;
});