define("trivver-frontend/pods/components/inputs/file-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8n8Mi73d",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"id\",[28,\"concat\",[\"id_\",[23,0,[\"field\"]]],null]],[11,\"name\",[23,0,[\"field\"]]],[11,\"value\",[23,0,[\"value\"]]],[11,\"accept\",[23,0,[\"accept\"]]],[10,\"class\",\"form-control ember-text-field\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"changeFile\",[23,0,[\"field\"]]],null]],[10,\"type\",\"file\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/file-input/template.hbs"
    }
  });

  _exports.default = _default;
});