define("trivver-frontend/pods/components/copyable-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4vXBJEJ8",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[[28,\"or\",[[23,0,[\"buttonClass\"]],[23,0,[\"defaultButtonClass\"]]],null]]]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"openModal\"]],[8],[1,[23,0,[\"buttonText\"]],false],[9],[0,\"\\n\\n\"],[5,\"bs-modal\",[[12,\"class\",\"trvr-modal copyable copyable-flat\"]],[[\"@open\",\"@onHide\",\"@onShown\",\"@transitionDuration\"],[[23,0,[\"open\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"open\"]]],null]],null],[28,\"action\",[[23,0,[]],\"select\"],null],0]],{\"statements\":[[0,\"\\n  \"],[7,\"form\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"h3\",true],[10,\"class\",\"modal-header__title\"],[8],[1,[23,0,[\"buttonText\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"buttons text-center\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-blue\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"copy\"]],[8],[1,[23,0,[\"copyButtonText\"]],false],[9],[0,\"\\n\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-gray\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n          Close\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/copyable-content/template.hbs"
    }
  });

  _exports.default = _default;
});