define("trivver-frontend/pods/components/models-table/advertiser-coupons/add-coupon-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-blue', 'btn-short'],
    attributeBindings: ['type'],
    type: 'button',
    click: function click() {
      this.sendAction('showProductCouponForm', this.record);
    }
  });

  _exports.default = _default;
});