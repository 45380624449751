define("trivver-frontend/pods/components/modals/tos-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    acceptDisabled: Ember.computed.not('acceptEnabled'),
    actions: {
      accept: function accept() {
        this.set('open', false);
        this.sendAction('onYes');
      },
      decline: function decline() {
        this.set('open', false);
        this.sendAction('onNo');
      }
    }
  });

  _exports.default = _default;
});