define("trivver-frontend/pods/home/register-drs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null,
    store: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.createRecord('drs-user'),
        states: this.store.findAll('state'),
        terms: this.store.findRecord('tos-document', 'drs-user-tos')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (!controller.get('model.user.state.short')) {
        controller.set('model.user.state', controller.defaultState);
      }

      controller.set('errors', null);
    }
  });

  _exports.default = _default;
});