define("trivver-frontend/pods/pp-campaign-files/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    anchor_image: (0, _model.attr)(),
    brand_logo: (0, _model.attr)(),
    uploadFiles: (0, _emberApiActions.memberAction)({
      path: 'asset-files/',
      type: 'post',
      urlType: 'query'
    }),
    getFilesResult: (0, _emberApiActions.memberAction)({
      path: 'task-status/',
      type: 'get',
      urlType: 'query'
    })
  });

  _exports.default = _default;
});