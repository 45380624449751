define("trivver-frontend/pods/components/with-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sidebar: Ember.inject.service(),
    classNames: ['row'],
    hasSidebar: Ember.computed('sidebar.config', function () {
      return !!this.get('sidebar.config');
    }),

    /**
     * Clear config sections (remove null values)
     */
    sidebarConfig: Ember.computed('sidebar.config', function () {
      return this.get('sidebar.config').map(function (section) {
        return section.filter(Boolean);
      });
    })
  });

  _exports.default = _default;
});