define("trivver-frontend/pods/register/model", ["exports", "@ember-data/model", "ember-cp-validations", "ember-api-actions", "trivver-frontend/mixins/field-validation", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _emberApiActions, _fieldValidation, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    }), (0, _emberCpValidations.validator)('unique-email', {
      // it is necessary not to reload the database
      disabled: Ember.computed('model.validationAttempted', function () {
        return !this.get('model.validationAttempted');
      })
    })],
    password1: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*\d).*$/,
      message: _messages.PASSWORD_VALIDATOR_DIGIT
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[a-z]).*/,
      message: _messages.PASSWORD_VALIDATOR_LOWER_CASE
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*[A-Z]).*/,
      message: _messages.PASSWORD_VALIDATOR_UPPER_CASE
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.*?[!@#$%^&-])/,
      message: _messages.PASSWORD_VALIDATOR_SPECIAL_CHAR
    })],
    password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password1',
      message: _messages.PASSWORD_CONFIRMATION
    })],
    country: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('country-support')],
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    company: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: _messages.REQUIRED
      }), (0, _emberCpValidations.validator)('phone-number', {
        isValidField: 'phoneIsValid'
      })]
    },
    zip_code: {
      validators: [(0, _emberCpValidations.validator)('zipcode-valid')]
    },
    is_agreed: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    })],
    // is_allow_sms: [
    //   validator('presence', {
    //     presence: true,
    //     message: REQUIRED,
    //   }),
    //   validator('allow-sms'),
    // ],
    url: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _messages.REQUIRED
    }), (0, _emberCpValidations.validator)('format', {
      type: 'url'
    })]
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    password1: (0, _model.attr)('string'),
    password2: (0, _model.attr)('string'),
    age: (0, _model.attr)('number'),
    address: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    type: (0, _model.attr)('string', {
      defaultValue: 'DEV'
    }),
    company: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    city: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    state: (0, _model.belongsTo)('state'),
    zip_code: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    country: (0, _model.belongsTo)('country'),
    is_agreed: (0, _model.attr)('boolean'),
    // is_allow_sms: attr('boolean'),
    url: (0, _model.attr)('string'),
    registerValidate: (0, _emberApiActions.memberAction)({
      path: 'validate/',
      type: 'post'
    })
  });

  _exports.default = _default;
});