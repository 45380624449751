define("trivver-frontend/pods/games-total/model", ["exports", "@ember-data/model", "accounting/format-money"], function (_exports, _model, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    action_3d: (0, _model.attr)(),
    coupon: (0, _model.attr)(),
    facebook: (0, _model.attr)(),
    instagram: (0, _model.attr)(),
    purchase: (0, _model.attr)(),
    smarttab: (0, _model.attr)(),
    twitter: (0, _model.attr)(),
    video: (0, _model.attr)(),
    viewability: (0, _model.attr)(),
    format: function format(value) {
      return "".concat((0, _formatMoney.default)(value / 100));
    },
    getAction3D: Ember.computed(function () {
      return this.format(this.action_3d);
    }),
    getViewability: Ember.computed(function () {
      return this.format(this.viewability);
    }),
    getPurchase: Ember.computed(function () {
      return this.format(this.purchase);
    }),
    getVideo: Ember.computed(function () {
      return this.format(this.video);
    }),
    getSmartTab: Ember.computed(function () {
      return this.format(this.smarttab);
    }),
    getCoupon: Ember.computed(function () {
      return this.format(this.coupon);
    })
  });

  _exports.default = _default;
});