define("trivver-frontend/pods/components/image-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m7f0GKUz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[10,\"id\",\"upload\"],[10,\"name\",\"Upload\"],[10,\"value\",\"Upload\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"uploadImage\"],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"btn btn-grey\"],[10,\"for\",\"upload\"],[8],[0,\"Upload\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/image-upload/template.hbs"
    }
  });

  _exports.default = _default;
});