define("trivver-frontend/pods/home/login-token/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['redirectTo', 'logout'],
    redirectTo: null,
    logout: null
  });

  _exports.default = _default;
});