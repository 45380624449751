define("trivver-frontend/services/config", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    config: _environment.default,
    emberHost: Ember.computed.reads("config.APP.EMBER_HOST"),
    indexPageHost: Ember.computed.reads("config.APP.INDEX_PAGE_HOST"),
    mainColor: Ember.computed.reads("config.APP.FRONTEND_MAIN_COLOR"),
    sdkLinks: Ember.computed.reads("config.APP.SDK_LINKS"),
    mobileDeepLinks: Ember.computed.reads("config.APP.MOBILE_DEEP_LINKS"),
    datetimeFormat: Ember.computed.reads("config.ENV.datetimeFormat"),
    dateFormat: Ember.computed.reads("config.ENV.dateFormat"),
    environment: Ember.computed.reads("config.environment")
  });

  _exports.default = _default;
});