define("trivver-frontend/mixins/routes/sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sidebar: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (this.sidebarConfig) {
        this.set('sidebar.config', this.sidebarConfig);
      }

      controller.set('sidebarConfig', this.get('sidebar.config'));
    },
    deactivate: function deactivate() {
      this._super();

      this.set('sidebar.config', null);
    }
  });

  _exports.default = _default;
});