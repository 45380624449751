define("trivver-frontend/pods/components/models-table/advertiser-coupons/product-remove-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9fw98evm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon glyphicon-remove glyphicon-remove--red\"],[10,\"title\",\"Remove the product from the list\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/advertiser-coupons/product-remove-button/template.hbs"
    }
  });

  _exports.default = _default;
});