define("trivver-frontend/pods/components/inputs/intl-tel-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    tagName: 'input',
    attributeBindings: ['type'],
    type: 'tel',
    number: Ember.computed('value', {
      get: function get() {
        return this.$().intlTelInput('getNumber');
      },
      set: function set() {}
    }),
    isValidNumber: Ember.computed('number', {
      get: function get() {
        return this.$().intlTelInput('isValidNumber');
      },
      set: function set() {}
    }),
    didInsertElement: function didInsertElement() {
      // TODO: This was a fast fix. Research for better solution.
      var self = this;

      var notifyChange = function notifyChange() {
        self.notifyPropertyChange('value');
        self.notifyPropertyChange('number');
        self.notifyPropertyChange('isValidNumber');
      }; // let Ember be aware of the changes


      this.$().keyup(notifyChange);
      this.$().change(notifyChange);
      this.element.addEventListener('countrychange', notifyChange);
      this.$().intlTelInput({
        autoPlaceholder: 'aggressive',
        separateDialCode: true
      });

      if (this.initialValue) {
        this.$().intlTelInput('setNumber', this.initialValue);
      }

      notifyChange();
    },
    willDestroyElement: function willDestroyElement() {
      this.$().intlTelInput('destroy');
    }
  });

  _exports.default = _default;
});