define("trivver-frontend/pods/components/inputs/currency-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pxj8HuD0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"title\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[29,[\"id_\",[23,0,[\"name\"]]]]],[8],[1,[23,0,[\"title\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[0,\"$\"],[9],[0,\"\\n\\n  \"],[1,[28,\"input\",null,[[\"key-press\",\"id\",\"required\",\"class\",\"name\",\"value\",\"min\",\"step\",\"disabled\"],[[23,0,[\"keyPress\"]],[28,\"concat\",[\"id_\",[23,0,[\"name\"]]],null],[28,\"or\",[[23,0,[\"required\"]],\"false\"],null],\"form-control currency\",[23,0,[\"name\"]],[23,0,[\"getValue\"]],[23,0,[\"min\"]],[23,0,[\"step\"]],[23,0,[\"disabled\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/inputs/currency-field/template.hbs"
    }
  });

  _exports.default = _default;
});