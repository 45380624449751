define("trivver-frontend/constants/e-commerce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MINIMUM_AMOUNT_VALUE = _exports.ESTIMATED_REACH_MULTIPLIER = void 0;
  var MINIMUM_AMOUNT_VALUE = 2;
  _exports.MINIMUM_AMOUNT_VALUE = MINIMUM_AMOUNT_VALUE;
  var ESTIMATED_REACH_MULTIPLIER = 25; // percents

  _exports.ESTIMATED_REACH_MULTIPLIER = ESTIMATED_REACH_MULTIPLIER;
});