define("trivver-frontend/pods/components/inputs/bs-file/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileName: '',
    actions: {
      browse: function browse() {
        Ember.$("#id_".concat(this.field)).click();
      },
      changeFile: function changeFile(field, event) {
        this.set('fileName', Ember.$("#id_".concat(field)).val().substring(12));
        this.sendAction('changeFile', field, event.target.files[0]);
      }
    }
  });

  _exports.default = _default;
});