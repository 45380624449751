define("trivver-frontend/pods/components/models-table/delete-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ULryjx0+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"record\",\"is_have_campaign\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"btn btn-disabled btn-short btn-grey\"],[10,\"disabled\",\"disabled\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"bs-tooltip\",null,[[\"title\",\"triggerElement\",\"placement\"],[[23,0,[\"helpMessage\"]],\"parentView\",\"right\"]]],false],[0,\"\\n    Delete\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"bs-button\",[[12,\"class\",\"btn btn-short btn-grey\"],[12,\"disabled\",[23,0,[\"record\",\"is_have_campaign\"]]]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"open\"]]],null],true],null]]],{\"statements\":[[0,\"\\n    Delete\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"modals/yes-no-delete\",[],[[\"@open\",\"@deleteAction\"],[[23,0,[\"open\"]],[28,\"action\",[[23,0,[]],\"onDeleteRow\",[23,0,[\"record\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Are you sure you want to delete this Smart Object?\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/delete-row/template.hbs"
    }
  });

  _exports.default = _default;
});