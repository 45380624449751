define("trivver-frontend/pods/home/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WpnCBIXX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\"Error 404\"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-center\"],[8],[0,\"Ooops, something went wrong\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/404/template.hbs"
    }
  });

  _exports.default = _default;
});