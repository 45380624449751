define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/theme", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bootstrap.default.extend({
    table: 'table table-striped'
  });

  _exports.default = _default;
});