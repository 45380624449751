define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/retrieve/hints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Displaying all data combined from all products.',
    impressions: 'The number of times the Product Portal code was completely loaded on the web page.',
    actions: 'Actions related to designated object(s) in this period for this campaign. Actions include clicks on any button in the Product Portal.',
    tViewSpend: 'The total cost related to T-Views for designated period for this campaign.',
    totalSpend: 'The total cost for the designated object(s) for the designated period realted to this campaign. The sum of the Action Spend at the T-View Spend over that period.'
  };
  _exports.default = _default;
});