define("trivver-frontend/pods/payout-request/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUSES = {
    approved: 'Paid Out',
    pending: 'Pending',
    rejected: 'Rejected'
  };

  var _default = _model.default.extend({
    created: (0, _model.attr)('date'),
    approved: (0, _model.attr)('date'),
    status: (0, _model.attr)('string'),
    transfer_status: (0, _model.attr)('string'),
    amount: (0, _model.attr)('cent'),
    developer_org: (0, _model.belongsTo)('org'),
    transaction: (0, _model.belongsTo)('transaction'),
    getPayoutStatus: Ember.computed(function () {
      return this.transfer_status.split(' ').map(function (i) {
        return Ember.String.capitalize(i);
      }).join(' ');
    }),
    payoutFailed: Ember.computed.equal('transfer_status', 'payout failed'),
    getTooltipTitle: Ember.computed('transfer_status', function () {
      if (this.payoutFailed) {
        return '(Why did my payment fail?)';
      }

      return '';
    }),
    getTooltipMsg: Ember.computed('transfer_status', function () {
      if (this.payoutFailed) {
        return "\n        Please check your account status. Stripe may have requested additional\n        information prior to payout. Your payout may be rejected by Trivver,\n        if Trivver suspects fraudulent activity on your account. Please\n        contact Trivver Support by email (support@trivver.com) or Intercom\n        chat (lower right corner of each page) to discuss.\n      ";
      }

      return '';
    }),
    getStatusTitle: Ember.computed('amount', function () {
      return STATUSES[this.status];
    })
  });

  _exports.default = _default;
});