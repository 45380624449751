define("trivver-frontend/pods/components/models-table/payout-requests/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enH79Zmb",
    "block": "{\"symbols\":[\"mt\",\"ft\",\"pn\"],\"statements\":[[5,\"models-table\",[],[[\"@columns\",\"@data\",\"@multipleColumnsSorting\",\"@pageSize\",\"@useFilteringByColumns\"],[[23,0,[\"columns\"]],[23,0,[\"data\"]],false,10,false]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"table\"]],\"expected `mt.table` to be a contextual component but found a string. Did you mean `(component mt.table)`? ('trivver-frontend/pods/components/models-table/payout-requests/template.hbs' @ L2:C4) \"],null]],[[\"class\"],[\"payout-requests mb0\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gt\",[[23,0,[\"data\",\"length\"]],10],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"footer\"]],[[12,\"class\",\"mt20\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[\"summary\"]],false],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-1 col-sm-1 col-xs-1\"],[8],[9],[0,\"\\n\\n      \"],[6,[23,2,[\"pagination-numeric\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"models-table/numeric-pagination\",null,[[\"class\",\"visiblePageNumbers\",\"gotoCustomPage\"],[\"pull-right\",[23,3,[\"visiblePageNumbers\"]],[23,3,[\"gotoCustomPage\"]]]]],false],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/payout-requests/template.hbs"
    }
  });

  _exports.default = _default;
});