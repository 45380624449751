define("trivver-frontend/validators/end-date", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var EndDate = _base.default.extend({
    validate: function validate(value, options, model) {
      if (!value) return true;
      if (model.get('isEnded')) return true;
      if (value > model.get('start')) return true;
      return "End date should be greater than ".concat(model.get('start').toDateString());
    }
  });

  var _default = EndDate;
  _exports.default = _default;
});