define("trivver-frontend/pods/phone-number/adapter", ["exports", "trivver-frontend/pods/drf/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.host, "/").concat(this.namespace, "/auth/send-verification-code");
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return "".concat(this.host, "/").concat(this.namespace, "/auth/check-verification-code");
    }
  });

  _exports.default = _default;
});