define("trivver-frontend/validators/tbso-format", ["exports", "ember-cp-validations/validators/base", "trivver-frontend/constants/allowed-formats", "trivver-frontend/constants/messages"], function (_exports, _base, _allowedFormats, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TBSOFormatValidator = _base.default.extend({
    validate: function validate(value) {
      if (value && !(typeof value === 'string')) {
        if (_allowedFormats.TBSO_FILE_FORMATS.includes(value.name.split('.').pop().toLowerCase())) {
          return true;
        }

        return _messages.TBSO_FILE_FORMAT_ERROR;
      }

      return true;
    }
  });

  var _default = TBSOFormatValidator;
  _exports.default = _default;
});