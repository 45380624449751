define("trivver-frontend/pods/pp-campaign-report/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var names = [['small_2d', '2D Main View'], ['full_2d', '2D Enlarged View'], ['small_3d', '3D Main View'], ['full_3d', '3D Enlarged View'], ['ar_view', 'AR Main View'], ['ar_link', 'AR Send Link'], ['intent_promo', 'Intent 1 - Get Promo Code'], ['intent_shop', 'Intent 2 - Shop Now'], ['advocacy_share', 'Social - Share'], ['brand_url', 'Visit Brand Website'], ['metaverse_visit', 'Visit Metaverse']];

  var _default = _model.default.extend({
    uid: (0, _model.attr)('string'),
    impressions: (0, _model.attr)('number'),
    actions: (0, _model.attr)(),
    total: (0, _model.attr)(),
    defaultActions: Ember.computed(function () {
      return names.map(function (item) {
        return {
          action_name: item[0],
          clicks: 0,
          cost: 0,
          cost_per_click: 0,
          ctr: 0,
          seconds: null,
          grand_total_cost: null,
          t_view_cost: null,
          t_view_count: null,
          view_time: null
        };
      });
    }),
    tViewsMap: {
      ar_view: 't_view_ar',
      full_3d: 't_view_full_3d',
      small_3d: 't_view_small_3d'
    },
    clicks: Ember.computed('actions', function () {
      var data = {};
      this.actions.forEach(function (item) {
        data[item.action_name] = item.clicks;
      });
      return data;
    }),
    report: Ember.computed('actions', function () {
      var _this = this;

      var dict = {};
      var actions = this.actions;

      if (!actions.length) {
        actions = this.defaultActions;
      } // create map like { action_name: {...} }


      actions.forEach(function (e) {
        Ember.set(dict, e.action_name, e);
      }); // create ordered list of actions and fill in titles

      return names.map(function (e) {
        var entry = dict[e[0]];
        var related = Ember.get(_this.tViewsMap, e[0]); // set title of the action

        Ember.set(entry, 'title', e[1]); // remap t-views from actions to properties

        Ember.set(entry, 't_view_count', Ember.get(dict, "".concat(related, ".clicks")) || null);
        Ember.set(entry, 't_view_cost', Ember.get(dict, "".concat(related, ".cost")) || null);
        return entry;
      });
    }),
    tViews: Ember.computed('total', function () {
      var count = this.get('total.t_view_count');
      var cpv = this.get('total.cpv');
      var total = this.get('total.t_view_cost');
      return {
        count: count,
        cpv: cpv,
        total: total
      };
    }),
    totalSpend: Ember.computed('total', function () {
      return this.get('total.grand_total_cost');
    })
  });

  _exports.default = _default;
});