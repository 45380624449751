define("trivver-frontend/authenticators/impersonate", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authenticate: function authenticate(credentials) {
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run(function () {
          resolve({
            key: credentials.identification
          });
        });
      });
    },
    restore: function restore(data) {
      return Promise.resolve(data);
    }
  });

  _exports.default = _default;
});