define("trivver-frontend/pods/home/cabinet/advertiser/pp/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Product Portal',
      linkable: false
    }
  });

  _exports.default = _default;
});