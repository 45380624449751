define("trivver-frontend/pods/home/cabinet/advertiser/campaigns/update/review/controller", ["exports", "trivver-frontend/mixins/draft-campaign", "trivver-frontend/mixins/reaches"], function (_exports, _draftCampaign, _reaches) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_draftCampaign.default, _reaches.default, {
    router: Ember.inject.service('-routing'),
    config: Ember.inject.service(),
    finishPageMap: Ember.inject.service(),
    campaignUrl: Ember.computed('model.campaign', function () {
      return "".concat(document.location.host).concat(this.router.generateURL('home.cabinet.advertiser.campaigns.update.review', this.get('model.campaign')));
    }),
    disableAddFunds: Ember.computed('model.campaign.end', function () {
      return this.get('model.campaign.end') !== null && this.get('model.campaign.end') < Date.now();
    }),
    finishPage: Ember.computed('parent.finishPage', function () {
      var queryParam = this.get('parent.finishPage');
      return this.finishPageMap.getFinishPage(queryParam);
    })
  });

  _exports.default = _default;
});