define("trivver-frontend/pods/game/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    currentUser: Ember.inject.service(),
    baseUrl: "".concat(_environment.default.APP.API_V1_HOST, "/").concat(_environment.default.APP.API_NAMESPACE),
    pathForType: function pathForType() {
      return 'games';
    },
    urlForFindRecord: function urlForFindRecord(id) {
      this.customHeaders['Game-UID'] = id;
      return "".concat(this.baseUrl, "/game/");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      this.customHeaders['Game-UID'] = snapshot.record.get('game_uid');
      return "".concat(this.baseUrl, "/game/");
    },
    // TODO: should be refactored API for each user type respectively
    // eslint-disable-next-line no-unused-vars
    urlForQuery: function urlForQuery(query) {
      var postfix = 'games/';

      if (this.get('currentUser.user.type') === 'DEV') {
        postfix = 'developer/games/my/';
      }

      return "".concat(this.baseUrl, "/").concat(postfix);
    }
  });

  _exports.default = _default;
});