define("trivver-frontend/pods/components/interactive/uploaders/asset-tbso-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ol/kdtVt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"asset\",\"file_preview\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-xs-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mt20\"],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[0,\"Preview:\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"img-wrapper\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"model\",\"asset\",\"file_tbso\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\",false],[12,\"class\",\"btn btn-link btn-link-remove\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"removeTBSOFile\"]],[8],[0,\"x\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"img\",true],[11,\"src\",[29,[[23,0,[\"model\",\"asset\",\"file_preview\"]]]]],[10,\"class\",\"thumbnail img-responsive\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-xs-9\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt20\"],[8],[0,\"\\n    \"],[1,[28,\"file-input\",null,[[\"errors\",\"label\",\"field\",\"value\",\"changeFile\",\"accept\",\"helpText\"],[[23,0,[\"errors\"]],[28,\"if\",[[23,0,[\"model\",\"asset\",\"file_tbso\"]],\"Update Trivver Branded Smart Object\",\"Upload Trivver Branded Smart Object\"],null],\"file_tbso\",[23,0,[\"model\",\"asset\",\"fake_file\"]],\"changeFile\",\".tbso\",\"Select a .tbso file to upload.\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mt10\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[10,\"class\",\"pull-right\"],[8],[0,\"Download Trivver’s 3D Asset Editor\\n      (\"],[7,\"a\",true],[11,\"href\",[23,0,[\"config\",\"sdkLinks\",\"assetEditorWin\"]]],[8],[0,\"Win\"],[9],[0,\",\\n      \"],[7,\"a\",true],[11,\"href\",[29,[[23,0,[\"config\",\"sdkLinks\",\"assetEditorMac\"]]]]],[8],[0,\"Mac\"],[9],[0,\")\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/interactive/uploaders/asset-tbso-file/template.hbs"
    }
  });

  _exports.default = _default;
});