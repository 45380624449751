define("trivver-frontend/pods/components/modals/yes-no-delete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    object: null,
    actions: {
      deleteAction: function deleteAction() {
        var deleteAction = this.deleteAction;
        deleteAction(this.object);
        this.set('open', false);
      }
    }
  });

  _exports.default = _default;
});