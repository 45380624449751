define("trivver-frontend/pods/home/email-confirm/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ['type'],
    userTypeTitle: Ember.computed('model', function () {
      var adUser = 'an advertiser';
      var devUser = 'a publisher';
      var ad = 'AD';
      return this.model.userType === ad ? adUser : devUser;
    }),
    // computed property for appropriate route to dashboard
    routeToDashboard: Ember.computed('session.isAuthenticated', function () {
      if (this.session.get('isAuthenticated')) {
        if (this.get('currentUser.user').type === 'AD') {
          return 'home.cabinet.advertiser';
        }

        return 'home.cabinet.developer';
      }

      return null;
    })
  });

  _exports.default = _default;
});