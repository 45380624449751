define("trivver-frontend/pods/category/adapter", ["exports", "ember-cli-form-data/mixins/form-data-adapter", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter", "trivver-frontend/constants/types"], function (_exports, _formDataAdapter, _environment, _adapter, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_formDataAdapter.default, {
    currentUser: Ember.inject.service(),
    baseUrl: "".concat(_environment.default.APP.API_V1_HOST, "/").concat(_environment.default.APP.API_NAMESPACE),
    pathForType: function pathForType() {
      return 'categories/';
    },
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.baseUrl, "/categories/").concat(id, "/");
    },
    urlForFindAll: function urlForFindAll() {
      var postfix = 'categories/';

      if (this.get('currentUser.user.type') === _types.ADVERTISER) {
        postfix = 'categories/games-stat/';
      }

      return "".concat(this.baseUrl, "/").concat(postfix);
    }
  });

  _exports.default = _default;
});