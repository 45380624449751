define("trivver-frontend/pods/components/presenters/spinner-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XbR0t2Yl",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ember-cli-spinner\",null,[[\"id\",\"type\",\"height\",\"width\",\"bgColor\",\"color\"],[[23,0,[\"name\"]],\"folding-cube\",\"80px\",\"80px\",\"rgba(0, 0, 0, 0.5)\",\"magenta\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/presenters/spinner-layout/template.hbs"
    }
  });

  _exports.default = _default;
});