define("trivver-frontend/constants/variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VIDEO_MAX_SIZE = _exports.VERIFICATION_TIMEOUT = _exports.START_DATE_CAMPAIGN_LIMIT = _exports.PHONE_REGEX = _exports.PHONE_MASK = _exports.IMAGE_MAX_SIZE = _exports.END_DATE_CAMPAIGN_LIMIT = void 0;
  var IMAGE_MAX_SIZE = 10485760;
  _exports.IMAGE_MAX_SIZE = IMAGE_MAX_SIZE;
  var VIDEO_MAX_SIZE = 209715200;
  _exports.VIDEO_MAX_SIZE = VIDEO_MAX_SIZE;
  var START_DATE_CAMPAIGN_LIMIT = 1;
  _exports.START_DATE_CAMPAIGN_LIMIT = START_DATE_CAMPAIGN_LIMIT;
  var END_DATE_CAMPAIGN_LIMIT = 1;
  _exports.END_DATE_CAMPAIGN_LIMIT = END_DATE_CAMPAIGN_LIMIT;
  var VERIFICATION_TIMEOUT = 90;
  _exports.VERIFICATION_TIMEOUT = VERIFICATION_TIMEOUT;
  var PHONE_REGEX = /^\+[1-9]\d{3}\d{3}\d{4}$/;
  _exports.PHONE_REGEX = PHONE_REGEX;
  var PHONE_MASK = ["+", /[1-9]/, " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  _exports.PHONE_MASK = PHONE_MASK;
});