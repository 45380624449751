define("trivver-frontend/pods/components/models-table/payment-history/component", ["exports", "trivver-frontend/pods/components/models-table/payment-history/theme"], function (_exports, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeInstance: _theme.default.create(),
    pageSize: 10,
    isMaxPageSize: false,
    columns: [{
      propertyName: 'transaction_id',
      title: 'Transaction ID',
      useFilter: false,
      component: 'models-table/payment-history/cells/transaction-id',
      componentForFooterCell: 'models-table/payment-history/cells/transaction-id/footer',
      className: 'mt-transaction-id'
    }, {
      propertyName: 'campaign',
      title: 'Campaign',
      className: 'mt-campaign',
      component: 'models-table/payment-history/cells/campaign'
    }, {
      propertyName: 'campaignType',
      title: 'Campaign Type',
      useFilter: false,
      className: 'mt-campaign-type'
    }, {
      propertyName: 'campaign_end',
      title: 'Campaign Ending',
      useFilter: false,
      component: 'models-table/payment-history/cells/campaign-ending',
      className: 'mt-campaign-ending'
    }, {
      propertyName: 'transaction_date',
      title: 'Transaction Date',
      useFilter: false,
      component: 'models-table/payment-history/cells/date',
      className: 'mt-transaction-date'
    }, {
      propertyName: 'transactionType',
      title: 'Transaction Type',
      useFilter: false,
      className: 'mt-transaction-type'
    }, {
      propertyName: 'payment_method',
      title: 'Payment Method',
      useFilter: false,
      component: 'models-table/payment-history/cells/payment_method',
      className: 'mt-payment-method'
    }, {
      propertyName: 'amount',
      title: 'Amount',
      useFilter: false,
      component: 'models-table/payment-history/cells/amount',
      componentForFooterCell: 'models-table/payment-history/cells/amount/footer',
      className: 'mt-amount'
    }, {
      propertyName: 'remaining_funds',
      title: 'Remaining Funds',
      useFilter: false,
      component: 'models-table/payment-history/cells/remaining-funds',
      componentForFooterCell: 'models-table/payment-history/cells/remaining-funds/footer',
      className: 'mt-remaining-funds'
    }],
    actions: {
      setMaxPageSize: function setMaxPageSize() {
        this.set('pageSize', this.data.get('length'));
        this.set('isMaxPageSize', true);
      },
      showDetails: function showDetails(record) {
        this.sendAction('showDetails', record);
      }
    }
  });

  _exports.default = _default;
});