define("trivver-frontend/pods/application/adapter", ["exports", "ember-simple-auth/mixins/data-adapter-mixin", "trivver-frontend/pods/drf/adapter", "trivver-frontend/config/environment"], function (_exports, _dataAdapterMixin, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_dataAdapterMixin.default, {
    cookies: Ember.inject.service(),
    authorizer: 'authorizer:token',
    host: _environment.default.APP.API_V1_HOST,
    namespace: _environment.default.APP.API_NAMESPACE,
    customHeaders: {},
    headers: Ember.computed(function () {
      var cookies = this.cookies;
      this.customHeaders['X-CSRFToken'] = cookies.read('csrftoken');
      return this.customHeaders;
    })
  });

  _exports.default = _default;
});