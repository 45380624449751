define("trivver-frontend/pods/components/modals/payment-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p0DK0enn",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"bs-modal\",[[12,\"class\",\"campaign-wizard__modal\"]],[[\"@open\",\"@onHide\",\"@backdropTransitionDuration\",\"@transitionDuration\"],[[23,0,[\"open\"]],[28,\"action\",[[23,0,[]],\"close\"],null],0,0]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"campaign-wizard-modal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"h3\",true],[10,\"class\",\"modal-header__title\"],[8],[0,\"Payment Details\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[7,\"dl\",true],[10,\"class\",\"dl-horizontal\"],[8],[0,\"\\n        \"],[7,\"dt\",true],[8],[0,\"Transaction ID:\"],[9],[0,\"\\n        \"],[7,\"dd\",true],[8],[1,[23,0,[\"payment\",\"transaction_id\"]],false],[9],[0,\"\\n\\n        \"],[7,\"dt\",true],[8],[0,\"Transaction Date:\"],[9],[0,\"\\n        \"],[7,\"dd\",true],[8],[1,[23,0,[\"payment\",\"transaction_date\"]],false],[9],[0,\"\\n\\n        \"],[7,\"dt\",true],[8],[0,\"Stripe ID:\"],[9],[0,\"\\n        \"],[7,\"dd\",true],[8],[1,[23,0,[\"payment\",\"stripe_id\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/payment-details/template.hbs"
    }
  });

  _exports.default = _default;
});