define("trivver-frontend/pods/components/with-sidebar/sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),
    classNames: ['sidebar'],
    activeClass: 'list-group-item__active',
    scrollTime: 300,
    menuItems: [],
    menuSections: [],
    menuIdToItemMap: {},
    menuClicked: '',
    didInsertElement: function didInsertElement() {
      this.updateItemsCache();
      this.click();

      var _this = this;

      Ember.$(window).scroll(function () {
        if (!_this.get('menuClicked')) {
          _this.send('highlightMenuLink');
        }
      }); // update cached sections after change route
      // and emulate click

      this.router.addObserver('currentRouteName', function () {// scheduleOnce('afterRender', _this, () => {
        //   _this.updateItemsCache();
        //   _this.click();
        // });
      });
    },

    /**
     * Update cached items and save to component
     */
    updateItemsCache: function updateItemsCache() {
      var _this = this;

      if (this.isDestroyed) return; // cache the navigation links

      this.set('menuItems', Ember.$('ul > li.menu-item')); // cache (in reversed order) the sections

      this.set('menuSections', Ember.$(Ember.$('.menu-section').get().reverse())); // map each section id to their corresponding navigation link

      this.menuSections.each(function (index, obj) {
        var id = Ember.$(obj).attr('id');

        _this.set("menuIdToItemMap.".concat(id), Ember.$("li.menu-item[data-menu-section=".concat(id, "]")));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(window).off('scroll');
    },
    click: function click() {
      // get hash from query params
      var search = window.location.search.slice(1);

      if (!search) {
        return;
      }

      var id = null;
      search.split('&').forEach(function (pair) {
        var _pair$split = pair.split('='),
            _pair$split2 = _slicedToArray(_pair$split, 2),
            key = _pair$split2[0],
            value = _pair$split2[1];

        if (key === 'hash') {
          id = value;
        }
      });
      if (!id) return;
      if (this.isDestroyed) return; // Set clicked menu item and clear timer

      this.set('menuClicked', id);
      window.clearTimeout(this.timer);

      var _this = this;

      _this.send('setActiveItem', id);

      Ember.$(window).scrollTo("#".concat(id), {
        duration: _this.get('scrollTime'),
        offset: -15,
        onAfter: function onAfter() {
          // set timeout for clearing clicked item, for prevent changing
          // active item in scroll handler
          var timer = window.setTimeout(function () {
            return _this.set('menuClicked', '');
          }, 200);

          _this.set('timer', timer);
        }
      });
    },
    actions: {
      /**
       * Highlight active menu item when scroll sections.
       */
      highlightMenuLink: function highlightMenuLink() {
        var _this = this;

        var scrollPosition = Ember.$(window).scrollTop(); // iterate the sections

        this.menuSections.each(function (index, obj) {
          var currentSection = Ember.$(obj);
          var sectionTop = currentSection.offset().top; // if the user has scrolled over the top of the section

          if (scrollPosition >= sectionTop) {
            _this.send('setActiveItem', currentSection.attr('id'));

            return false;
          }

          return true;
        });
      },

      /**
       * Set active menu item
       *
       * This method add active class for element with passed id and remove
       * active class from other elements
       *
       * @param id {String} - element id
       */
      setActiveItem: function setActiveItem(id) {
        var navigationLink = this.get("menuIdToItemMap.".concat(id));
        var activeClass = this.activeClass; // if the link is not active

        if (!navigationLink.hasClass(activeClass)) {
          // remove active class from all items
          this.menuItems.removeClass(activeClass); // add active class to the current link

          navigationLink.addClass(activeClass);
        }
      }
    }
  });

  _exports.default = _default;
});