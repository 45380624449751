define("trivver-frontend/pods/user/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/field-validation", "trivver-frontend/constants/messages"], function (_exports, _model, _emberCpValidations, _fieldValidation, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.first_nameFlag'),
      message: _messages.VALIDATOR_NOT_BLANK
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.last_nameFlag'),
      message: _messages.VALIDATOR_NOT_BLANK
    })],
    phone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.phoneFlag'),
      message: _messages.VALIDATOR_NOT_BLANK
    }), (0, _emberCpValidations.validator)('phone-number', {
      disabled: Ember.computed.not('model.phoneFlag'),
      isValidField: 'phoneIsValid'
    })],
    zip_code: [(0, _emberCpValidations.validator)('length', {
      disabled: Ember.computed.not('model.zip_codeFlag'),
      min: 5,
      max: 6
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[0-9]*$/,
      disabled: Ember.computed.not('model.zip_codeFlag'),
      message: 'Zip code  must include only digit'
    })],
    avatar: [(0, _emberCpValidations.validator)('image-format'), (0, _emberCpValidations.validator)('image-size')],
    country: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _model.default.extend(_fieldValidation.default, Validations, {
    first_name: (0, _model.attr)('string'),
    last_name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    date_joined: (0, _model.attr)('string'),
    last_login: (0, _model.attr)('string'),
    location_updated: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    zip_code: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    company: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.belongsTo)('state'),
    device_id: (0, _model.attr)('string'),
    org_id: (0, _model.attr)('number'),
    // JSON structure and has "longitude" and "latitude" fields
    location: (0, _model.attr)('raw'),
    // JSON structure and contains "push_notification" field
    notifications: (0, _model.attr)('raw'),
    assets_count: (0, _model.attr)('number'),
    campaigns_count: (0, _model.attr)('number'),
    campaigns_active_count: (0, _model.attr)('number'),
    completed_campaigns_count: (0, _model.attr)('number'),
    active_product_portal_campaigns_count: (0, _model.attr)('number'),
    completed_product_portal_campaigns_count: (0, _model.attr)('number'),
    games_played: (0, _model.attr)('number'),
    new_coupons: (0, _model.attr)('number'),
    avatar: (0, _model.attr)('file'),
    is_product_portal_enabled: (0, _model.attr)('boolean'),
    country: (0, _model.belongsTo)('country'),
    age: (0, _model.belongsTo)('age', {
      async: true
    }),
    org: Ember.computed(function () {
      var objects = this.store.peekAll('org');

      if (objects.get('length')) {
        return objects.firstObject;
      }

      return this.store.queryRecord('org', {
        my: true
      });
    }),
    avatarPreview: Ember.computed('avatar', function () {
      var image = this.avatar;
      return _typeof(image) === 'object' && image ? URL.createObjectURL(image) : image;
    }),
    avatarCroppShow: Ember.computed('avatar', function () {
      var image = this.avatar;
      return _typeof(image) === 'object' && image;
    }),
    isFormValid: Ember.computed('validations.isValid', function () {
      this.enableAllFlags();

      if (!this.zip_code) {
        this.set('zip_codeFlag', false);
      }

      return this.get('validations.isValid');
    }),
    isAdvertiser: Ember.computed(function () {
      return this.type === 'AD';
    }),
    isDeveloper: Ember.computed(function () {
      return this.type === 'DEV';
    }),
    dashboardRoute: Ember.computed(function () {
      if (this.isAdvertiser) {
        return 'home.cabinet.advertiser';
      }

      if (this.isDeveloper) {
        return 'home.cabinet.developer';
      }

      return null;
    }),
    hasCampaigns: Ember.computed(function () {
      return this.campaigns_count + this.completed_campaigns_count > 0;
    }),
    // Compute count of all product portal campaigns (active + completed)
    productPortalCampaignsCount: Ember.computed('active_product_portal_campaigns_count', 'completed_product_portal_campaigns_count', function () {
      return this.active_product_portal_campaigns_count + this.completed_product_portal_campaigns_count;
    }),
    fullName: Ember.computed('first_name', 'last_name', function () {
      return "".concat(this.first_name, " ").concat(this.last_name).trim();
    })
  });

  _exports.default = _default;
});