define("trivver-frontend/pods/home/cabinet/advertiser/pp/campaigns/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "trivver-frontend/mixins/routes/advertiser-only"], function (_exports, _authenticatedRouteMixin, _advertiserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _advertiserOnly.default.extend(_authenticatedRouteMixin.default, {
    breadCrumb: null
  });

  _exports.default = _default;
});