define("trivver-frontend/pods/components/image-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    actions: {
      toChangeImageValidation: function toChangeImageValidation(image, event) {
        var file = event.target.files[0];

        if (!['jpg', 'jpeg', 'png'].includes(file.name.split('.').pop().toLowerCase())) {
          this.notify.alert('Only JPEG, JPG, PNG files are allowed');
          return;
        }

        var changeImage = this.changeImage;
        changeImage(image, file);
      }
    }
  });

  _exports.default = _default;
});