define("trivver-frontend/pods/components/models-table/advertiser-coupons/product-radio-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    attributeBindings: ['title'],
    title: 'Set the product as primary',
    actions: {
      setPrimaryProduct: function setPrimaryProduct(product) {
        this.sendAction('setPrimaryProduct', product);
      }
    }
  });

  _exports.default = _default;
});