define("trivver-frontend/services/store", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _store.default.extend({
    getRecord: function getRecord(model) {
      return this.findRecord(model, 0);
    }
  });

  _exports.default = _default;
});