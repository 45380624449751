define("trivver-frontend/pods/components/link-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hERu4VPK",
    "block": "{\"symbols\":[\"element\",\"index\",\"&default\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"link-filter\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"data\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"active\"]],\"active\",\"\"],null]]]],[8],[7,\"a\",false],[3,\"action\",[[23,0,[]],\"setActive\",[23,2,[]]]],[8],[1,[23,1,[\"title\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/link-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});