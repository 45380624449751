define("trivver-frontend/transforms/coupon-date-format", ["exports", "@ember-data/serializer/transform", "moment", "trivver-frontend/constants/date-formats"], function (_exports, _transform, _moment, _dateFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }

      return (0, _moment.default)(serialized).toDate();
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }

      return (0, _moment.default)(deserialized).format(_dateFormats.SEND_DATE_FORMAT);
    }
  });

  _exports.default = _default;
});