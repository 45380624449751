define("trivver-frontend/pods/components/show-game-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n0kY0j4r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"sendAction\",\"showGameDetailsModal\",[23,0,[\"record\"]]]],[8],[0,\"\\n  \"],[7,\"strong\",true],[8],[1,[23,0,[\"record\",\"title\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/show-game-details/template.hbs"
    }
  });

  _exports.default = _default;
});