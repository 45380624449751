define("trivver-frontend/pods/transaction/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    account: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    created: (0, _model.attr)('date'),
    event_time: (0, _model.attr)('date'),
    status: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    stripe_id: (0, _model.attr)('string'),
    transfer_type: (0, _model.attr)('string')
  });

  _exports.default = _default;
});