define("trivver-frontend/pods/components/models-table/payment-history/cells/date/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formatted: Ember.computed('column.propertyName', function () {
      var date = (0, _moment.default)(this.get("record.".concat(this.get('column.propertyName'))));

      if (!date.isValid()) {
        return null;
      }

      return (0, _moment.default)(this.get("record.".concat(this.get('column.propertyName')))).format('MM/DD/YYYY');
    })
  });

  _exports.default = _default;
});