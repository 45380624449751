define("trivver-frontend/pods/components/models-table/hints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    clicks: 'The number of times each button for this product/screen was clicked at least once. Only one click for each product/button/screen type is recorded for a 24 hour session.',
    cpc: 'Cost per click.',
    cpa: 'Estimated cost per action. Because some actions may cost differently, or even $0, this cost is estimated rather than calculated.',
    cpv: 'Cost per T-View associated with this campaign.',
    total: 'The total cost related to Actions for designated period for this campaign for the designated object(s).',
    ctr: 'Click-thru Ratio. Clicks for this product/screen divided by total impressions.',
    tViews: 'T-Views related to designated object(s) in this period for this campaign. Whenever the average over 5 seconds of  he screen coverage (% of the screen that`s covered) is 5% and object viewability (% of object that`s visible) is over 75%, a T-View is eligible to be counted. Only one T-View will be counted for each 24-hour period.',
    tvr: 'T-View Ratio is equal to T-Views divided by the impression count.',
    viewTime: 'The portal view time is the average time in seconds starting when the user clicks button to view this product/screen and ends when the user leaves this product/screen.',
    impressions: 'The number of times the Product Portal code was completely loaded on the web page.',
    budgeted: '“Budgeted” shows the initial dollar amount paid. “Remaining” represents the remaining dollar amount yet to be refunded.',
    actions: 'Actions related to designated object(s) in this period for this campaign. Actions include clicks on any button in the Product Portal.'
  };
  _exports.default = _default;
});