define("trivver-frontend/pods/home/forgot-password-confirm/route", ["exports", "trivver-frontend/mixins/routes/unlogged-user-only"], function (_exports, _unloggedUserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unloggedUserOnly.default.extend({
    breadCrumb: null,
    store: Ember.inject.service(),
    model: function model(params) {
      var modelData = this.store.createRecord('confirm-password');
      modelData.uid = params.uid;
      modelData.token = params.token;
      return modelData;
    }
  });

  _exports.default = _default;
});