define("trivver-frontend/pods/components/models-table/payment-history/cells/transaction-id/footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    classNames: 'mt-transaction-id'
  });

  _exports.default = _default;
});