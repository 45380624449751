define("trivver-frontend/pods/adv-asset/model", ["exports", "@ember-data/model", "ember-cp-validations", "trivver-frontend/mixins/copyable", "ember-api-actions"], function (_exports, _model, _emberCpValidations, _copyable, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    asset_3d_model: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Select an object from the list."
    }), (0, _emberCpValidations.validator)("belongs-to", true)],
    categories: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Select at least one category from the tree."
    }), (0, _emberCpValidations.validator)("has-many", true)],
    title: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      max: 40
    })],
    description: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
      max: 1024
    })],
    where_to_buy: [(0, _emberCpValidations.validator)("format", {
      regex: /(^$|^https?:\/\/[\w. /%=&#@*(){}+?!^$-_~\\]+$)/i,
      message: "Incorrect url"
    }), (0, _emberCpValidations.validator)("length", {
      max: 1024
    })],
    file_icon: [(0, _emberCpValidations.validator)("image-format"), (0, _emberCpValidations.validator)("image-size"), (0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Please select image file from your disk."
    })],
    file_image: [(0, _emberCpValidations.validator)("image-format"), (0, _emberCpValidations.validator)("image-size"), (0, _emberCpValidations.validator)("presence", {
      presence: true,
      message: "Please select image file from your disk."
    })],
    video_mobile: [(0, _emberCpValidations.validator)("video-format"), (0, _emberCpValidations.validator)("video-size")],
    coupon_settings: [(0, _emberCpValidations.validator)("belongs-to", true)]
  }, {
    disabled: Ember.computed.not("model.validationAttempted")
  });

  var previewOrNull = function previewOrNull(file) {
    if (file && file.size) {
      return URL.createObjectURL(file);
    }

    if (typeof file === "string") {
      var proto = file.startsWith("http") ? "" : "https://";
      return "".concat(proto).concat(file);
    }

    return null;
  };

  function convertNullToUndefined(field) {
    return Ember.observer(field, function () {
      if (!this.get(field)) {
        this.set(field, null);
      }
    });
  }

  var _default = _model.default.extend(_copyable.default, Validations, {
    uid: (0, _model.attr)("string"),
    title: (0, _model.attr)("string"),
    description: (0, _model.attr)("string"),
    where_to_buy: (0, _model.attr)("url-with-proto", {
      defaultValue: ""
    }),
    file_icon: (0, _model.attr)(),
    file_image: (0, _model.attr)(),
    file_coupon: (0, _model.attr)(),
    video_mobile: (0, _model.attr)(),
    file_standalone: (0, _model.attr)(),
    coupon_id: (0, _model.attr)("number"),
    asset_3d_model: (0, _model.belongsTo)("asset-3d-model"),
    coupon_settings: (0, _model.belongsTo)("coupon", {
      async: true
    }),
    categories: (0, _model.hasMany)("category", {
      async: true
    }),
    uploadFiles: (0, _emberApiActions.memberAction)({
      path: "asset-files/",
      type: "post",
      urlType: "query"
    }),
    getFilesResult: (0, _emberApiActions.memberAction)({
      path: "task-status/",
      type: "get",
      urlType: "query"
    }),
    assetUrl: Ember.computed("where_to_buy", function () {
      // If the link is not absolute, fix it
      var url = String(this.where_to_buy);

      if (url.includes("https://") || url.includes("http://")) {
        return url;
      }

      return "http://".concat(url);
    }),
    arrayOfCategories: Ember.computed("categories.@each.id", function () {
      return this.categories.mapBy("id");
    }),
    fileIconPreview: Ember.computed("file_icon", function () {
      var file = this.file_icon;
      return previewOrNull(file);
    }),
    fileImagePreview: Ember.computed("file_image", function () {
      var file = this.file_image;
      return previewOrNull(file);
    }),
    fileVideoMobilePreview: Ember.computed("video_mobile", function () {
      var file = this.video_mobile;
      return previewOrNull(file);
    }),
    fileStandalonePreview: Ember.computed("file_standalone", "asset_3d_model.file_standalone", function () {
      var preview = this.get("asset_3d_model.file_standalone");

      if (!preview) {
        preview = previewOrNull(this.file_standalone);
      }

      return preview;
    }),
    fileVideoChanged: convertNullToUndefined("video_mobile"),
    fileCouponChanged: convertNullToUndefined("file_coupon"),
    fileImageChanged: convertNullToUndefined("file_image"),
    fileIconChanged: convertNullToUndefined("file_icon")
  });

  _exports.default = _default;
});