define("trivver-frontend/pods/components/tags-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$('.tags-input').selectize({
        plugins: ['remove_button'],
        delimiter: ',',
        persist: true,
        hideSelected: true,
        onInitialize: function onInitialize() {
          var tagsInitList = this.items.map(function (x) {
            return x.trim();
          });
          /*
             * because the component caches tags loaded with model,
             * we need to filter them from API response, so they are not
             * presented twice in drop-down menu
            */

          this.tagsInitLookUp = tagsInitList.reduce(function (hash, elem) {
            var hashTags = hash;
            hashTags[elem] = true;
            return hashTags;
          }, {});
        },
        create: function create(input) {
          return {
            value: input,
            text: input
          };
        },
        load: function load(query, callback) {
          var _this = this;

          self.get('store').query('tag', {
            query: query
          }).then(function (data) {
            // change property names for each object
            var tags = data.map(function (tagObj) {
              return {
                value: tagObj.get('name'),
                text: tagObj.get('name')
              };
            }).filter(function (tagObj) {
              return !_this.tagsInitLookUp[tagObj.text];
            });
            callback(tags);
          });
        }
      });
    }
  });

  _exports.default = _default;
});