define("trivver-frontend/pods/components/modals/modal-country/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kdccQ11+",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[5,\"bs-modal\",[[12,\"class\",[23,0,[\"class\"]]]],[[\"@open\",\"@onHide\",\"@backdropTransitionDuration\",\"@transitionDuration\"],[[23,0,[\"open\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"open\"]]],null]],null],0,0]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"X\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"modal-title-blue\"],[8],[0,\"Attention\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"modal-title\"],[8],[0,\"\\n        \"],[7,\"b\",true],[8],[1,[23,0,[\"title\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"modal-title\"],[10,\"align\",\"left\"],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"btn btn-blue modal-btn-singup-error\"],[10,\"href\",\"mailto:support@trivver.com\"],[8],[0,\"\\n        Contact Customer Service\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/modals/modal-country/template.hbs"
    }
  });

  _exports.default = _default;
});