define("trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/view-time/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rNBnikDH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"title\",[29,[[23,0,[\"record\",\"view_time\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"record\",\"view_time\"]],null],null]],null,{\"statements\":[[0,\"    -\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"numeral\",[[28,\"round\",[[23,0,[\"record\",\"view_time\"]]],null],\"0,0\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/models-table/pp-advertiser-campaign/cells/view-time/template.hbs"
    }
  });

  _exports.default = _default;
});