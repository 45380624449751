define("trivver-frontend/pods/spot/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    created: (0, _model.attr)('date'),
    modified: (0, _model.attr)('date'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    uid: (0, _model.attr)('string'),
    image: (0, _model.attr)(),
    game: (0, _model.attr)('number'),
    version: (0, _model.attr)('number'),
    categories: (0, _model.hasMany)('category', {
      async: true
    })
  });

  _exports.default = _default;
});