define("trivver-frontend/transforms/date-only", ["exports", "@ember-data/serializer/transform", "moment", "trivver-frontend/constants/date-formats"], function (_exports, _transform, _moment, _dateFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Date only transform
   *
   * Drops time from serialized request JSON
   *
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      return _moment.default.utc(deserialized).format(_dateFormats.SEND_DATE_FORMAT);
    }
  });

  _exports.default = _default;
});