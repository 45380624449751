define("trivver-frontend/pods/pp-asset/adapter", ["exports", "trivver-frontend/pods/drf/adapter", "ember-cli-form-data/mixins/form-data-adapter", "trivver-frontend/config/environment"], function (_exports, _adapter, _formDataAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_formDataAdapter.default, {
    pathForType: function pathForType() {
      return "assets";
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.createRecord(store, type, snapshot);
    },
    urlForQuery: function urlForQuery() {
      return "".concat(_environment.default.APP.API_V2_HOST, "/api/v2/assets/");
    }
  });

  _exports.default = _default;
});