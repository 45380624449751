define("trivver-frontend/pods/components/avatar-upload/component", ["exports", "trivver-frontend/constants/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    cropperShow: null,
    actions: {
      /**
       * Upload and preview avatar
       *
       * Upload user's image in user model
       *
       * @method uploadImage
       * @return {undefined}
       * @public
       */
      uploadImage: function uploadImage(evt) {
        if (evt.target.files && evt.target.files[0]) {
          this.set('model.avatar', null);
          var event = evt;
          var image = event.target.files[0];
          var ext = image.name.substr(image.name.lastIndexOf('.') + 1);

          if (['jpeg', 'png', 'jpg'].includes(ext.toLowerCase())) {
            this.set('model.avatar', image);
            this.set('errors', null);
            event.target.value = null;
          } else {
            this.set('errors', {
              detail: _messages.IMAGE_FORMAT_ERROR
            });
          }
        }
      },
      deleteImage: function deleteImage() {
        this.set('model.avatar', null);
        this.set('avatarCroppShow', false);
      }
    }
  });

  _exports.default = _default;
});