define("trivver-frontend/pods/components/models-table/advertiser-products/product-radio-button/input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'name', 'checked'],
    type: 'radio',
    name: 'products',
    checked: Ember.computed.alias('record.is_primary'),
    _updateElementValue: Ember.on('didInsertElement', function () {
      this.set('checked', this.$().prop('checked'));
    }),
    change: function change() {
      this._updateElementValue();

      this.sendAction('setPrimaryProduct', this.record);
    }
  });

  _exports.default = _default;
});