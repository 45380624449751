define("trivver-frontend/services/order-normalizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Order string normalizer
   *
   * Table component sends data in format (prop, sorting), where prop - name of property which should
   * be sorted, sorting is order of sorting (asc, desc or nothing). Django receives ordering in
   * following format "(-)property"
   *
   * @class OrderNormalizer
   * @module services/order-normalizer
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    /**
     * Normalize
     *
     * @param {String} prop property name
     * @param {String} sorting sorting order
     * @return {String} normalized string which ready to be send in request
     */
    normalize: function normalize(prop, sorting) {
      var orderingString;

      if (sorting === 'asc') {
        orderingString = "".concat(prop);
      } else if (sorting === 'desc') {
        orderingString = "-".concat(prop);
      }

      return orderingString;
    }
  });

  _exports.default = _default;
});