define("trivver-frontend/helpers/sort-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(value) {
      return value[0].map(function (item) {
        return item.title;
      }).sort();
    }
  });

  _exports.default = _default;
});