define("trivver-frontend/constants/date-formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SEND_DATE_FORMAT = _exports.MAIN_DATE_TIME_FORMAT = _exports.FUNDS_EARNED_DATE_FORMAT = void 0;
  var MAIN_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm a';
  _exports.MAIN_DATE_TIME_FORMAT = MAIN_DATE_TIME_FORMAT;
  var SEND_DATE_FORMAT = 'YYYY-MM-DD';
  _exports.SEND_DATE_FORMAT = SEND_DATE_FORMAT;
  var FUNDS_EARNED_DATE_FORMAT = 'MM/DD/YYYY';
  _exports.FUNDS_EARNED_DATE_FORMAT = FUNDS_EARNED_DATE_FORMAT;
});