define("trivver-frontend/pods/home/email-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VtMK5zwF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-title\"],[8],[0,\"Email Confirmed\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"alert alert-success\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[7,\"strong\",true],[8],[0,\"Your email has been confirmed!\"],[9],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Thanks for registering to be \"],[1,[22,\"userTypeTitle\"],false],[0,\" on Trivver.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"You will receive an email as soon as we have approved your account.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/home/email-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});