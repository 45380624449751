define("trivver-frontend/pods/components/datetime-picker/component", ["exports", "trivver-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var datetimeFormat = _environment.default.datetimeFormat;

  var _default = Ember.Component.extend({
    oldDate: moment(),
    open: false,
    changedDateOnly: function changedDateOnly(currentDate) {
      // close datepicker on date updating only
      var oldDate = this.oldDate;
      var oldDateFormat = moment(oldDate).format('M/D/YYYY');
      var currentDateFormat = moment(currentDate).format('M/D/YYYY');
      return oldDateFormat !== currentDateFormat;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$('.datetimepicker').datetimepicker({
        format: datetimeFormat,
        icons: {
          time: 'icon-ic-clock',
          date: 'icon-ic-calendar',
          up: 'icon-ic-arrow-wide-up',
          down: 'icon-ic-arrow-wide-down',
          next: 'icon-ic-next',
          previous: 'icon-ic-prev'
        }
      }).on('dp.change', function (e) {
        // close only if changed the date
        if (_this.open && _this.changedDateOnly(e.date)) {
          _this.set('open', false);

          _this.set('oldDate', e.date);

          Ember.$('.datetimepicker').data('DateTimePicker').hide();
        }
      }).on('dp.show', function (e) {
        // eslint-disable-line no-unused-vars
        _this.set('open', true);
      });
      var current = this.value;

      if (current) {
        this.set('oldDate', moment(current));
        var date = moment(current).format(datetimeFormat);
        Ember.$("#id_".concat(this.field)).val(date);
      }
    }
  });

  _exports.default = _default;
});