define("trivver-frontend/pods/account/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE, "/payments/account"),
    buildURL: function buildURL(modelName, instance, snapshot, urlType) {
      var url = this._super(modelName, instance, snapshot, urlType);

      var index = url.indexOf('accounts');
      return url.replace(/\/\d+\//, '').substr(0, index);
    }
  });

  _exports.default = _default;
});