define("trivver-frontend/pods/advertiser/serializer", ["exports", "trivver-frontend/pods/drf/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    serialize: function serialize() {
      // state not save as null in backend
      var json = this._super.apply(this, arguments);

      if (json.state === null || json.state === 'NO') {
        json.state = '';
      }

      return json;
    }
  });

  _exports.default = _default;
});