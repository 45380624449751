define("trivver-frontend/pods/components/image-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/TDs3J3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,0,[\"image\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"image-preview__note\"],[8],[1,[23,0,[\"textNoSelected\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/image-preview/template.hbs"
    }
  });

  _exports.default = _default;
});