define("trivver-frontend/pods/payment-account/adapter", ["exports", "trivver-frontend/config/environment", "trivver-frontend/pods/drf/adapter"], function (_exports, _environment, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    namespace: "".concat(_environment.default.APP.API_NAMESPACE),
    queryRecord: function queryRecord(store, type, query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/payments/account/");
      return this.ajax(url, 'GET', {
        data: query
      });
    }
  });

  _exports.default = _default;
});