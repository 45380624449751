define("trivver-frontend/validators/boolean", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var BooleanValidator = _base.default.extend({
    validate: function validate(value, params) {
      if (params.presence) {
        if (!value) {
          return 'TOS have to be accepted to save and finalize campaign.';
        }

        return value;
      }

      return true;
    }
  });

  BooleanValidator.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
     *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() {
      return [];
    }
  });
  var _default = BooleanValidator;
  _exports.default = _default;
});