define("trivver-frontend/pods/home/login-token/route", ["exports", "trivver-frontend/mixins/routes/force-logout"], function (_exports, _forceLogout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _forceLogout.default.extend({
    breadCrumb: null,
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model(params) {
      var cookies = this.cookies;
      var identification = cookies.read('user-token');
      var redirectTo = params.redirectTo; // remove api token from storage to prevent possible vulnerability
      // through cookies stealing

      cookies.clear('user-token');

      if (!identification) {
        this.transitionTo('home.login');
      }

      this.session.authenticate('authenticator:impersonate', {
        identification: identification
      });

      if (redirectTo) {
        document.location.href = redirectTo;
      }
    }
  });

  _exports.default = _default;
});