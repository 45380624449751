define("trivver-frontend/pods/confirmation-email-link/model", ["exports", "@ember-data/model", "ember-api-actions"], function (_exports, _model, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: (0, _model.attr)('string'),
    sendRequest: (0, _emberApiActions.memberAction)({
      path: 'link/',
      type: 'post',
      urlType: 'createRecord'
    })
  });

  _exports.default = _default;
});