define("trivver-frontend/pods/fund/adapter", ["exports", "trivver-frontend/pods/drf/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    pathForType: function pathForType() {
      return 'campaigns';
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 422) {
        return payload;
      }

      return this._super(status, headers, payload);
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var campaignId = snapshot.record.get('campaign.id');
      return "".concat(this.host, "/").concat(this.namespace, "/").concat(this.urlForType(), "/").concat(campaignId);
    }
  });

  _exports.default = _default;
});