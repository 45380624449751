define("trivver-frontend/pods/matching-game/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    game_uid: (0, _model.attr)('string'),
    ages: (0, _model.attr)('string'),
    genres: (0, _model.attr)('string'),
    platforms: (0, _model.attr)('string'),
    arrayOfGenres: Ember.computed.reads('genres')
  });

  _exports.default = _default;
});