define("trivver-frontend/pods/components/code-generate-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    type: 'button',
    attributeBindings: ['type', 'disabled'],
    click: function click() {
      this.set('target', Math.random().toString(36).substring(6).toUpperCase());
    }
  });

  _exports.default = _default;
});