define("trivver-frontend/validators/mv-coordinates", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MetaverseCoordinates = _base.default.extend({
    validate: function validate(value, options, model) {
      var mvX = model.mv_coordinate_x;
      var mvY = model.mv_coordinate_y;

      if (!mvX || !mvY) {
        return 'Enter correct coordinates.';
      }

      return true;
    }
  });

  MetaverseCoordinates.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['model.mv_coordinate_x', 'model.mv_coordinate_y'];
    }
  });
  var _default = MetaverseCoordinates;
  _exports.default = _default;
});