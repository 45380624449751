define("trivver-frontend/pods/components/inputs/dropdown-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mainClass: 'datalist-input',
    inputClass: 'form-control',
    listClass: 'data-items',
    searchText: null,
    value: null,
    uniqueId: Ember.computed('name', function () {
      return "".concat(this.name, "_id");
    }),
    getList: Ember.computed('getValue', function () {
      var value = this.getValue;
      var list = this.list;
      if (!value) return list;
      return list.filter(function (i) {
        return i.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
    }),
    getValue: Ember.computed('value', {
      get: function get() {
        return this.value;
      },
      set: function set(key, value) {
        var isOpen = this.isSelectOpen();
        this.set('value', value);

        if (!isOpen && this.get('getList.length')) {
          this.open();
        }

        return value;
      }
    }),
    open: function open() {
      if (!this.isSelectOpen()) {
        var selectId = "#".concat(this.uniqueId, " div");
        Ember.$(selectId).get(0).dispatchEvent(new MouseEvent('mousedown'));
      }
    },
    isSelectOpen: function isSelectOpen() {
      return !!Ember.$('#ember-basic-dropdown-wormhole ul').length;
    },
    actions: {
      openSelect: function openSelect() {
        this.open();
      }
    }
  });

  _exports.default = _default;
});