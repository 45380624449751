define("trivver-frontend/pods/components/radio-select-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      change: function change(item) {
        this.set('value', item);
      }
    }
  });

  _exports.default = _default;
});