define("trivver-frontend/pods/home/cabinet/developer/payouts/route", ["exports", "trivver-frontend/mixins/routes/developer-only"], function (_exports, _developerOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _developerOnly.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        account: this.get('currentUser.paymentAccount'),
        payoutRequests: this.store.findAll('payout-request'),
        balance: this.store.queryRecord('retrieve-balance', {
          developer: this.get('currentUser.user.org_id')
        })
      });
    }
  });

  _exports.default = _default;
});