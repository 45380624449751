define("trivver-frontend/helpers/format-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Helper that used for price formatting.
   *
   * @type {object}
   * @return {number} in params.number format
   * @public
   */
  var _default = Ember.Helper.extend({
    compute: function compute(price) {
      return parseFloat(price[0]).toFixed(parseFloat(price[1])).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }
  });

  _exports.default = _default;
});