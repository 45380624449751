define("trivver-frontend/pods/home/confirmation-email-link/route", ["exports", "trivver-frontend/mixins/routes/unlogged-user-only"], function (_exports, _unloggedUserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unloggedUserOnly.default.extend({
    breadCrumb: null,
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isModalOpen: null,
    model: function model() {
      return Ember.RSVP.hash({
        email: this.store.createRecord('confirmation-email-link')
      });
    },
    actions: {
      send: function send(model) {
        var _this = this;

        model.email.save().then(function () {
          _this.controller.set('isModalOpen', true);

          _this.store.unloadAll('confirmation-email-link');

          _this.store.unloadRecord(model.email);
        }).catch(function (e) {
          _this.flashMessages.danger(e.detail);
        });
      },
      redirectToLogin: function redirectToLogin() {
        this.transitionTo('home.login');
      }
    }
  });

  _exports.default = _default;
});