define("trivver-frontend/pods/home/user-types/route", ["exports", "trivver-frontend/mixins/routes/unlogged-user-only"], function (_exports, _unloggedUserOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unloggedUserOnly.default.extend({
    breadCrumb: null,
    renderTemplate: function renderTemplate() {
      this.render();
      this.set('title', document.title);
      document.title = 'Select User Type - Trivver';
    },
    actions: {
      willTransition: function willTransition() {
        document.title = this.title;
      }
    }
  });

  _exports.default = _default;
});