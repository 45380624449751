define("trivver-frontend/validators/has-primary-asset", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var HasPrimaryAsset = _base.default.extend({
    validate: function validate(value, options, model) {
      if (model.get('assets').filter(function (a) {
        return a.get('is_primary');
      }).length) {
        return true;
      }

      return 'Select primary product.';
    }
  });

  HasPrimaryAsset.reopenClass({
    // This validator depends from the property `is_primary` of each item from
    // the collection, that is why need to set dependencies for retry validation
    // after changing `is_primary` of one of an item from the collection
    getDependentsFor: function getDependentsFor(attribute) {
      return ["model.".concat(attribute, ".@each.is_primary"), "model.".concat(attribute, ".content.@each.is_primary")];
    }
  });
  var _default = HasPrimaryAsset;
  _exports.default = _default;
});